import React, { useState, useEffect } from "react";
import { getTimesheets } from "../../../graphql/queries";
import { ApolloQueryResult } from "@apollo/client";
import numeral from "numeral";
import DatePicker from "react-datepicker";

import "./JobCostingWkSht.scss";

interface jobCostingWkshtProps {
  reportJobId: string;
}

const JobCostingWkSht: React.FC<jobCostingWkshtProps> = ({ reportJobId }) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [timesheets, setTimesheets] = useState<any[]>([]);
  const [wagesTotals, setWagesTotals] = useState<number>(0);
  const [laborCodeChunks, setLaborCodeChunks] = useState<any[]>([]);
  const [laborCodeTotals, setLaborCodeTotals] = useState<any[]>([]);
  const [timeBreakdown, setTimeBreakdown] = useState<any[]>([]);
  const [lcDates, setLcDates] = useState<any[]>([]);
  const [job, setJob] = useState<{
    jobId: string;
    jobName: string;
    reportDate: string;
    laborSubtotal?: number;
  }>({
    jobId: "",
    jobName: "",
    reportDate: new Date().toString(),
    laborSubtotal: 0,
  });

  const fetchTimesheets = async () => {
    const tsList: ApolloQueryResult<any> = await getTimesheets();
    if (tsList.data) {
      setTimesheets(tsList.data.timesheets);
      let listArr = [...tsList.data.timesheets.filter((t: any) => t.jobId === reportJobId)];
      let interArr: any = [];
      let wagesTotals: any = [];
      let wagesOTTotals: any = [];
      listArr.forEach((t: any) => {
        t.reportHours.forEach((r: any) => {
          interArr.push({ ...r, date: new Date(t.reportDate) });
          wagesTotals.push(r.wagesTotalRT);
          wagesTotals.push(r.wagesTotalOT);
        });
        setTimeBreakdown([...timeBreakdown, ...interArr]);
      });
      setWagesTotals(parseFloat(wagesTotals.reduce((a: any, c: any) => a + c).toFixed(2)));
    }

    const sheets = tsList.data.timesheets;
    const d = new Date(sheets[0].reportDate).toString();
    d.split(" ");
    setJob({
      jobId: sheets[0].jobId,
      jobName: sheets[0].jobName,
      reportDate: d.split(" ").slice(0, 4).join(" "),
    });
  };

  const getLcChunks = () => {
    const tb = timeBreakdown.sort((a: any, b: any) => parseInt(a.laborCode) - parseInt(b.laborCode)).map((l: any) => l);
    let temp: any = [];
    let chunks: any = [];
    let begin = 0;
    let end = 0;
    for (let i = 0; i < tb.length; i++) {
      temp = [...temp, { lc: tb[i].laborCode, tb: tb[i] }];
    }
    setLaborCodeChunks(temp);
  };

  const workWeekGenerator = (sd: any) => {
    const dt = new Date();
    const currD = new Date(sd);
    let startDate = new Date(sd);
    startDate.setDate(startDate.getDate() - 6);
    const filteredDate = timeBreakdown.filter((d: any) => new Date(d.date).getDate() >= startDate.getDate() && new Date(d.date).getDate() <= currD.getDate());
    const laborCodeSum = filteredDate.filter((c: any) => parseInt(c.lc) === 11);
    let workDatesArr: any[] = [];
    setLcDates([]);
    setLcDates(filteredDate);
    getLCTotals();
    const workDayChunks = filteredDate.reduce((agg: any, wd) => {
      const wdDate = new Date(wd?.date);
      const wdDay = wdDate.getDate();
      if (!agg[wdDay]) {
        agg[wdDay] = { date: wdDate, arr: [] };
      }
      agg[wdDay].arr.push(wd);
      return agg;
    }, {});
    console.log("WORK DAY CHUNKS: ", workDayChunks);
    let workWeekChunksArr: any[] = [];
    let lcChunksArr: any[] = [];
    for (let c in workDayChunks) {
      workWeekChunksArr.push(workDayChunks[c]);
    }
    workWeekChunksArr.forEach((a: any) => {
      a.arr.forEach((el: any) => {
        el.total = el.wagesTotalRT + el.wagesTotalOT;
        el.totalHours = el.regHours + el.otHours;
      });
      lcChunksArr = a.arr.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) a.push(b.laborCode);
        return a;
      }, []);
    });

    let newChunks = [...new Set(lcChunksArr)];
    let newArr: any[] = [];
    let newLCArr: any[] = [];
    for (let i = 0; i < workWeekChunksArr.length; i++) {
      newChunks.forEach((el: any) => {
        let temp = workWeekChunksArr[i].arr.filter((c: any) => c.laborCode === el);
        newArr.push({
          date: workWeekChunksArr[i].date,
          laborCode: el,
          totalHours: parseFloat(temp?.reduce((a: any, b: any) => a + b?.totalHours, 0)),
          total: parseFloat(temp?.reduce((a: any, b: any) => a + b.total, 0).toFixed(2)),
        });
      });
    }
    newChunks.forEach((el: any) => {
      let fa = newArr.filter((e: any) => e.laborCode === el);
      newLCArr.push({
        dateEnding: selectedDate,
        laborCode: el,
        totalHours: fa.reduce((a: any, b: any) => a + b.totalHours, 0),
        total: fa.reduce((a: any, b: any) => a + b.total, 0),
      });
    });
  };

  useEffect(() => {
    fetchTimesheets();
  }, []);

  useEffect(() => {
    if (timesheets.length > 0) {
      getLcChunks();
    }
  }, [timeBreakdown]);

  const laborTotals = () => {
    if (wagesTotals) {
      return numeral(wagesTotals.toFixed(2)).format("$0,0.00");
    }
  };

  const getLCTotals = () => {
    let temp: any[] = [];
    let reduceArr: any[] = [];
    let chunkArr: any[] = [];
    let lcArr: any[] = [...lcDates];

    lcArr.forEach((l: any) => {
      temp.push(l.laborCode);
    });
    temp = temp.reduce((a: any, b: any) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    for (let i of temp) {
      chunkArr.push(lcArr.filter((a: any) => a.laborCode === i));
    }

    for (let i = 0; i < chunkArr.length; i++) {
      console.log("CHUNK[I]: ", chunkArr[i]);
      let reducTot = chunkArr[i].reduce((acc: any, val: any) => {
        return acc + val.total;
      }, 0);

      let reducHrs = chunkArr[i].reduce((acc: any, val: any) => {
        return acc + val.totalHours;
      }, 0);

      reduceArr = [...reduceArr, { totals: reducTot, hours: reducHrs, laborCodes: temp[i] }];
    }

    console.log("CHUNK ARR: ", chunkArr);
    console.log("REDUCE ARR: ", reduceArr);
    setLaborCodeTotals([...reduceArr]);
  };

  const laborBreakdownTable_ = () => {
    getLcChunks();
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Employee</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">FM</th>
            <th className="laborbreakdown-table-head-toprow-col">JM</th>
            <th className="laborbreakdown-table-head-toprow-col">AP</th>
            <th className="laborbreakdown-table-head-toprow-col">Total HRS.</th>
            <th className="laborbreakdown-table-head-toprow-col">FM Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">BTJ Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">AP Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Sub-Total</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages O&P</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Total</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {timeBreakdown
            .sort((a: any, b: any) => a.date - b.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.regWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPRT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalRT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                  <tr key={idx + 0.5}>
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.otWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPOT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalOT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const laborBreakdownTable = () => {
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Name</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">Hours</th>
            <th className="laborbreakdown-table-head-toprow-col">Amount</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {lcDates
            .sort((a: any, b: any) => a.date - a.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.laborCode}</td>
                    <td className="laborbreakdown-table-body-row-col">{parseFloat(ts.regHours) + parseFloat(ts.otHours)}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.total).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const dateGenerator = () => {
    const d = new Date(),
      year = d.getFullYear(),
      mondays: Date[] = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all other Mondays in the month
    while (d.getFullYear() === year) {
      const pushDate = new Date(d.getTime());
      mondays.push(new Date(pushDate.getMonth() + 1 + "-" + pushDate.getDate() + "-" + pushDate.getFullYear()));
      d.setDate(d.getDate() + 7);
    }
    // console.log('MONDAYS: ', mondays);
    return mondays;
  };

  useEffect(() => {
    if (laborCodeChunks.length > 0) {
      getLCTotals();
    }
  }, [laborCodeChunks]);
  console.log("LABOR TOTALS: ", laborCodeTotals);

  return (
    <div className="laborbreakdown">
      <div className="laborbreakdown-datePicker">
        <DatePicker
          wrapperClassName="laborbreakdown-datePicker-date"
          selected={new Date()}
          includeDates={dateGenerator()}
          onChange={(e: any) => {
            setSelectedDate(new Date(e[0]).toDateString());
            workWeekGenerator(e[0]);
          }}
          selectsRange
          inline
        />
      </div>
      {laborBreakdownTable()}
      <div>
        <table>
          <thead>
            <tr>
              <th>Labor Code</th>
              <th>Total Hours</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {laborCodeTotals.map((l: any, idx: number) => {
              return (
                <tr>
                  <td>{l.laborCodes}</td>
                  <td>{l.hours}</td>
                  <td>{numeral(l.totals).format("$0,0.00")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobCostingWkSht;
