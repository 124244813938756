import React, { useState, useEffect } from "react";
import { getFocus, getShiftData } from "../../api/si";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ImageAttachment.scss";
import Button1 from "../button1/Button1";
import { uploadAttachmentPdf } from "../../api/pdfAttachments";
import { uploadJobImage } from "../../api/images";

interface IImageAttachment {
  // pdfDoc: any;
  showModal: (s: boolean) => void;
}
const ImageAttachment: React.FC<IImageAttachment> = ({ showModal }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const [previewImgUrl, setPreviewimgUrl] = useState("");

  const [fileMetaData, setFileMetaData] = useState<{ job?: string; shift?: number; date?: Date; txt?: string; foreman?: string } | null>();
  const [shiftData, setShiftData] = useState<any>(null);
  const [focus, setFocus] = useState<any>(null);

  const getWorksiteData = async () => {
    const focusData = await getFocus();
    const siteData = await getShiftData(focusData);
    if (siteData) {
      setFocus(focusData);
      setShiftData(siteData);
      setFileMetaData({
        job: siteData?.jobName,
        shift: siteData?.shift,
        date: new Date(),
        foreman: `${siteData?.siteEmployees.foreman.firstName} ${siteData?.siteEmployees.foreman.lastName} `,
      });
    }
  };

  const handleSaveImgAttachment = async () => {
    const data: any = { ...fileMetaData };
    data.img = previewImgUrl;

    await uploadJobImage(data);
    // if (res?.statusText === "Created") {
    //   alert("Your file has successfully been attached.");
    //   setFile(null);
    //   setFileMetaData(null);
    //   setPreviewimgUrl("");
    // } else {
    //   alert("Error attaching your file");
    // }
    alert("Your file has successfully been attached.");
    setFile(null);
    setFileMetaData(null);
    setPreviewimgUrl("");
    // console.log("UPLOAD PDF RESPONSE: ", res);
  };

  // console.log("FILE ATTACHMENT: ,", file?.name);

  useEffect(() => {
    getWorksiteData();
  }, []);

  const fileToDataString = (file: File) => {
    return new Promise<string>(async (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
      reader.onload = () => resolve(reader.result as string);
    });
  };

  return (
    <div className="imageattachment">
      <div className="imageattachment-topbtngrp">
        <FontAwesomeIcon
          className="imageattachment-topbtngrp-closeicon"
          icon={faX}
          onClick={async () => {
            showModal(false);
          }}
        />
      </div>
      <form
        className="imageattachment-form"
        onSubmit={e => {
          e.preventDefault();
          if (file) {
            handleSaveImgAttachment();
          } else {
            alert("There are no files attached.");
          }
        }}
      >
        <div className="imageattachment-form-attachment">
          <label className="imageattachment-form-attachment-label" htmlFor="pdfAttachment">
            Image Attachment
          </label>
          <input
            className="imageattachment-form-attachment-input"
            id="pdfAttachment"
            type="file"
            accept="image/*"
            placeholder="Add Image here"
            onChange={async e => {
              if (e?.target?.files) {
                setFile(e?.target?.files[0]);
                const imgUrl = await fileToDataString(e?.target?.files[0]);
                setPreviewimgUrl(imgUrl);
              }
            }}
          />
        </div>
        {/* <div className="imageattachment-form-name">
          <label className="imageattachment-form-name-label" htmlFor="pdfAttachment">
            Name (Optional)
          </label>
          <input
            className="imageattachment-form-name-input"
            id="pdfAttachment"
            type="text"
            placeholder="Add Name Here"
            value={fileMetaData?.name ? fileMetaData?.name : ""}
            onChange={e => {
              if (file) {
                setFileMetaData({
                  ...fileMetaData,
                  name: e.target.value,
                });
              } else {
                alert("Choose a PDF before adding a name.");
              }
            }}
          />
        </div> */}

        <div className="imageattachment-form-notes">
          <label className="imageattachment-form-notes-label" htmlFor="imgAttachment">
            Notes
          </label>
          <textarea
            className="imageattachment-form-notes-input"
            id="imgAttachment"
            placeholder="Add Name Here"
            value={fileMetaData?.txt ? fileMetaData?.txt : ""}
            onChange={e => {
              if (file) {
                setFileMetaData({
                  ...fileMetaData,
                  txt: e.target.value,
                });
              } else {
                alert("Choose a PDF before adding notes.");
              }
            }}
          />
        </div>
        <div className="imageattachment-form-btngrp">
          <button name="submitBtn" id="submitBtn" type="submit" className="imageattachment-form-btngrp-submitbtn">
            Save File
          </button>

          <button
            name="submitBtn"
            id="submitBtn"
            type="button"
            className="imageattachment-form-btngrp-submitbtn"
            onClick={() => {
              showModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      <div className="imageattachment-document">
        <img className="imageattachment-document-imgpreview" src={previewImgUrl} alt="preview image" />
      </div>
    </div>
  );
};

export default ImageAttachment;
