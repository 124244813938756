import React, { useState, useEffect } from "react";
import { getTimesheets } from "../../../graphql/queries";
import { ApolloQueryResult } from "@apollo/client";
import numeral from "numeral";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import "./JobCostingSummary.scss";

interface jobCostingWkshtProps {
  reportJobId: string;
}

const JobCostingSummary: React.FC<jobCostingWkshtProps> = ({ jobId }: any) => {
  const [timesheets, setTimesheets] = useState<any[]>([]);
  const [wagesTotals, setWagesTotals] = useState<number>(0);
  const [laborCodeChunks, setLaborCodeChunks] = useState<any[]>([]);
  const [timeBreakdown, setTimeBreakdown] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [jobCostSummary, setJobCostSummary] = useState<any[]>([]);
  const [job, setJob] = useState<{
    jobId: string;
    jobName: string;
    reportDate: string;
    laborSubtotal?: number;
  }>({
    jobId: "",
    jobName: "",
    reportDate: new Date().toString(),
    laborSubtotal: 0,
  });

  const fetchTimesheets = async () => {
    const tsList: ApolloQueryResult<any> = await getTimesheets();
    if (tsList.data) {
      setTimesheets(tsList.data.timesheets);
      let listArr = [...tsList.data.timesheets];
      let interArr: any = [];
      let wagesTotals: any = [];
      let wagesOTTotals: any = [];
      listArr.forEach((t: any) => {
        t.reportHours.forEach((r: any) => {
          interArr.push({ ...r, date: new Date(t.reportDate) });
          wagesTotals.push(r.wagesTotalRT);
          wagesTotals.push(r.wagesTotalOT);
        });
        setTimeBreakdown([...timeBreakdown, ...interArr]);
      });
      setWagesTotals(parseFloat(wagesTotals.reduce((a: any, c: any) => a + c).toFixed(2)));
      console.log("WAGE TOTALS: ", wagesTotals.reduce((a: any, c: any) => a + c).toFixed(2));
    }

    const sheets = tsList.data.timesheets;
    const d = new Date(sheets[0].reportDate).toString();
    d.split(" ");
    setJob({
      jobId: sheets[0].jobId,
      jobName: sheets[0].jobName,
      reportDate: d.split(" ").slice(0, 4).join(" "),
    });
  };
  const getLcChunks = () => {
    const tb = timeBreakdown.sort((a: any, b: any) => parseInt(a.laborCode) - parseInt(b.laborCode)).map((l: any) => l);
    let temp: any = [];
    let chunks: any = [];
    let begin = 0;
    let end = 0;
    for (let i = 0; i < tb.length; i++) {
      temp = [...temp, { lc: tb[i].laborCode, tb: tb[i] }];
    }
    console.log("TEMP: ", chunks, begin);
    setLaborCodeChunks(temp);
  };

  useEffect(() => {
    fetchTimesheets();
    setTimesheets(
      timesheets.filter((el: any) => {
        el.jobId === jobId;
      })
    );
  }, []);

  useEffect(() => {
    if (timesheets.length > 0) {
      getLcChunks();
    }
  }, [timeBreakdown]);

  // console.log('LABOR BREAKDOWN CHUNKS: ', laborCodeChunks,)

  const costSummaryTotal = () => {
    const total = jobCostSummary.reduce((acc: any, tot: any) => acc + tot.total, 0);
    return numeral(total).format("$0,0.00");
  };

  console.log("COST SUMMARY TOTAL: ", costSummaryTotal());

  const getLCTotals = () => {
    let temp: any[] = [];
    let reduceArr: any[] = [];
    let chunkArr: any[] = [];
    let count = 0;
    let start, end;
    let lcArr: any[] = [...laborCodeChunks];
    for (let i = 0; i < lcArr.length; i++) {
      if (i > 0) {
        if (parseInt(lcArr[i].lc) !== parseInt(lcArr[i - 1].lc)) {
          start = 0;
          end = i;
          temp.push(lcArr[i - 1].lc);
          count++;
        }
      }
      if (i < lcArr.length - 1) {
        if (parseInt(lcArr[i].lc) !== parseInt(lcArr[i + 1].lc)) {
          start = 0;
          end = i;
          temp.push(lcArr[i + 1].lc);
          count++;
        }
      }
    }
    reduceArr = temp.reduce((a: any, b: any) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    for (let el of reduceArr.sort()) {
      chunkArr.push(laborCodeChunks.filter((c: any) => c.tb.laborCode === el));
    }

    let totals: any[] = [];
    let sum = 0;
    let hours = 0;
    let idx = 0;
    let lc = "";
    chunkArr.forEach((c: any) => {
      for (let d = 0; d < c.length; d++) {
        sum += c[d].tb.wagesTotalRT + c[d].tb.wagesTotalOT;
        hours += c[d].tb.regHours + c[d].tb.otHours;
      }
      lc = reduceArr[idx];
      totals.push({ lc, hours, sum: parseFloat(sum.toFixed(2)) });
      idx++;
    });
    console.log("TOTALS: ", totals);
    console.log("CHUNK ARR: ", chunkArr);
  };

  const getDateTotals = () => {
    let temp: any[] = [];
    let reduceArr: any[] = [];
    let chunkArr: any[] = [];
    let count = 0;
    let start, end;
    let lcArr: any[] = [...laborCodeChunks];
    for (let i = 0; i < lcArr.length; i++) {
      if (i > 0) {
        if (new Date(lcArr[i].tb.date).toDateString() !== new Date(lcArr[i - 1].tb.date).toDateString()) {
          start = 0;
          end = i;
          temp.push(new Date(lcArr[i - 1].tb.date).toDateString());
          count++;
        }
      }
      if (i < lcArr.length - 1) {
        if (new Date(lcArr[i].tb.date).toDateString() !== new Date(lcArr[i + 1].tb.date).toDateString()) {
          start = 0;
          end = i;
          temp.push(new Date(lcArr[i + 1].tb.date).toDateString());
          count++;
        }
      }
    }

    reduceArr = temp.reduce((a: any, b: any) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    for (let el of reduceArr.sort((a: any, b: any) => a.tb?.date - b.tb?.date)) {
      chunkArr.push(laborCodeChunks.filter((c: any) => new Date(c.tb.date).toDateString() === el));
    }

    let totals: any[] = [];
    let sum = 0;
    let hours = 0;
    let idx = 0;
    let lcDate = "";
    chunkArr.forEach((c: any) => {
      for (let d = 0; d < c.length; d++) {
        sum += c[d].tb.wagesTotalRT + c[d].tb.wagesTotalOT;
        hours += c[d].tb.regHours + c[d].tb.otHours;
      }
      lcDate = reduceArr[idx];
      totals.push({ lcDate, hours, sum: parseFloat(sum.toFixed(2)) });
      idx++;
    });
    let startDay = 2;
    let endDay = 1;
    dayjs.extend(isBetween);
    // const dateArr = reduceArr.filter((r: any) => {
    //   return dayjs(r).day() > dayjs(r).day(startDay) && new Date(r).getDay() < endDay;
    // })

    console.log("DAY JS DAY: ", dayjs().day(2));
    console.log("TOTALS: ", totals);
    console.log("CHUNK ARR: ", chunkArr);
    console.log("REDUCE ARR: ", reduceArr);
  };

  const laborBreakdownTable_ = () => {
    getLcChunks();
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Employee</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">FM</th>
            <th className="laborbreakdown-table-head-toprow-col">JM</th>
            <th className="laborbreakdown-table-head-toprow-col">AP</th>
            <th className="laborbreakdown-table-head-toprow-col">Total HRS.</th>
            <th className="laborbreakdown-table-head-toprow-col">FM Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">BTJ Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">AP Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Sub-Total</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages O&P</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Total</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {timeBreakdown
            .sort((a: any, b: any) => a.date - b.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.regWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPRT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalRT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                  <tr key={idx + 0.5}>
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.otWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPOT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalOT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const laborBreakdownTable = () => {
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Name</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">Hours</th>
            <th className="laborbreakdown-table-head-toprow-col">Amount</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {laborCodeChunks
            .sort((a: any, b: any) => a.tb.date - a.tb.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.tb.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.tb.name}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.tb.laborCode}</td>
                    <td className="laborbreakdown-table-body-row-col">{parseFloat(ts.tb.regHours) + parseFloat(ts.tb.otHours)}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.tb.regWages + ts.tb.otWages).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const costSummary = () => {
    return (
      <table className="laborbreakdown-table">
        <thead>
          <tr
            style={{
              width: "100%",
            }}
          >
            {jobCostSummary.length > 0 && (
              <th
                style={{
                  width: "5%",
                  backgroundColor: "grey",
                }}
              >
                Labor Codes
              </th>
            )}
            {jobCostSummary?.map((cost, idx) => {
              console.log("JOB COSTS: ", cost);
              return (
                <>
                  <th
                    key={idx}
                    scope="col"
                    style={{
                      backgroundColor: "grey",
                      width: "10%",
                    }}
                  >
                    {cost.laborCode}
                  </th>
                </>
              );
            })}
          </tr>
          <tr
            style={{
              width: "100%",
            }}
          >
            {jobCostSummary.length > 0 && (
              <th
                scope="row"
                style={{
                  width: "5%",
                  backgroundColor: "light-grey",
                  border: "0.5px solid #000000",
                }}
              >
                WE
              </th>
            )}
            {jobCostSummary?.map((cost, idx) => {
              console.log("JOB COSTS: ", cost);
              return (
                <>
                  <th
                    scope="col"
                    style={{
                      backgroundColor: "light-grey",
                      width: "10%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          border: "0.5px solid #000000",
                          width: "50%",
                        }}
                      >
                        Hrs
                      </div>

                      <div
                        style={{
                          border: "0.5px solid #000000",
                          width: "50%",
                        }}
                      >
                        Cost
                      </div>
                    </div>
                  </th>
                </>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {jobCostSummary?.map((cost, idx) => {
            console.log("COST: ", cost);
            return (
              <tr>
                <td
                  style={{
                    backgroundColor: "light-grey",
                    width: "5%",
                    fontSize: "15px",
                    border: "0.5px solid #000000",
                    textAlign: "center",
                  }}
                >
                  {cost.dateEnding}
                </td>
                {jobCostSummary?.map((c, idx) => {
                  return (
                    <>
                      <td
                        style={{
                          backgroundColor: "light-grey",
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              border: "0.5px solid #000000",
                              width: "50%",
                            }}
                          >
                            {c.totalHours}
                          </div>

                          <div
                            style={{
                              border: "0.5px solid #000000",
                              width: "50%",
                            }}
                          >
                            {numeral(c.total).format("$0,0.00")}
                          </div>
                        </div>
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const temp = () => {
    return (
      <table className="laborbreakdown-table">
        <thead>
          <tr>
            <th>WE</th>
            <th>Labor Code</th>
            <th>Total Hours</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {jobCostSummary?.map((cost, idx) => {
            console.log("COST: ", cost);
            return (
              <tr>
                <td
                  style={{
                    backgroundColor: "light-grey",
                    width: "5%",
                    fontSize: "15px",
                    border: "0.5px solid #000000",
                    textAlign: "center",
                  }}
                >
                  {cost.dateEnding}
                </td>

                <td
                  style={{
                    backgroundColor: "light-grey",
                    width: "5%",
                    fontSize: "15px",
                    border: "0.5px solid #000000",
                    textAlign: "center",
                  }}
                >
                  {cost.laborCode}
                </td>

                <td
                  style={{
                    backgroundColor: "light-grey",
                    width: "5%",
                    fontSize: "15px",
                    border: "0.5px solid #000000",
                    textAlign: "center",
                  }}
                >
                  {cost.totalHours}
                </td>

                <td
                  style={{
                    backgroundColor: "light-grey",
                    width: "5%",
                    fontSize: "15px",
                    border: "0.5px solid #000000",
                    textAlign: "center",
                  }}
                >
                  {numeral(cost.total).format("$0,0.00")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  if (laborCodeChunks.length > 0) {
    getLCTotals();
    getDateTotals();
  }

  const dateGenerator = () => {
    const d = new Date(),
      year = d.getFullYear(),
      mondays: Date[] = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all other Mondays in the month
    while (d.getFullYear() === year) {
      const pushDate = new Date(d.getTime());
      mondays.push(new Date(pushDate.getMonth() + 1 + "-" + pushDate.getDate() + "-" + pushDate.getFullYear()));
      d.setDate(d.getDate() + 7);
    }
    // console.log('MONDAYS: ', mondays);
    return mondays;
  };

  const workWeekGenerator = (sd: any) => {
    const dt = new Date();
    const currD = new Date(sd);
    let startDate = new Date(sd);
    startDate.setDate(startDate.getDate() - 6);
    const filteredDate = laborCodeChunks.filter((d: any) => new Date(d.tb.date).getDate() >= startDate.getDate() && new Date(d.tb.date).getDate() <= currD.getDate());

    const laborCodeSum = filteredDate.filter((c: any) => parseInt(c.lc) === 11);

    let workDatesArr: any[] = [];

    const workDayChunks = filteredDate.reduce((agg: any, wd) => {
      console.log("WB DATE: ", new Date(wd?.tb?.date).getDate());
      const wdDate = new Date(wd?.tb?.date);
      const wdDay = wdDate.getDate();
      if (!agg[wdDay]) {
        agg[wdDay] = { date: wdDate, arr: [] };
      }
      agg[wdDay].arr.push(wd.tb);
      return agg;
    }, {});

    let workWeekChunksArr: any[] = [];
    let lcChunksArr: any[] = [];
    for (let c in workDayChunks) {
      workWeekChunksArr.push(workDayChunks[c]);
    }
    workWeekChunksArr.forEach((a: any) => {
      a.arr.forEach((el: any) => {
        el.total = el.wagesTotalRT + el.wagesTotalOT;
        el.totalHours = el.regHours + el.otHours;
      });
      lcChunksArr = a.arr.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) a.push(b.laborCode);
        return a;
      }, []);
    });

    let newChunks = [...new Set(lcChunksArr)];
    let newArr: any[] = [];
    let newLCArr: any[] = [];
    for (let i = 0; i < workWeekChunksArr.length; i++) {
      console.log("THIS IS J: ", workWeekChunksArr[i].arr.length);
      newChunks.forEach((el: any) => {
        let temp = workWeekChunksArr[i].arr.filter((c: any) => c.laborCode === el);
        newArr.push({
          date: workWeekChunksArr[i].date,
          laborCode: el,
          totalHours: parseFloat(temp?.reduce((a: any, b: any) => a + b?.totalHours, 0)),
          total: parseFloat(temp?.reduce((a: any, b: any) => a + b.total, 0).toFixed(2)),
        });
      });
    }
    newChunks.forEach((el: any) => {
      let fa = newArr.filter((e: any) => e.laborCode === el);
      newLCArr.push({
        dateEnding: selectedDate,
        laborCode: el,
        totalHours: fa.reduce((a: any, b: any) => a + b.totalHours, 0),
        total: fa.reduce((a: any, b: any) => a + b.total, 0),
      });
    });
    setJobCostSummary(newLCArr);
    console.log("REVISED LC CHUNKS ARR: ", newLCArr);
    console.log("NEW LC CHUNKS ARR: ", newArr);
    console.log("NEW CHUNKS: ", newChunks);
    console.log("WORK WEEK CHUNKS: ", workWeekChunksArr);
    console.log("LC CHUNKS ARR: ", workDayChunks);
  };

  return (
    <div className="laborbreakdown">
      <div className="laborbreakdown-datePicker">
        <DatePicker
          wrapperClassName="laborbreakdown-datePicker-date"
          selected={new Date()}
          includeDates={dateGenerator()}
          onChange={(e: any) => {
            console.log("DATE PICKER: ", e[0]);
            setSelectedDate(new Date(e[0]).toDateString());
            workWeekGenerator(e[0]);
          }}
          selectsRange
          inline
        />
      </div>
      <div className="">
        <button>Prepare to print</button>
      </div>

      {temp()}
      <div>
        <p>Total Cost</p>
        {costSummaryTotal()}
      </div>
    </div>
  );
};

export default JobCostingSummary;
