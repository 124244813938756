import { faChevronDown, faChevronUp, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";

import { formattedDate } from "../../helpers/dateTime";
import { getUser } from "../../api/auth";
import { getChecklistData, getInitChecklistData, updateChecklistData, updateChecklistDate, updateChecklistDatePrepared } from "../../api/checklists";
import { getShiftData, updateLocation, updateStartDate, updateTaskCompletion, updateWorkType, getFocus } from "../../api/si";
import { getForemanData } from "../../graphql/queries";
import PdfViewer from "../../common/pdfViewer/PdfViewer";

import { ForemanData, Job, WorksiteEmployees } from "../../types";
import "./Header.scss";
import ImageAttachment from "../imageAttachment/ImageAttachment";

interface HeaderProps {
  headerType?: string;
  checklistHeader?: string;
  site: {
    job: {
      jobName: string;
      location: string;
    };
    contractNo: string;
    agency: {
      agencyName: string;
    };
    location: string;
    jobName: string;
    startDate: string;
    taskCompletion: string;
    shift: string;
  };
  workShift: (s: string) => void;
}

enum Shifts {
  SHIFT1 = 1,
  SHIFT2,
  SHIFT3,
}

enum TypeOfWork {
  contract = "CONTRACT",
  changeOrder = "CHANGE ORDER",
  tAndM = "T & M",
  extraWork = "EXTRA WORK",
}

enum AttachmentActions {
  addImages = "Attach Images",
  viewImages = "View Images",
  addPdfs = "Attach PDFs",
  viewPdfs = "View PDFs",
}

const attachmentActions = [AttachmentActions.addImages, AttachmentActions.viewImages, AttachmentActions.addPdfs, AttachmentActions.viewPdfs];

const Header: React.FC<HeaderProps> = ({ site, workShift, headerType, checklistHeader }) => {
  const [showLowerHeader, setShowLowerHeader] = useState(true);
  const [shift, setShift] = useState<string>("");
  const [shiftStartDate, setShiftStartDate] = useState<Date | null>(null);
  const [taskCompletion, setTaskCompletion] = useState<Date | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [foremanData, setForemanData] = useState<ForemanData | null>(null);

  const [selectedAttachmentAction, setSelectedAttachmentAction] = useState<string | null>(null);
  const [showPdfAttachmentsModal, setShowPdfAttachmentModal] = useState<boolean>(false);
  const [showImageAttachmentsModal, setShowImageAttachmentModal] = useState<boolean>(false);
  const [pdfAttachmnt, setPdfAttachmnt] = useState<any | null>(null);

  const [checklistHeaderData, setChecklistHeaderData] = useState<any | null | undefined>(null);
  const [worksiteLocation, setWorksiteLocation] = useState<{ name: string }[]>([
    {
      name: "",
    },
  ]);

  const [workzones, setWorkzones] = useState<string[]>([]);

  const [checklistDate, setChecklistDate] = useState<Date | null>(null);
  const [checklistDatePrepared, setChecklistDatePrepared] = useState<Date | null>(null);

  const handleShiftChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (shift !== "") {
      alert("WARNING: You are changing the Job Site Shift.  All data will carry over to the new shift.  Please make sure the shift change is not in error.");
    }

    const shiftVal = e.target.value;
    setShift(shiftVal);
    workShift(shiftVal);
    const updatedShiftData = await getShiftData(jobFocus);
    setShiftData(updatedShiftData);
  };

  const fetchInitChecklistData = async () => {
    try {
      const _checklistData = await getChecklistData();
      if (_checklistData.error) {
        console.log(error);
      } else {
        setChecklistHeaderData(_checklistData);
      }
      const initForemanChecklistData = await getInitChecklistData();
      setForemanData(initForemanChecklistData);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const jobFocus = {
    jobName: site?.jobName,
    shift: site?.shift,
  };

  const getJobSiteData = async () => {
    const job = await getShiftData(jobFocus);
    if (job?.workzones && job?.workzones?.length > 0) {
      setWorkzones(job?.workzones);
    }
    setShiftData(job);
    if (job?.shift) {
      setShift(job?.shift);
    }
    setTaskDates();
  };

  useEffect(() => {
    if (navigator.onLine) {
      getForemanData(getUser()?.userId)
        .then(data => {
          setForemanData({ ...data });
        })
        .catch(error => {
          console.log({ ERROR: error.message });
        });
    }

    if (headerType === "checklist") {
      fetchInitChecklistData();
    }
  }, [shiftData]);

  useEffect(() => {
    getJobSiteData();
  }, []);

  const saveChecklistDatePrepared = async (val: Date) => {
    const updateChecklistData = await updateChecklistDatePrepared(val);
    setChecklistHeaderData(updateChecklistData);
    setChecklistDatePrepared(val);
  };

  const saveChecklistDate = async (val: Date) => {
    const updateChecklistData = await updateChecklistDate(val);
    setChecklistHeaderData(updateChecklistData);
    setChecklistDate(val);
  };

  const setTaskDates = async () => {
    await updateStartDate(new Date().toDateString(), jobFocus);
    await updateTaskCompletion(new Date().toDateString(), jobFocus);
  };

  const handleAddLocation = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const siteLocation = [...workzones!];
    siteLocation[idx] = e.target.value;
    setWorkzones(siteLocation);
  };

  const addBlankFieldTaskrow = () => {
    const item = "";
    setWorkzones([...workzones!, item]);
  };

  const removeBlankFieldTaskrow = (idx: number) => {
    const updatedWorksiteLocation = [...workzones!];
    updatedWorksiteLocation.splice(idx, 1);
    setWorkzones(prevState => updatedWorksiteLocation);
    // addBlankFieldTaskrow();
  };

  const renderAddPdfModal = () => {
    return (
      <div>
        <PdfViewer
          pdfDoc={pdfAttachmnt}
          showModal={(s: boolean) => {
            setShowPdfAttachmentModal(s);
          }}
        />
      </div>
    );
  };

  const renderImageAttachmentsModal = () => {
    return (
      <div>
        <ImageAttachment
          showModal={(s: boolean) => {
            setShowImageAttachmentModal(s);
          }}
        />
      </div>
    );
  };

  // useEffect(() => {
  //   worksiteLocation;
  // }, [worksiteLocation]);

  // console.log("SHIFT DATA: ", shiftData, "WORKSITE LOCATION: ", worksiteLocation);

  const handleJobImageView = (view: string) => {
    if (view === "Attach Images") {
      setShowImageAttachmentModal(true);
    } else if (view === "View Images") {
      // setSelectedAttachmentAction(view);
      navigate("/job-images");
    } else if (view === "Attach PDFs") {
      setShowPdfAttachmentModal(true);
    } else if (view === "View PDFs") {
      navigate("/pdf-attachments");
    }
  };

  return (
    <div className="header">
      <div className="header-1">
        {headerType !== "checklist" && (
          <>
            <div className="header-1-col1">
              {/* <div>{shiftData?.job?.contractNo}</div>
              <div>{shiftData?.job?.agency?.agencyName}</div> */}
              <div>
                <label htmlFor="workreportActions">Attachments </label>
                <select
                  name="workreportActions"
                  id="workreportActions"
                  onChange={e => {
                    handleJobImageView(e.target.value);
                  }}
                  disabled={showImageAttachmentsModal || showImageAttachmentsModal || !navigator.onLine}
                >
                  <option value="none" selected>
                    Select Action
                  </option>
                  {attachmentActions.map((a: string, idx: number) => {
                    return <option key={idx}>{a}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="header-1-col2">
              <label htmlFor="shift">Shift </label>
              <select name="shift" id="shift" onChange={handleShiftChange} value={shift}>
                <option value="">Select a Shift</option>
                <option value={Shifts.SHIFT1}>{Shifts.SHIFT1}</option>
                <option value={Shifts.SHIFT2}>{Shifts.SHIFT2}</option>
                <option value={Shifts.SHIFT3}>{Shifts.SHIFT3}</option>
              </select>
            </div>
            {/* <div className="header-1-col3">
              <div className="header-1-col3-date">
                <label>CONTRACT START DATE:</label>
                <p>{new Date(shiftData?.job?.contractStartDate).toDateString()}</p>
              </div>
            </div> */}
          </>
        )}
      </div>
      {headerType !== "checklist" && (
        <div className="header-divider">
          <FontAwesomeIcon className="header-divider-icon" icon={showLowerHeader ? faChevronUp : faChevronDown} onClick={() => setShowLowerHeader(!showLowerHeader)} />
        </div>
      )}
      {showLowerHeader && (
        <>
          <div className="header-2">
            {headerType !== "checklist" ? (
              <>
                <div
                  className="header-2-col1"
                  onBlur={async () => {
                    const updatedShiftData = await updateLocation(workzones!, await getFocus());
                    // console.log('UPDATED SHIFT DATA: ', updatedShiftData)
                    // setShiftData(updatedShiftData);
                    // console.log("UPDATED SHIFT: ", updatedShiftData?.workLocation);
                    setWorkzones([...updatedShiftData?.workzones]);
                  }}
                >
                  <label className="header-2-col1-label">
                    WORK ZONES
                    <FontAwesomeIcon
                      className="header-2-col1-label-add-icon"
                      icon={faPlus}
                      onClick={() => {
                        addBlankFieldTaskrow();
                      }}
                    />
                  </label>
                  <ul>
                    {workzones?.map((l, idx) => (
                      <li key={idx} className="header-2-col1-inputwrapper">
                        <input className="header-2-col1-inputwrapper-input" type="text" onChange={e => handleAddLocation(e, idx)} value={l} />
                        <FontAwesomeIcon
                          className="header-2-col1-inputwrapper-removeicon"
                          icon={faMinus}
                          onClick={() => {
                            removeBlankFieldTaskrow(idx);
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="header-2-col2">
                  <div className="header-2-col2-headers">
                    <label className="header-2-col2-headers-label">JOB NAME:</label>
                    <div className="header-2-col2-headers-jobname">{shiftData?.jobName}</div>
                  </div>
                  <div className="header-2-col2-headers">
                    <label className="header-2-col2-headers-label">JOB LOCATION:</label>
                    <div className="header-2-col2-headers-jobname">{shiftData?.location}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="header-2-col2checklist-wrapper">
                  <div className="header-2-col2checklist">
                    <select
                      name="agency"
                      id="agency"
                      // value={checklistHeaderData ? checklistHeaderData?.agency?.agencyName : `${"Select an agency"}`}
                      onChange={async e => {
                        // setChecklistHeaderData(foremanData?.jobs[Number(e.target.value)].job)
                        try {
                          const updatedChecklistData = await updateChecklistData({
                            agency: foremanData?.jobs[Number(e.target.value)].job.agency,
                            location: foremanData?.jobs[Number(e.target.value)].job.location,
                          });
                          if (updatedChecklistData!) {
                            setChecklistHeaderData(updatedChecklistData);
                          }
                        } catch (error: any) {
                          setError(error?.message);
                        }
                      }}
                    >
                      {!checklistHeaderData?.agency?.agencyName ? <option>Select Agency</option> : <option>{checklistHeaderData?.agency?.agencyName}</option>}
                      {foremanData?.jobs
                        .filter((_j: any) => _j.job !== null)
                        .map((data: WorksiteEmployees, idx) => {
                          return (
                            <option key={idx} value={idx}>
                              {data.job.agency.agencyName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="header-2-col2checklist">
                    <label className="header-2-col2checklist-label">LOCATION:</label>
                    <input
                      className="header-2-col2checklist-location"
                      type="text"
                      value={checklistHeaderData?.location}
                      onChange={e => {
                        console.log("LOCATION: ", e.target.value);
                        setChecklistHeaderData({ ...checklistHeaderData, location: e.target.value });
                      }}
                      onBlur={async () => {
                        const updatedChecklistData = await updateChecklistData({
                          location: checklistHeaderData?.location,
                        });
                        if (updatedChecklistData!) {
                          setChecklistHeaderData(updatedChecklistData);
                        }
                      }}
                    />

                    {/* <div className="header-2-col2checklist-location">{checklistHeaderData?.location}</div> */}
                  </div>
                  {/* <div className="header-2-col2checklist">
                        <label className="header-2-col2checklist-label">JOB NAME:</label>
                        <div className="header-2-col2checklist-jobname">{shiftData?.jobName}</div>
                      </div> */}
                </div>
              </>
            )}
          </div>
          <div className="header-3">
            <div className="header-3-col1">
              {headerType !== "checklist" && (
                <>
                  <label htmlFor="typeOfWork">WORK TYPE</label>
                  <select
                    className="header-3-col1-worktype"
                    name="typeOfWork"
                    id="typeOfWork"
                    onChange={async e => {
                      try {
                        await updateWorkType(e.target.value, jobFocus);
                        const job = await getShiftData(jobFocus);
                        setShiftData(job);
                        window.location.reload();
                      } catch (error) {
                        alert("Please Enter Job Shift");
                      }
                    }}
                    value={shiftData?.workType}
                  >
                    <option value="">Select Work Type</option>
                    <option value={TypeOfWork.contract}>{TypeOfWork.contract}</option>
                    <option value={TypeOfWork.changeOrder}>{TypeOfWork.changeOrder}</option>
                    <option value={TypeOfWork.tAndM}>{TypeOfWork.tAndM}</option>
                    <option value={TypeOfWork.extraWork}>{TypeOfWork.extraWork}</option>
                  </select>
                </>
              )}
            </div>

            {/* <div className="header-3-col2">
              <select
                className="workreport-main-tasks-table-row-content-input matQtyInput"
                name="imageActions"
                id="imageActions"
                onChange={e => {
                  handleJobImageView(e.target.value);
                }}
                value={selectedImageAction ? selectedImageAction : ""}
              >
                <option value="none" selected>
                  Actions
                </option>
                {imageActions.map((a: string, idx: number) => {
                  return (
                    <option key={idx} value={a}>
                      {a}
                    </option>
                  );
                })}
              </select>
            </div> */}

            <div className="header-3-col2">
              {headerType !== "checklist" ? (
                <>
                  <div className="header-3-col2-date">
                    <label>TASK START:</label>
                    <input className="header-3-col2-date-picker" type="date" value={formattedDate()} />
                  </div>
                  <div className="header-3-col2-date">
                    <label>TASK COMPLETION:</label>
                    <input className="header-3-col2-date-picker" type="date" value={formattedDate()} />
                  </div>
                </>
              ) : (
                <>
                  <div className="header-3-col2-date-checklist">
                    <label>DATE:</label>
                    <DateTimePicker
                      className="header-3-col2-date-picker-checklist"
                      onChange={(val: any) => {
                        saveChecklistDate(val);
                      }}
                      value={checklistDate!}
                    />
                  </div>
                  <div className="header-3-col2-date-checklist">
                    <label>DATE PREPARED:</label>
                    <DateTimePicker
                      className="header-3-col2-date-picker-checklist"
                      onChange={(val: any) => {
                        saveChecklistDatePrepared(val);
                      }}
                      value={checklistDatePrepared!}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <>{showPdfAttachmentsModal && renderAddPdfModal()}</>
      <>{showImageAttachmentsModal && renderImageAttachmentsModal()}</>
    </div>
  );
};

export default Header;
