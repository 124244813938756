import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { getAgencies, createAgency, editAgency, removeAgency } from "../../../graphql/queries";
import { getUser } from "../../../api/auth";

import AdminMenu from "../AdminMenu";

import "./Agency.scss";

const Agency: React.FC = () => {
  const [agencies, setAgencies] = useState<any | null>(null);
  const [createAgencyName, setCreateAgencyName] = useState<string>("");
  const [updateAgencyName, setUpdateAgencyName] = useState<string>("");
  const [message, setMessage] = useState<{ text: string }>({
    text: "",
  });
  const [selectedAgency, setSelectedAgency] = useState<{ agencyName: string; id: string } | null>(null);

  const [createNewAgency, { data: createAgencyData, loading: createAgencyLoading, error: createAgencyError }] = useMutation(createAgency);

  const [updateAgency, { data: updateAgencyData, loading: updateAgencyLoading, error: updateAgencyError }] = useMutation(editAgency);

  const [deleteAgency, { data: deleteAgencyData, loading: deleteAgencyLoading, error: deleteAgencyError }] = useMutation(removeAgency);

  const fetchAgencies = async () => {
    const currentAgencies = await getAgencies();
    if (currentAgencies) {
      setAgencies(currentAgencies);
    }
  };

  useEffect(() => {
    fetchAgencies();
  }, []);

  const renderAgencyList = () => {
    return (
      <div className="agency-wrapper-agencylist">
        <h4 className="agency-wrapper-agencylist-title">Agencies</h4>
        <ul className="agency-wrapper-agencylist-list">
          {agencies?.data?.agencies?.map((agency: { id: string; agencyName: string }) => {
            return (
              <li className={`agency-wrapper-agencylist-list-item ${agency.id === selectedAgency?.id ? "itemselected" : ""}`} key={agency.id} onClick={() => setSelectedAgency(agency)}>
                {agency.agencyName}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleSubmitNewAgency = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const data = await createNewAgency({ variables: { input: { agencyName: createAgencyName } } });
    if (data) {
      alert(`${createAgencyName} has been successfully created`);
      setCreateAgencyName("");
      window.location.reload();
    }
  };

  const handleUpdatedAgency = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const data = await updateAgency({ variables: { input: { agencyName: updateAgencyName, id: selectedAgency?.id } } });
    if (data) {
      alert(`${selectedAgency?.agencyName} has been successfully updated.`);
      setUpdateAgencyName("");
      window.location.reload();
    }
  };

  const handleRemoveAgency = async (id: string) => {
    await deleteAgency({ variables: { id } });
    alert(`${selectedAgency?.agencyName} has been successfully removed`);
    window.location.reload();
  };

  const renderAgencyEdit = () => {
    return (
      <form className="agency-agencyedit">
        <div className="agency-agencyedit-create">
          <h4 className="agency-agencyedit-create-title">Create</h4>
          <label className="agency-agencyedit-create-inputlabel" htmlFor="agencyname">
            Create Agency
          </label>
          <input
            className="agency-agencyedit-create-input"
            type="text"
            id="agencyname"
            placeholder="Agency Name"
            value={createAgencyName}
            onChange={e => {
              setCreateAgencyName(e.target.value);
            }}
          />
          <div className="agency-agencyedit-create-btngroup">
            <button
              className="agency-agencyedit-create-btngroup-savebtn"
              type="submit"
              onClick={e => {
                handleSubmitNewAgency(e);
              }}
            >
              Save
            </button>

            <button
              className="agency-agencyedit-create-btngroup-savebtn"
              type="button"
              onClick={e => {
                setCreateAgencyName("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>

        <div className="agency-agencyedit-create">
          <h4 className="agency-agencyedit-create-title">Edit</h4>
          <label className="agency-agencyedit-create-inputlabel" htmlFor="agencyname">
            Edit Agency
          </label>

          <p className="agency-agencyedit-create-editname">{selectedAgency?.agencyName}</p>
          <input
            className="agency-agencyedit-create-input"
            type="text"
            id="agencyname"
            placeholder="Agency Name"
            value={updateAgencyName}
            onChange={e => {
              setUpdateAgencyName(e.target.value);
            }}
          />
          <div className="agency-agencyedit-create-btngroup">
            <button
              className="agency-agencyedit-create-btngroup-savebtn"
              type="submit"
              onClick={e => {
                handleUpdatedAgency(e);
              }}
            >
              Save
            </button>

            <button
              className="agency-agencyedit-create-btngroup-savebtn"
              type="button"
              onClick={e => {
                setUpdateAgencyName("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  if (!agencies) {
    return <p>Loading please wait...</p>;
  }
  return (
    <div className="agency">
      <AdminMenu />
      {getUser().level === 1 && (
        <button
          className={`agency-removebtn ${selectedAgency && "remove-selected"}`}
          type="button"
          onClick={() => {
            handleRemoveAgency(selectedAgency?.id!);
          }}
        >
          Remove Selected Agency
        </button>
      )}

      <div className="agency-wrapper">
        <>{renderAgencyList()}</>
        <>{renderAgencyEdit()}</>
      </div>
    </div>
  );
};

export default Agency;
