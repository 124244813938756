import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import imageCompression from "browser-image-compression";
import { readFileSync } from "fs";

import AdminMenu from "../AdminMenu";
import { ChecklistReport } from "./ChecklistReport";
import { ChecklistEdit } from "./ChecklistEdit";
import ChecklistCreator from "./ChecklistCreator";
import { getUser } from "../../../api/auth";
import { getChecklistReportsList } from "../../../graphql/queries";
import EmailChecklist from "./EmailChecklist";
import { emailReport } from "../../../api/pdf";

import "./index.scss";

const devUrl = "http://localhost:3000";
const prodUrl = "http://workforce-daily-report.com";

const reportUrl = process.env.NODE_ENV === "development" ? devUrl : prodUrl;

export default () => {
  const [checklistReportList, setChecklistReportList] = useState<any | null>(null);
  const [selectedChecklist, setSelectedChecklist] = useState<any | null>(null);
  const [showChecklistCreator, setShowChecklistCreator] = useState<boolean>(false);
  const [editChecklist, setEditChecklist] = useState<boolean>(false);
  const [showEmailControls, setShowEmailControls] = useState<boolean>(false);
  const [emailRecipient, setEmailRecipient] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");

  const fetchChecklistreportList = async () => {
    const clData = await getChecklistReportsList();
    if (clData) {
      console.log("CHECKLIST DATA: ", clData);
      setChecklistReportList(clData?.checklists);
    }
  };

  const openReportInNewTab = (uri: any) => {
    // window.open(uri, "_blank", "noreferrer")
    window.open(uri, "_blank")?.focus();
  };

  // console.log('SELECTED CHECKLIST: ', selectedChecklist);

  const saveToPdf = async () => {
    // console.log('EMAIL CHECKLIST BODY STUFF: ', emailRecipient, emailBody,)
    const emailValidated = validateEmail(emailRecipient);
    if (!emailRecipient) {
      alert("You have entered an invalid email address!");
      setEmailRecipient("");
      return;
    } else {
      const content = await document.getElementById("clreport")!;
      const doc = new jsPDF("l", "mm", [1200, 1210]);
      doc.setFontSize(11.25);
      doc.html(content, {
        callback: async doc => {
          // await doc.save('sample-checklist.pdf')
          // doc.addImage(selectedChecklist?.additionalRemarks.signature1, 'PNG', 5, 10, 118, 16);
          // doc.addImage(selectedChecklist?.additionalRemarks.signature1, 'JPEG', Math.trunc(getPosition()?.signature1.x!), Math.trunc(getPosition()?.signature1.y!), 118, 16);
          // doc.addImage(selectedChecklist?.additionalRemarks.signature2, 'JPEG', Math.trunc(getPosition()?.signature2.x!), Math.trunc(getPosition()?.signature2.y!), 118, 16);
          // doc.addImage(selectedChecklist?.additionalRemarks.signature3, 'JPEG', Math.trunc(getPosition()?.signature3.x!), Math.trunc(getPosition()?.signature3.y!), 118, 16);
          const outputPdf = await doc.output();
          // const emailRes = await emailReport(emailRecipient, emailBody, outputPdf);
          // console.log('FINISHED SENDING EMAIL RESPONSE: ', emailRes);
          setShowEmailControls(false);
          alert("The checklist has been successfully emailed.");
          setEmailRecipient("");
          setEmailBody("");
        },
      });
    }
  };

  const strToByteArr = (str: string) => new TextEncoder().encode(str);

  const exportPDF = () => {
    const input = document.getElementById("clreport");
    html2canvas(input!, { logging: true, useCORS: true }).then(async canvas => {
      const imageWidth = 208;
      const imgHeight = (canvas.height * imageWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      // const pdf = new jsPDF('p', 'mm', 'a4');
      const pdf = new jsPDF("p", "mm", [395.28, 241.89], true);
      pdf.addImage(imgData, "PNG", 0, 0, imageWidth, imgHeight);
      // pdf.save();
      const outputPdf = await pdf.output("arraybuffer");
      const emailRes = await emailReport(emailRecipient, emailBody, outputPdf, `${reportUrl}/checklistreport-download?checklistid=${selectedChecklist?.id}`);
      setShowEmailControls(false);
      alert("The checklist has been successfully emailed.");
      setEmailRecipient("");
      setEmailBody("");
    });
  };

  const validateEmail = (emailAddress: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return true;
    }
    return false;
  };

  const getPosition = () => {
    let sig1 = document.getElementById("signature1")?.getBoundingClientRect();
    let sig2 = document.getElementById("signature2")?.getBoundingClientRect();
    let sig3 = document.getElementById("signature3")?.getBoundingClientRect();
    // console.log("SIGNATURE ELEMENT POSITION: ", {
    //   signature1: { x: sig1?.left, y: sig1?.top },
    //   signature2: { x: sig2?.left, y: sig2?.top },
    //   signature3: { x: sig3?.left, y: sig3?.top },
    // });
    const signaturePositions = {
      signature1: { x: sig1?.left, y: sig1?.top },
      signature2: { x: sig2?.left, y: sig2?.top },
      signature3: { x: sig3?.left, y: sig3?.top },
    };
    return signaturePositions;
  };

  useEffect(() => {
    fetchChecklistreportList();
  }, []);

  const renderCLRList = () => {
    return (
      <div className="cl-container-reports">
        <h4 className="cl-container-reports-title">Checklists</h4>
        <ul className="cl-container-reports-list">
          {checklistReportList?.map((cl: any) => {
            return (
              <li
                className={`cl-container-reports-list-item ${cl.id === selectedChecklist?.id ? "selecteditem" : ""}`}
                key={cl.id}
                onClick={() => {
                  setSelectedChecklist(cl);
                }}
              >
                <div className="cl-container-reports-list-item-container">
                  <p className="cl-container-reports-list-item-container-title">{cl.checklistType}</p>
                  <p className="cl-container-reports-list-item-container-date">Submitted on {new Date(cl.date).toDateString()}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  // if (selectedChecklist) {
  //   getPosition();
  // }

  return (
    <div className="cl">
      <AdminMenu />
      <div className="cl-btngrp">
        {!showChecklistCreator && (
          <>
            <button className="cl-btngrp-printbtn" role="link" onClick={() => openReportInNewTab(`${reportUrl}/checklistreports-to-print`)}>
              Prepare For Printing
            </button>

            <button
              className="cl-btngrp-edit"
              role="link"
              onClick={() => {
                if (selectedChecklist) setEditChecklist(!editChecklist);
                else alert("Please select a Checklist to edit");
              }}
            >
              {editChecklist ? "Done Editing" : "Edit Selected Checklist"}
            </button>
            <button
              className="cl-btngrp-email"
              role="link"
              onClick={async () => {
                if (selectedChecklist) {
                  // await saveChecklistPdf();
                  // openReportInNewTab(`${devUrl}/checklistreports-pdf`);
                  setShowEmailControls(!showEmailControls);
                } else alert("Please select a Checklist to email");
              }}
            >
              Email Selected Checklist
            </button>
          </>
        )}

        <button className="cl-btngrp-creator" role="link" onClick={() => setShowChecklistCreator(!showChecklistCreator)}>
          {!showChecklistCreator ? "Checklist Creator" : "Checklist Viewer"}
        </button>
      </div>
      {showEmailControls && (
        <EmailChecklist
          sendEmail={() => {
            // saveToPdf();
            exportPDF();
            // convertRecipientString();
          }}
          handleRecipientInput={e => {
            if (e.target.name === "recipient") {
              setEmailRecipient(e.target.value);
            } else if (e.target.name === "body") {
              setEmailBody(e.target.value);
            }
          }}
          emailRecipient={emailRecipient}
          emailBody={emailBody}
        />
      )}

      <div className="cl-container">
        {showChecklistCreator && <ChecklistCreator />}
        {!showChecklistCreator && !editChecklist && (
          <>
            {renderCLRList()}
            <ChecklistReport report={selectedChecklist} />
          </>
        )}
        {editChecklist && !showChecklistCreator && (
          <>
            {renderCLRList()}
            <ChecklistEdit report={{ ...selectedChecklist }} />
          </>
        )}
      </div>
    </div>
  );
};
