const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const d = new Date();
const monthName = month[d.getMonth()];
const day = d.getDate();
const year = d.getFullYear();

export const getTheDate = () => `${monthName} ${day} ${year}`;

export const getTheTime = () => d.toLocaleTimeString();

export const formattedDate = () => {
  const theDate = new Date();
  let month = theDate.getMonth() + 1 < 10 ? `0${theDate.getMonth() + 1}` : theDate.getMonth() + 1;
  let day = theDate.getDate() < 10 ? `0${theDate.getDate()}` : theDate.getDate();
  let year = theDate.getFullYear();
  let theReverseDate = `${year}-${month}-${day}`;
  return theReverseDate;
};

export const formatTheDate = (date: Date) => {
  const theDate = new Date(date);
  let month = theDate.getMonth() + 1 < 10 ? `0${theDate.getMonth() + 1}` : theDate.getMonth() + 1;
  let day = theDate.getDate() < 10 ? `0${theDate.getDate() + 2}` : theDate.getDate() + 2;
  let year = theDate.getFullYear();
  let theReverseDate = `${year}-${month}-${day}`;
  return theReverseDate;
};

export const setTimeString = () => {
  let now = new Date();
  let hour = now.getHours();
  let minutes: number | string;
  minutes = now.getMinutes();
  let ampm = "AM";
  if (minutes >= 15) {
    minutes = 30;
  } else if (minutes < 15) {
    minutes = "00";
  } else if (minutes >= 45) {
    minutes = "00";
    ++hour;
  } else if (minutes < 45) {
    minutes = 30;
  }
  if (hour > 12) {
    hour -= 12;
    ampm = "PM";
  } else if (hour === 12) {
    ampm = "PM";
  } else if (hour === 0) {
    hour += 12;
    ampm = "AM";
  }
  return hour + ":" + minutes + ampm;
};

export const setFormattedTime = () => {
  let now = new Date();
  let hour = now.getHours();
  let minutes: number | string;
  minutes = now.getMinutes();
  minutes >= 45 ? ((minutes = "00"), ++hour) : (minutes = now.getMinutes());
  if ((minutes as number) >= 15) {
    minutes = 30;
  } else if ((minutes as number) < 15) {
    minutes = "00";
  } else if ((minutes as number) < 45) {
    minutes = 30;
  }
  console.log("SET FORMATTED TIME: ", hour + ":" + minutes);
  return hour < 10 ? "0" + hour : hour + ":" + minutes;
};
