import axios from "axios";

const devUrl = "http://localhost:9000";
const prodUrl = "https://server.workforce-daily-report.com";

const URL = process.env.NODE_ENV === "development" ? devUrl : prodUrl;

const instance = axios.create({
  baseURL: URL,
});

const headers = {
  "Content-Type": "multipart/form-data",
};

export const uploadJobImages = async (files: any[]) => {
  const formData = new FormData();
  files.forEach((file: any, idx: number) => {
    formData.append(`img${idx + 1}`, file.img);
    formData.append(`txt${idx + 1}`, file.txt);
  });

  const res = await instance.post(`/images/upload-group`, formData, { headers });
  return res;
};

export const uploadJobImage = async (file: { img: string; txt: string; job: string; foreman: string; date: string; shift: number }) => {
  const { img, txt, job, foreman, date, shift } = file;
  // const formData = new FormData();
  // formData.append("image", img);
  // formData.append("txt", txt);
  // formData.append("job", job);
  // formData.append("date", date);
  // formData.append("foreman", foreman);
  // formData.append("shift", shift.toString());
  // console.log("FORM DATA: ", Object.fromEntries(formData));
  // const dataObj = Object.fromEntries(formData);
  // const res = await instance.post("/images/upload", formData, { headers });
  const imgRes = await instance.postForm("/images/upload", file);
  return imgRes;
};

export const uploadImageId = async (file: { img: string; employeeId: string; employeeName: string }) => {
  const res = await instance.postForm("/image-id/upload-one", file);
  return res;
};

export const getJobImages = async () => {
  const res = await instance.get("/images/list");
  // console.log("RES: ", res.data.Contents);
  return res.data;
};

export const jobImageList = async () => {
  const jobImgArr: any[] = [];
  const res = await instance.get("/job-image-list");
  return res.data;
};

export const getJobImage = async () => {
  const res = await instance.get("/images/image");
  return res;
};

export const getImageId = async (employeeId: string) => {
  try {
    const res = await instance.get(`/image-id/${employeeId}`);
    return res;
  } catch (error: any) {
    console.log("GET IMAGE ID API ERROR: ", error);
    return { err: error.response.data.message };
  }
};
