import { ForemanData } from "../types";
import { getReportNo } from "./report";
import { setFormattedTime, formattedDate } from "../helpers/dateTime";

export const createInitData = async (data: any) => {
  if (!(await localStorage.getItem("initData"))) {
    await localStorage.setItem("initData", JSON.stringify({ [`${data?.jobName}`]: data }));

    await localStorage.setItem(`${data?.jobName}`, JSON.stringify(data));

    await localStorage.setItem("verifiedWorkers", JSON.stringify([]));

    let currentWeather = JSON.parse(await localStorage.getItem("currentWeather")!);
    let initShiftData = JSON.parse(await localStorage.getItem(`${data?.jobName}`)!);
    const updatedShiftData = {
      ...initShiftData,
      forcast: currentWeather?.weather[0]?.description,
      temperature: currentWeather?.main?.temp,
      canRecall: true,
      hasBeenRecalled: false,
    };
    await localStorage.setItem(`${data?.jobName}`, JSON.stringify(updatedShiftData));
    await localStorage.removeItem("initData");
    initShiftData = JSON.parse(await localStorage.getItem(`${data?.jobName}`)!);
    return initShiftData;
  }
};

export const setSiteForeman = async (data: any) => {
  if (!(await localStorage.getItem("siteForeman"))) {
    await localStorage.setItem("siteForeman", JSON.stringify(data));
    return true;
  }
};

export const getSiteForeman = async () => {
  if (await localStorage.getItem("siteForeman")) {
    const foreman = await JSON.parse(localStorage.getItem("siteForeman")!);
    return foreman;
  }
  if (!(await localStorage.getItem("siteForeman"))) {
    throw new Error("This site has no Foreman");
  }
};

export const createVerifiedWorker = async (worker: any) => {
  let data: any[] = JSON.parse(await localStorage.getItem("verifiedWorkers")!);
  const existingData = data.find((d: any) => d.id === worker.id);
  if (!existingData) {
    worker.sign = "in";
    worker.verified = false;
    data.push(worker);
    localStorage.setItem("verifiedWorkers", JSON.stringify(data));
  }
  return data;
};

export const setVerifiedWorkerSignout = async (workerId: string) => {
  let data: any[] = JSON.parse(await localStorage.getItem("verifiedWorkers")!);
  const existingData = data.find((d: any) => d.id === workerId);
  if (existingData) {
    existingData.sign = "out";
    const dataIndex = data.indexOf(existingData);
    data.splice(dataIndex, 1, existingData);
    localStorage.setItem("verifiedWorkers", JSON.stringify(data));
  }
  return data;
};

export const removeVerifiedWorker = (workerId: string) => {
  let data: any[] = JSON.parse(localStorage.getItem("verifiedWorkers")!);
  const existingData = data.find((d: any) => d.id === workerId);
  if (existingData) {
    const dataIndex = data.indexOf(existingData);
    data.splice(dataIndex, 1);
    localStorage.setItem("verifiedWorkers", JSON.stringify(data));
  }
  return data;
};

export const getVerifiedWorker = (workerId: string) => {
  let data: any[] = JSON.parse(localStorage.getItem("verifiedWorkers")!);
  const existingData = data.find((d: any) => d.id === workerId);
  if (existingData) {
    return existingData;
  }
};

export const setWorkerVerification = (workerId: string) => {
  let data: any[] = JSON.parse(localStorage.getItem("verifiedWorkers")!);
  const foundData = data.find((w: any) => w.id === workerId);
  console.log("FOUND DATA: ", foundData);
  foundData.verified = true;
  localStorage.setItem("verifiedWorkers", JSON.stringify(data));

  return data;
};

export const getVerifiedWorkers = async () => {
  let data: any[] = JSON.parse(await localStorage.getItem("verifiedWorkers")!);
  return data;
};

export type focusType = {
  jobName: string;
  shift: string;
};

export type tasksType = {
  title: string;
  status: string;
  progress: string;
  details: string;
};

export type materialsDesc = {
  qty: number;
  um: string;
  unitCost: number;
  totalCost?: number;
  item?: string;
  description: string;
  images: string[];
  workzone: string;
};

export const createFocus = async (focus: focusType) => {
  if (!(await localStorage.getItem("focus"))) {
    await localStorage.setItem("focus", JSON.stringify(focus));
  } else {
    const currentFocus = await JSON.parse(localStorage.getItem("focus")!);
    const updatedFocus = {
      currentFocus,
      ...focus,
    };
    await localStorage.setItem("focus", JSON.stringify(updatedFocus));
  }
};

export const setFocus = async (focus: focusType) => {
  await localStorage.setItem("focus", JSON.stringify(focus));
  return true;
};

export const getFocus = async () => {
  if (await localStorage.getItem("focus")) {
    const currentFocus = await JSON.parse(localStorage.getItem("focus")!);
    return currentFocus;
  }
};

export const createJobData = async (data: any, shift: string, jobName: string) => {
  const foreman = await getSiteForeman();
  if (!(await localStorage.getItem(`${jobName}`))) {
    const newSiteData = {
      ...data,
      foreman: foreman.firstName + " " + foreman.lastName,
      shift,
    };
    await localStorage.setItem(`${jobName}`, JSON.stringify(newSiteData));
    return true;
  } else {
    const currentData = JSON.parse(await localStorage.getItem(`${jobName}`)!);
    let updatedData = {
      ...currentData,
      foreman: foreman.firstName + " " + foreman.lastName,
      shift,
    };
    await localStorage.setItem(`${jobName}`, JSON.stringify(updatedData));
    updatedData = JSON.parse(await localStorage.getItem(`${jobName}`)!);
    return updatedData;
  }
};

export const getShiftData = async (focus: focusType) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    return currentJob;
  }
};

export const createShift = async (focus: focusType) => {
  console.log("SETTING SHIFT2...");
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const job = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    let reportCount = await getReportNo();

    let reportNoPrefix = job.docPrefix;
    let reportNo = `${reportNoPrefix}-${(reportCount as number) + 1}`;
    const updatedJob = {
      ...job,
      shift: focus?.shift,
      reportNo,
    };
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
    const shiftData = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else {
    return false;
  }
};

export const clearAllShiftData = () => {
  localStorage.clear();
};

export const doesShiftExist = async (focus: focusType) => {};

export const updateWorkType = async (type: string, focus: focusType) => {
  let siShift = {};
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const updatedShift = {
      ...siShift,
      workType: type,
    };
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
  }
};

export const updateStartDate = async (date: {}, focus: focusType) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        startDate: date,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
    }
  }
};

export const updateTaskCompletion = async (date: {}, focus: focusType) => {
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);

    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        taskCompletionDate: date,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
    }
  }
};

export const updateLocation = async (workzones: string[], focus: focusType) => {
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);

    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        workzones,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
    }
  }
  const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
  return shiftData;
};

export const updateEmployeeTimeIn = async (focus: focusType, workerId: string, timeIn: Date) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.timeIn = timeIn.toString();
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };
    localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const upateEmployeeTimeOut = async (focus: focusType, workerId: string, timeOut: Date) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.timeOut = timeOut.toString();
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else {
    return false;
  }
};

export const updateCostCode = async (focus: focusType, workerId: string, costcode: string, laborCode: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.costcode = costcode;
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateEmployeeRegularTime = async (focus: focusType, workerId: string, regularTime: number) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.regularTime = regularTime;
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateTimeInSignature = async (focus: focusType, workerId: string, timeInSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.timeInSignature = timeInSignature;
    foundData.timeIn = new Date(`${formattedDate()}T${setFormattedTime()}:00`);
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    return true;
  } else return false;
};

export const updateTimeOutSignature = async (focus: focusType, workerId: string, timeOutSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.timeOutSignature = timeOutSignature;
    foundData.timeOut = new Date(`${formattedDate()}T${setFormattedTime()}:00`);
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const updatedData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return updatedData;
  } else return false;
};

export const updateEmployeeDoubleTime = async (focus: focusType, workerId: string, doubleTime: number) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.doubleTime = doubleTime;
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateEmployeeDoubleTimeSignature = async (focus: focusType, workerId: string, doubletimeSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.doubletimeSignature = doubletimeSignature;
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateNotesSignature = async (focus: focusType, workerId: string, notes: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }

  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    const siteEmployees = siShift.siteEmployees;
    const employees: any[] = siteEmployees.employees;
    const foundData = employees.find((e: any) => e._id === workerId);
    const foundDataIdx = employees.findIndex((e: any) => e._id === workerId);
    foundData.notes = notes;
    employees.splice(foundDataIdx, 1, foundData);
    siteEmployees.employees = employees;
    const updatedShift = {
      ...siShift,
      siteEmployees,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updatePM = async (focus: focusType, foreman: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      foreman,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updatePMSignature = async (focus: focusType, foremanSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      foremanSignature,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));

    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updatePMName = async (focus: focusType, foreman: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      foreman,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return {};
};

export const updateContractorRepSignature = async (focus: focusType, contractorRepSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      contractorRepSignature,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateContractorRepName = async (focus: focusType, contractorRep: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      contractorRep,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return {};
};

export const updateAgencyRepSignature = async (focus: focusType, agencyRepSignature: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      agencyRepSignature,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return false;
};

export const updateAgencyRepName = async (focus: focusType, agencyRep: string) => {
  if (!focus?.shift) {
    throw new Error("Please Enter job Shift");
  }
  if (await localStorage.getItem(`${focus?.jobName}`)) {
    const siShift = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);

    const updatedShift = {
      ...siShift,
      agencyRep,
    };

    await localStorage.removeItem(`${focus?.jobName}`);
    await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedShift));
    const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
    return shiftData;
  } else return {};
};

export const updateWorkDescription = async (focus: focusType, desc: string) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        workDescription: desc,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateRemarks = async (focus: focusType, remarks: string) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        remarks,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateIncidentReportQ = async (focus: focusType, incidentReport: string) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        incidentReport,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateIncidentReportText = async (focus: focusType, incidentReportText: string) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        incidentReportText,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateChangeOrderTitle = async (focus: focusType, titleOfChangeOrder: string) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        titleOfChangeOrder,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateTasks = async (focus: focusType, tasks: tasksType[]) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        tasks,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateMaterialDesc = async (focus: focusType, materialsDesc: materialsDesc[]) => {
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const updatedJob = {
        ...currentJob,
        materialsDesc,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const updateMaterialDescRowImages = async (images: string[]) => {
  const focus = await JSON.parse(localStorage.getItem("focus")!);
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);
    if (currentJob.shift === focus?.shift) {
      const idx = JSON.parse(localStorage.getItem("materialImageAction")!).idx;
      const m = [...currentJob.materialsDesc];
      let mImages = m[idx].images;
      mImages = images;
      m[idx].images = mImages;

      const updatedJob = {
        ...currentJob,
        materialsDesc: m,
      };
      await localStorage.setItem(`${focus?.jobName}`, JSON.stringify(updatedJob));
      const shiftData = JSON.parse(await localStorage.getItem(`${focus?.jobName}`)!);
      return shiftData;
    }
    return {};
  }
};

export const getMaterialDescRowImgIds = async () => {
  const focus = await getFocus();
  if (localStorage.getItem(`${focus?.jobName}`)) {
    const currentJob = await JSON.parse(localStorage.getItem(`${focus?.jobName}`)!);

    if (currentJob.shift === focus?.shift) {
      const idx = JSON.parse(localStorage.getItem("materialImageAction")!).idx;
      return currentJob.materialsDesc[idx]?.images;
    }
  }
};
