import axios, { HeadersDefaults } from "axios";
import Cookies from "js-cookie";

const authUrl = "http://localhost:9000/auth";
const prodAuthUrl = "https://server.workforce-daily-report.com/auth";

const AuthUrl = process.env.NODE_ENV === "development" ? authUrl : prodAuthUrl;

const headerConfig = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const instance = axios.create({
  baseURL: AuthUrl,
  headers: process.env.NODE_ENV === "production" ? headerConfig : {},
});

instance.defaults.withCredentials = true;

console.log("SERVER URL: ", AuthUrl);

export const userLogin = async (email: string, password: string) => {
  try {
    const res = await instance.post("/login", {
      email,
      password,
    });
    await localStorage.clear();
    Cookies.remove("userToken");
    Cookies.set("userToken", res.data?.userToken);
    return res.data;
  } catch (err) {
    console.log("LOGIN IN ERROR: ", err);
    return err;
  }
};

export const setUser = (userId: string, role: string, level: number) => {
  localStorage.setItem("user", JSON.stringify({ userId, role, level }));
};

export const userRegistration = async (userName: string, password: string) => {
  try {
    const res = await instance.post(`/register-user-authorization`, { userName, password });
    return res;
  } catch (error) {
    return error;
  }
};

export const logout = () => {
  localStorage.clear();
  Cookies.remove("userToken");
};

export const getUser = () => {
  if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) !== undefined) {
    const user = JSON.parse(localStorage.getItem("user")!);
    return user;
  }
  // else throw new Error('User is not logged in');
};

export const getIntCode = async () => {
  const res = await instance.get("/get-ic");
  return res;
};

export const confirmUser = async (email: string) => {
  try {
    const res = await instance.post("/confirm-user", { email });
    return res;
  } catch (error) {
    return error;
  }
};

export const setUserActive = async (email: string) => {
  try {
    const res = await instance.post(`/user-active/${email}`);
    return res;
  } catch (error) {
    return error;
  }
};
