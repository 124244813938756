import React, { useEffect, useState } from "react";
import "./WorkReports.scss";
import { useMutation } from "@apollo/client";

import { getWorkreportList, updateSigninSignoutWr } from "../../../graphql/queries";
import { formatTheDate } from "../../../helpers/dateTime";

interface WorkReportsProps {
  reportId?: string;
  report: any;
  save: boolean;
}

export const WorkReportsAdminEdit: React.FC<WorkReportsProps> = ({ report, save }) => {
  const [workReport, setWorkReport] = useState<any | null>(null);
  const [editReport, setEditReport] = useState<boolean>(true);

  const employeesList = workReport?.siteEmployees.map((e: any) => e);
  const notesList = employeesList?.filter((n: any) => n.notes !== null);

  const [updateSIWR, { data, loading, error }] = useMutation(updateSigninSignoutWr);

  const handleUpdateReport = async () => {
    if (!report) {
      alert("You must select a Work Report first");
    }
    const updatedData = await updateSIWR({
      variables: {
        input: {
          id: report.id,
          ...workReport,
        },
      },
    });
    const updatedList = await getWorkreportList();
    localStorage.setItem("workreport", JSON.stringify(updatedList));
    alert("Work Report Successfully Updated");
    window.location.reload();
  };

  useEffect(() => {
    setWorkReport(report);
    localStorage.setItem("workreport", JSON.stringify(report));
    if (save) {
      handleUpdateReport();
    }
  }, [report, save]);

  console.log("WORK REPORT: ", workReport, "SAVING UPDATES: ", save);

  return (
    <div
      className="dailyworkreport"
      style={{
        padding: "30px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="dailyworkreport-headerrow1"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <h1>BISSETTA & LIST, INC.</h1>
        <h1>DAILY WORK REPORT</h1>
        <h2>Doc No. {workReport?.reportNo}</h2>
      </div>
      <div
        className="dailyworkreport-headerrow2"
        style={{
          marginBottom: "5px",
        }}
      >
        <h4>Plumbing & Heating Contractors 420 W. 49th Street, New York, NY 10019</h4>
      </div>
      <div
        className="dailyworkreport-headerrow3"
        style={{
          marginBottom: "5px",
        }}
      >
        <h4>Tel: (212) 265-7771 Fax: (212) 489-2283 Email: reports@bissettalist.com</h4>
      </div>
      <div
        className="dailyworkreport-body"
        style={{
          width: "100%",
          border: "solid #000000 1px",
        }}
      >
        <div
          className="dailyworkreport-body-row1"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p
            style={{
              marginRight: "250px",
              paddingTop: "10px",
            }}
          >
            GC /CM /AGENCY:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.agency?.agencyName}
            </span>
          </p>
          <p>
            CONTRACT START DATE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {new Date(workReport?.contractStartDate).toLocaleDateString()}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row2"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p
            style={{
              marginRight: "300px",
              paddingTop: "10px",
            }}
          >
            CONTRACT #:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.contractNo}
            </span>
          </p>
          <p>
            SHIFT:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.shift}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row3"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            padding: "0 10px",
            // height: '20px'
          }}
        >
          <p>
            LOCATION:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.location || "TBD"}
            </span>
          </p>
          {workReport?.workLocation?.length > 0 && (
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "5rem",
              }}
            >
              <label
                style={{
                  marginRight: "1rem",
                }}
                htmlFor=""
              >
                WORK LOCATION:{" "}
              </label>
              <ul style={{ listStyle: "none", display: "flex", flexDirection: "row" }}>
                {workReport?.workLocation?.map((l: { name: string }, idx: number) => (
                  <li
                    key={idx}
                    style={{
                      position: "relative",
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>{l.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div
          className="dailyworkreport-body-row4"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p>
            JOB NAME:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.jobName}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row5"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p
            style={{
              marginRight: "300px",
              paddingTop: "10px",
            }}
          >
            WEATHER:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.forcast}
            </span>
          </p>
          <p>
            TEMPERATURE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.temperature}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row6"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p>
            WORK TYPE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.workType}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row7"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p>
            TITLE OF CHANGE ORDER / T & M / EXTRA WORK PERFORMED (if any):
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.titleOfChangeOrder || "N/A"}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-body-row8"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "solid #000000 1px",
            height: "20px",
            padding: "0 10px",
          }}
        >
          <p
            style={{
              marginRight: "200px",
              paddingTop: "10px",
            }}
          >
            REQUESTED BY:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {"N/A"}
            </span>
          </p>
          <p
            style={{
              marginRight: "200px",
              paddingTop: "10px",
            }}
          >
            TASK START DATE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {new Date(formatTheDate(workReport?.startDate)).toLocaleDateString()}
            </span>
          </p>
          <p>
            TASK COMPLETION DATE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {new Date(formatTheDate(workReport?.taskCompletionDate)).toLocaleDateString()}
            </span>
          </p>
        </div>
        <div className="dailyworkreport-body-employeecontainer">
          <table
            className="dailyworkreport-body-employeecontainer-table"
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead
              style={{
                height: "30px",
                backgroundColor: "#e8e7da",
              }}
            >
              <tr className="dailyworkreport-body-employeecontainer-table-headerrow">
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">EMPLOYEE</th>
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">SSN</th>
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">CLASS</th>
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">TIME IN</th>
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">TIME OUT</th>
                <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">WORK DESCRIPTION</th>
              </tr>
            </thead>
            <tbody>
              {employeesList?.map(
                (
                  e: {
                    firstName: string;
                    lastName: string;
                    last4SSN: string;
                    class: string;
                    timeIn: string;
                    timeOut: string;
                    timeInSignature: string;
                    timeOutSignature: string;
                  },
                  idx: number
                ) => {
                  // console.log("EMPLOYEE TO UPDATE: ", e);
                  return (
                    <tr key={idx} className="dailyworkreport-body-employeecontainer-table-headerrow-row1">
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "20%",
                          textAlign: "center",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        {e.firstName} {e.lastName}
                      </td>
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "5%",
                          textAlign: "center",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        {e.last4SSN}
                      </td>
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "5%",
                          textAlign: "center",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        {e.class}
                      </td>
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "10%",
                          textAlign: "center",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        {new Date(e.timeIn).toLocaleTimeString()}
                      </td>
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "10%",
                          textAlign: "center",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        {new Date(e.timeOut).toLocaleTimeString()}
                      </td>
                      <td
                        className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                        style={{
                          width: "450px",
                          border: "0.5px #000000 solid",
                        }}
                      >
                        <div
                          className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item-sig"
                          style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item-sig-in" style={{ marginRight: "auto", paddingLeft: "5px" }}>
                            <p>
                              SIGN IN:{" "}
                              <img
                                src={e?.timeInSignature}
                                style={{
                                  height: "10px",
                                  width: "50px",
                                  marginLeft: "10px",
                                }}
                              />
                            </p>
                            <p>Signature</p>
                          </div>
                          <div className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item-sig-out" style={{ marginRight: "auto" }}>
                            <p>
                              SIGN OUT:{" "}
                              <img
                                src={e?.timeOutSignature}
                                style={{
                                  height: "10px",
                                  width: "50px",
                                  marginLeft: "10px",
                                }}
                              />
                            </p>
                            <p>Signature</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <table
          className="dailyworkreport-body-employeecontainer-table"
          style={{
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead
            style={{
              height: "30px",
              backgroundColor: "#e8e7da",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <tr className="dailyworkreport-body-employeecontainer-table-headerrow">
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">COST CODE</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">EMPLOYEE</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">CLASS</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">REG HOURS</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">DT HOURS</th>
            </tr>
          </thead>
          <tbody>
            {employeesList?.map(
              (
                e: {
                  firstName: string;
                  lastName: string;
                  class: string;
                  regularTime: number;
                  doubleTime: number;
                  costcode: string;
                },
                idx: number
              ) => {
                return (
                  <tr
                    key={idx}
                    className="dailyworkreport-body-employeecontainer-table-headerrow-row1"
                    style={{
                      height: "20px",
                    }}
                  >
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {e?.costcode}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "15%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {e?.firstName} {e?.lastName}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {e?.class}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {editReport ? (
                        <input
                          name="regularTime"
                          type="number"
                          value={e.regularTime}
                          onChange={e => {
                            const tempEList = workReport?.siteEmployees.map((t: any, i: number) => {
                              if (i === idx) {
                                let p = { ...t };
                                p.regularTime = parseFloat(e.target.value);
                                return p;
                              }
                              return t;
                            });
                            setWorkReport({
                              ...workReport,
                              siteEmployees: [...tempEList],
                            });
                          }}
                        />
                      ) : (
                        e?.regularTime
                      )}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {editReport ? (
                        <input
                          name="doubleTime"
                          type="number"
                          value={e.doubleTime}
                          onChange={e => {
                            const tempEList = workReport?.siteEmployees.map((t: any, i: number) => {
                              if (i === idx) {
                                let p = { ...t };
                                p.doubleTime = parseFloat(e.target.value);
                                return p;
                              }
                              return t;
                            });
                            setWorkReport({
                              ...workReport,
                              siteEmployees: [...tempEList],
                            });
                          }}
                        />
                      ) : (
                        e?.doubleTime
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>

        <table
          className="dailyworkreport-body-employeecontainer-table"
          style={{
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead
            style={{
              height: "30px",
              backgroundColor: "#e8e7da",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <tr className="dailyworkreport-body-employeecontainer-table-headerrow">
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">EMPLOYEE</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">NOTES</th>
            </tr>
          </thead>
          <tbody>
            {notesList?.map(
              (
                e: {
                  firstName: string;
                  lastName: string;
                  notes: string;
                },
                idx: number
              ) => {
                return (
                  <tr
                    key={idx}
                    className="dailyworkreport-body-employeecontainer-table-headerrow-row1"
                    style={{
                      height: "20px",
                    }}
                  >
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "15%",
                        height: "50px",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {e?.firstName} {e?.lastName}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        padding: "0 5px",
                        width: "70%",
                        height: "50px",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                        textAlignLast: "left",
                      }}
                    >
                      {e?.notes}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>

        <div>
          <h4
            style={{
              border: "1px solid #000000",
              height: "35px",
              textAlign: "center",
              padding: "10px 0 10px 0",
              // backgroundColor: "#e8e7da",
              marginTop: "5px",
            }}
          >
            WORK DESCRIPTION
          </h4>
          <p
            style={{
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
              borderBottom: "1px solid #000000",
              // height: "50px",
              // height: '500px',
              padding: "10px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              maxWidth: "1452.52px",
              textAlign: "justify",
            }}
          >
            {workReport?.workDescription}
          </p>
        </div>

        <div className="pagebreak" />

        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h4
            style={{
              border: "1px solid #000000",
              height: "35px",
              textAlign: "center",
              padding: "10px 0 10px 0",
              backgroundColor: "#e8e7da",
              marginTop: "5px",
            }}
          >
            INCIDENT REPORT?
          </h4>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "30px",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
              borderBottom: "1px solid #000000",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                type="checkbox"
                style={{
                  marginRight: "5px",
                }}
                checked={workReport?.incidentReport === "yes"}
              />
              <label htmlFor="inidentReportYes">YES</label>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                type="checkbox"
                style={{
                  marginRight: "5px",
                }}
                checked={workReport?.incidentReport === "no"}
              />
              <label htmlFor="inidentReportYes">NO</label>
            </div>
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
                borderBottom: "1px solid #000000",
                // height: "50px",
                // height: '500px',
                padding: "10px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                maxWidth: "1452.52px",
                textAlign: "justify",
              }}
            >
              {workReport?.incidentReportText}
            </p>
          </div>
        </div>

        <table
          className="dailyworkreport-body-employeecontainer-table"
          style={{
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead
            style={{
              height: "30px",
              backgroundColor: "#e8e7da",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <tr
              className="dailyworkreport-body-employeecontainer-table-headerrow"
              style={{
                height: "30px",
                borderBottom: "1px solid #000000",
              }}
            >
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">QTY</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">UM</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">MATERIAL DESCRIPTION and/or DELIVERIES</th>
              {/* <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">UNIT COST</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">TOTAL COST</th> */}
            </tr>
          </thead>
          <tbody>
            {workReport?.materialsDesc?.map(
              (
                m: {
                  qty: string;
                  item: string;
                  um: string;
                  description: string;
                  unitCost: number;
                  totalCost: number;
                },
                idx: number
              ) => {
                return (
                  <tr
                    key={idx}
                    className="dailyworkreport-body-employeecontainer-table-headerrow-row1"
                    style={{
                      height: "20px",
                    }}
                  >
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {m?.qty}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {m?.um}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "30%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                        overflowWrap: "break-word",
                      }}
                    >
                      {m?.description}
                    </td>
                    {/* <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "10%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      &#x24;{m?.unitCost}.00
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "10%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      &#x24;{m?.totalCost}.00
                    </td> */}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>

        <table
          className="dailyworkreport-body-employeecontainer-table"
          style={{
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead
            style={{
              height: "30px",
              backgroundColor: "#e8e7da",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <tr
              className="dailyworkreport-body-employeecontainer-table-headerrow"
              style={{
                height: "30px",
                borderBottom: "1px solid #000000",
              }}
            >
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">TASK</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">STATUS</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">PROGRESS</th>
              <th className="dailyworkreport-body-employeecontainer-table-headerrow-header">DETAILS</th>
            </tr>
          </thead>
          <tbody>
            {workReport?.tasks?.map(
              (
                m: {
                  title: string;
                  status: string;
                  progress: string;
                  details: string;
                },
                idx: number
              ) => {
                return (
                  <tr
                    key={idx}
                    className="dailyworkreport-body-employeecontainer-table-headerrow-row1"
                    style={{
                      height: "20px",
                    }}
                  >
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "15%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {m?.title}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {m?.status}
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "5%",
                        textAlign: "center",
                        border: "0.5px #000000 solid",
                      }}
                    >
                      {m?.progress} &#37;
                    </td>
                    <td
                      className="dailyworkreport-body-employeecontainer-table-headerrow-row1-item"
                      style={{
                        width: "30%",
                        textAlign: "left",
                        padding: "0 5px",
                        border: "0.5px #000000 solid",
                        overflowWrap: "break-word",
                      }}
                    >
                      {m?.details}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>

        <div className="dailyworkreport-remarks">
          <h4
            style={{
              border: "1px solid #000000",
              height: "35px",
              textAlign: "center",
              padding: "10px 0 10px 0",
              backgroundColor: "#e8e7da",
              marginTop: "5px",
            }}
          >
            REMARKS
          </h4>
          <p
            style={{
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
              borderBottom: "1px solid #000000",
              // height: "50px",
              // height: "500px",
              padding: "10px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              maxWidth: "1452.52px",
              textAlign: "justify",
            }}
          >
            {workReport?.remarks}
          </p>
        </div>
      </div>
      <div
        className="dailyworkreport-terms"
        style={{
          position: "relative",
          marginTop: "20px",
        }}
      >
        <p>
          <span>Terms & Conditions: </span>On or around February 11, 1997 the General Contractors’ Association and The New York State, City Of New York, Office Of The Controller, and relevant agencies had agreed that the site superintendent or other contractor representative when submitting the time sheet for that day would indicate the arrival time and departure time for each worker. I hereby certify that the above information is true and correct and represents all persons employed by Bissetta
          & List, Inc. on the above project on the date indicated.
        </p>
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <h4
          style={{
            border: "1px solid #000000",
            height: "35px",
            textAlign: "center",
            padding: "10px 0 10px 0",
            backgroundColor: "#e8e7da",
            marginTop: "5px",
          }}
        >
          APPROVED BY
        </h4>
        <div
          className="dailyworkreport-signatures1"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            // justifyContent: 'center',
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ margin: "0 3.8% 0 0" }}>
            SIGNATURE (CONTRACTOR REPRESENTATIVE):{" "}
            {
              <img
                src={workReport?.contractorRepSignature}
                style={{
                  height: "10px",
                  width: "100px",
                  margin: "0 0 0 5px",
                }}
              />
            }
          </p>
          <p style={{ margin: "0 0 0 13%", width: "300px" }}>
            NAME / TITLE:
            <span
              style={{
                margin: "0 70px 0 5px",
              }}
            >
              {workReport?.contractorRep}
            </span>
          </p>
          <p style={{ marginLeft: "auto", width: "150px" }}>
            DATE:
            <span
              style={{
                margin: "0 0 0 5%",
              }}
            >
              {new Date(workReport?.createdAt)?.toLocaleDateString()}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-signatures1"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            // justifyContent: 'space-between',
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ margin: "0 5% 0 0" }}>
            SIGNATURE (AGENCY REPRESENTATIVE):{" "}
            {
              <img
                src={workReport?.agencyRepSignature}
                style={{
                  height: "10px",
                  width: "80px",
                  marginLeft: "35px",
                }}
              />
            }
          </p>
          <p style={{ margin: "0 0 0 13%", width: "300px" }}>
            NAME / TITLE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.agencyRep}
            </span>
          </p>
          <p style={{ marginLeft: "auto", width: "150px" }}>
            DATE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {new Date(workReport?.createdAt)?.toLocaleDateString()}
            </span>
          </p>
        </div>
        <div
          className="dailyworkreport-signatures2"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            // justifyContent: 'space-between',
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div style={{ margin: "0 5% 0 0" }}>
            <p>
              SIGNATURE (BISSETTA & LIST, INC.):{" "}
              {
                <img
                  src={workReport?.foremanSignature}
                  style={{
                    height: "10px",
                    width: "80px",
                    marginLeft: "57px",
                  }}
                />
              }
            </p>
          </div>

          <p style={{ margin: "0 0 0 13%", width: "300px" }}>
            NAME / TITLE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {workReport?.foreman}
            </span>
          </p>
          <p style={{ marginLeft: "auto", width: "150px" }}>
            DATE:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {new Date(workReport?.createdAt).toLocaleDateString()}
            </span>
          </p>
        </div>
      </div>

      <div
        className="dailyworkreport-copyright"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
          // border: "1px solid #000000",
          height: "35px",
          padding: "10px 10px",
          // backgroundColor: "#e8e7da",
        }}
      >
        <p>BLws3001</p>
        <p>Copyright Bissetta & List, Inc. 2021 All Rights Reserved</p>
      </div>
    </div>
  );
};
export default WorkReportsAdminEdit;
