import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";

import Jobs from "./jobs/Jobs";
import Agency from "./agency/Agency";

import "./AdminMenu.scss";

const links = [
  {
    name: "Agencies",
    url: "/agencies",
  },
  {
    name: "Jobs",
    url: "/jobs",
  },
  {
    name: "Employees",
    url: "/employees",
  },
  {
    name: "Worksites",
    url: "/worksites",
  },
  {
    name: "Budget",
    url: "/budget",
  },
  {
    name: "Workreports",
    url: "/workreports",
  },
  {
    name: "Checklists",
    url: "/checklistreports",
  },
  {
    name: "Labor Reports",
    url: "/labor-reports",
  },
  {
    name: "Job Images",
    url: "/job-images",
  },
  {
    name: "PDFs",
    url: "/pdf-attachments",
  },
];

export const AdminMenu: React.FC = () => {
  const getPath = () => window.location.pathname;
  const renderLinks = () => {
    return (
      <div className="adminmenu-links">
        <ul className="adminmenu-links-list">
          {links.map((link, idx) => {
            return (
              // <li className='adminmenu-links-list-item' key={idx}>
              //   <Link className='adminmenu-links-list-item-link' to={link.url}>{link.name}</Link>
              // </li>
              <Link className="adminmenu-links-list-item" key={idx} to={link.url}>
                <li key={idx} className={`adminmenu-links-list-item-link ${getPath() === link.url && "selecteditem"}`}>
                  {link.name}
                </li>
              </Link>
            );
          })}
        </ul>

        <div className="adminmenu-routes"></div>
      </div>
    );
  };

  return (
    <div className="adminmenu">
      <>{renderLinks()}</>
    </div>
  );
};

export default AdminMenu;
