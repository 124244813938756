import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { getJobImages } from "../../../api/images";
import { getFocus, getMaterialDescRowImgIds, updateMaterialDescRowImages } from "../../../api/si";
import { getJobs } from "../../../graphql/queries";
import day from "dayjs";
import AdminMenu from "../AdminMenu";
import { getUser } from "../../../api/auth";

import "./JobImages.scss";
import { Navigate, useNavigate } from "react-router-dom";
interface IViweType {
  view: string;
  idx: number;
  from?: string;
  imgIds?: string[];
}

const JobImages: React.FC = () => {
  const [jobImages, setJobImages] = useState<any[]>([]);
  const [allImages, setAllImages] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [jobs, setJobs] = useState<[]>([]);
  const [selectedJob, setSelectedJob] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showMultiple, setShowMultiple] = useState<boolean>(false);
  const [showAllImgs, setShowAllImgs] = useState<boolean>(false);
  const [imagesToLink, setImagesToLink] = useState<any[]>([]);
  const [materialsRowImgIds, setMaterialsRowImgIds] = useState<string[]>([]);
  const [materialsRowImages, setMaterialsRowImages] = useState<any[]>([]);
  const [viewType, setViewType] = useState<IViweType | null>(null);

  const navigate = useNavigate();

  const fetchImages = async () => {
    const data = await getJobImages();
    setJobImages(data);
    setAllImages(data);
    const localData = { ...JSON.parse(localStorage.getItem("materialImageAction")!) };
    if (localData) {
      setViewType(localData);
    }

    if (localData?.from && localData?.from === "admin") {
      const imgIds = localData?.imgIds;
      setMaterialsRowImgIds([...imgIds]);
    } else {
      const imgIds = await getMaterialDescRowImgIds();
      setMaterialsRowImgIds([...imgIds]);
    }

    if (materialsRowImgIds.length > 0) {
      handleSetMaterialsRowImages();
    }
  };

  const fetchJobs = async () => {
    const jobs = await getJobs();
    setJobs(jobs.data.jobs);
  };

  const handleSetMaterialsRowImages = async () => {
    const tArr = [];
    for (let i = 0; i < materialsRowImgIds?.length; i++) {
      for (let j = 0; j < jobImages?.length; j++) {
        if (materialsRowImgIds[i] === jobImages[j]._id) {
          tArr.push(jobImages[j]);
        }
      }
    }
    setMaterialsRowImages(tArr);
  };

  const renderMaterialRowImagesList = () => {
    // const rowImages =
    return (
      <div className="jobimages-list">
        {materialsRowImages.map((img: any, idx: number) => {
          return (
            <div
              className="jobimages-list-imgcontainer"
              key={idx}
              onClick={() => {
                if (!showMultiple && !showAllImgs) {
                  setSelectedImage(img);
                } else {
                  if (!showAllImgs && imagesToLink && !imagesToLink?.find((i: any) => i === img._id)) {
                    setImagesToLink([...imagesToLink!, img._id]);
                  } else if (!showAllImgs && imagesToLink && imagesToLink?.find((i: any) => i === img._id)) {
                    const updatedImgState = imagesToLink?.filter((i: any) => i !== img._id);
                    setImagesToLink(updatedImgState);
                  }
                }
              }}
            >
              <p className="jobimages-list-imgcontainer-date">{new Date(img.details.date).toDateString()}</p>
              <img className="jobimages-list-imgcontainer-img" src={img.details.imgString} alt="job image" />
              <p className="jobimages-list-imgcontainer-job">{img.job}</p>
              <p className="jobimages-list-imgcontainer-txt">{img.details.txt}</p>
            </div>
          );
        })}
        {showAllImgs && (
          <div className="jobimages-list">
            {jobImages?.map((img: any, idx: number) => {
              return (
                <div
                  className="jobimages-list-imgcontainer"
                  key={idx}
                  onClick={() => {
                    if (!showMultiple) {
                      setSelectedImage(img);
                    } else {
                      if (materialsRowImgIds && !materialsRowImgIds?.find((i: any) => i === img._id)) {
                        setMaterialsRowImgIds([...materialsRowImgIds!, img._id]);
                      } else if (materialsRowImgIds && materialsRowImgIds?.find((i: any) => i === img._id)) {
                        const updatedImgState = materialsRowImgIds?.filter((i: any) => i !== img._id);
                        setMaterialsRowImgIds(updatedImgState);
                      }
                    }
                  }}
                >
                  <p className="jobimages-list-imgcontainer-date">{new Date(img.details.date).toDateString()}</p>
                  <img className="jobimages-list-imgcontainer-img" src={img.details.imgString} alt="job image" />
                  <p className="jobimages-list-imgcontainer-job">{img.job}</p>
                  <p className="jobimages-list-imgcontainer-txt">{img.details.txt}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderImagesList = () => {
    return (
      <div className="jobimages-list">
        {jobImages.map((img: any, idx: number) => {
          return (
            <div
              className="jobimages-list-imgcontainer"
              key={idx}
              onClick={() => {
                if (!showMultiple) {
                  setSelectedImage(img);
                } else {
                  if (imagesToLink && !imagesToLink?.find((i: any) => i === img._id)) {
                    setImagesToLink([...imagesToLink!, img._id]);
                  } else if (imagesToLink && imagesToLink?.find((i: any) => i === img._id)) {
                    const updatedImgState = imagesToLink?.filter((i: any) => i !== img._id);
                    setImagesToLink(updatedImgState);
                  }
                }
              }}
            >
              <p className="jobimages-list-imgcontainer-date">{new Date(img.details.date).toDateString()}</p>
              <img className="jobimages-list-imgcontainer-img" src={img.details.imgString} alt="job image" />
              <p className="jobimages-list-imgcontainer-job">{img.job}</p>
              <p className="jobimages-list-imgcontainer-txt">{img.details.txt}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSelectedImage = () => {
    return (
      <div className="jobimages-selectedimage">
        <p className="jobimages-selectedimage-viewallbtn" onClick={() => setSelectedImage(null)}>
          View All Images
        </p>
        <p className="jobimages-selectedimage-image-date">{new Date(selectedImage.date).toDateString()}</p>
        <img className="jobimages-selectedimage-image" src={selectedImage.details.imgString} alt="job image details" />
        <p className="jobimages-selectedimage-job">{selectedImage.job}</p>
        <p className="jobimages-selectedimage-text">{selectedImage.details.txt}</p>
      </div>
    );
  };

  const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedJob && !startDate) {
      alert("Enter a Job or Date range");
      return;
    } else if (selectedJob !== "" && !startDate) {
      const temp = [...allImages];
      const filtered = temp.filter((t: any) => t.job === selectedJob);
      setJobImages(filtered);
    } else if (!selectedJob && startDate && endDate) {
      const temp = [...allImages];
      const filtered = temp.filter((t: any) => day(t.date).format() >= day(startDate).format() && day(t.date).format() <= day(endDate).format());
      setJobImages(filtered);
    } else {
      const temp = [...allImages];
      const filtered = temp.filter((t: any) => t.job === selectedJob).filter((t: any) => t.date >= startDate && t.date <= endDate);
      setJobImages(filtered);
    }
  };

  const renderFilters = () => (
    <form
      className="jobimages-filters"
      onSubmit={e => {
        handleFilterSubmit(e);
      }}
    >
      <h3 className="jobimages-filters-header">Filter</h3>
      <div className="jobimages-filters-jobselector">
        <label className="jobimages-filters-jobselector-label" htmlFor="job-filter">
          Job
        </label>
        <select
          className="jobimages-filters-jobselector-selector"
          name="jobFilter"
          id="job-filter"
          onChange={e => {
            setSelectedJob(e.target.value);
          }}
        >
          <option className="jobimages-filters-jobselector-selector-option" value="default">
            Select a job
          </option>
          {jobs.map((job: { jobName: string }, idx: number) => {
            return (
              <option key={idx} className="jobimages-filters-jobselector-selector-option" value={job.jobName}>
                {job.jobName}
              </option>
            );
          })}
        </select>
      </div>

      <div className="jobimages-filters-dateselector">
        <label className="jobimages-filters-dateselector-label" htmlFor="date-filter">
          Date From
        </label>
        <input
          className="jobimages-filters-dateselector-selector"
          name="jobFilter"
          id="job-filter"
          type="date"
          onChange={e => {
            setStartDate(e.target.value);
          }}
        />
      </div>

      <div className="jobimages-filters-dateselector">
        <label className="jobimages-filters-dateselector-label" htmlFor="date-filter">
          Date To
        </label>
        <input
          className="jobimages-filters-dateselector-selector"
          name="jobFilter"
          id="job-filter"
          type="date"
          onChange={e => {
            setEndDate(e.target.value);
          }}
        />
      </div>
      <button className="jobimages-submitbtn" type="submit">
        Search
      </button>
      <button
        className="jobimages-resetbtn"
        type="button"
        onClick={() => {
          setSelectedJob("");
          setStartDate(null);
          setEndDate(null);
          setJobImages(allImages);
        }}
      >
        Reset
      </button>
    </form>
  );

  useEffect(() => {
    fetchImages();
    fetchJobs();
  }, []);

  useEffect(() => {
    handleSetMaterialsRowImages();
  }, [jobImages, materialsRowImgIds]);

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  // if (getUser() && getUser().role !== "ADMIN") {
  //   return <Navigate to="/" replace={true} />;
  // }

  return (
    <div className="jobimages">
      {getUser().role == "ADMIN" && <AdminMenu />}
      {!selectedImage && (
        <div className="jobimages-controls">
          <button
            className="jobimages-controls-filters"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            Filters
          </button>
          <button
            className="jobimages-controls-select"
            onClick={() => {
              setShowMultiple(!showMultiple);
            }}
          >
            Select Multiple
          </button>
          {viewType?.view === "View Images" && (
            <button
              className="jobimages-controls-addsave"
              onClick={async () => {
                if (showAllImgs) {
                  // console.log("IMAGES TO LINK: ", imagesToLink);
                  await updateMaterialDescRowImages(materialsRowImgIds);
                  alert("Images successfully updated");
                  setShowAllImgs(false);
                } else if (!showAllImgs) {
                  setShowAllImgs(true);
                }
              }}
            >
              {showAllImgs ? "Save" : "Add More Images"}
            </button>
          )}
          {viewType?.view === "View Images" && showAllImgs && (
            <button
              onClick={() => {
                setShowAllImgs(false);
              }}
            >
              Cancel
            </button>
          )}
          {imagesToLink.length > 0 && (
            <button
              onClick={async () => {
                // localStorage.setItem("materialImages", JSON.stringify({ idx: viewType?.idx, imagesIds: imagesToLink }));
                const updatedData = await updateMaterialDescRowImages(imagesToLink);
                if (updatedData?.materialsDesc[viewType?.idx!].images?.length > 0) {
                  alert("Images successfully added to Materials Item");
                  localStorage.removeItem("materialImageAction");
                  navigate("/wr");
                }
              }}
            >
              Add to Materials
            </button>
          )}
        </div>
      )}

      {showFilters && renderFilters()}
      {viewType?.view === "View Images" ? (!selectedImage ? jobImages.length > 0 && renderMaterialRowImagesList() : renderSelectedImage()) : !selectedImage ? jobImages.length > 0 && renderImagesList() : renderSelectedImage()}
      {}
    </div>
  );
};

export default JobImages;
