import React, { useCallback, useRef, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "../../api/auth";
import Webcam from "react-webcam";
import { getEmployees } from "../../graphql/queries";
import { uploadImageId } from "../../api/images";

import "./EmployeeImageId.scss";
interface IEmployee {
  id: string;
  name: string;
  class: string;
}
const EmployeeImageId: React.FC = () => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const camRef: any = useRef(null);

  const fetchEmployees = async () => {
    const e = await getEmployees();
    if (!e.error) {
      setEmployees(e.data.workers);
    }
  };

  const handleSelectedEmployee = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const id = e.target.value;
    console.log("ID: ", e.target.value);
    const { class: employeeClass, firstName, lastName } = employees.find((e: any) => e._id === id);
    const name = `${firstName} ${lastName}`;
    setSelectedEmployee({
      id,
      name,
      class: employeeClass,
    });
  };

  const renderEmployees = () => {
    return (
      <div className="eid-employeeinfo-dropdown">
        <label className="eid-employeeinfo-dropdown-label" htmlFor="employeeDropdown">
          Employee
        </label>
        <select className="eid-employeeinfo-dropdown-selector" name="employeeDropdown" id="employeeDropdown" onChange={e => handleSelectedEmployee(e)}>
          <option className="eid-employeeinfo-dropdown-selector-options" value="default">
            Select Employee
          </option>
          {employees &&
            employees.map((e: any, idx: number) => (
              <option key={idx} value={e._id}>
                {e.firstName} {e.lastName}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const handleUploadId = async () => {
    try {
      const uploadRes = await uploadImageId({
        img: image!,
        employeeId: selectedEmployee?.id!,
        employeeName: selectedEmployee?.name!,
      });
      console.log("IMAGE ID UPLOAD RES: ", uploadRes);
      setImage(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const capture = useCallback(() => {
    const imageSrc = camRef.current?.getScreenshot();
    setImage(imageSrc);
  }, [camRef]);

  const retake = () => {
    setImage(null);
  };

  return (
    <div className="eid">
      <section className="eid-employeeinfo">
        {renderEmployees()}
        <div className="eid-employeeinfo-details">
          <div className="eid-employeeinfo-details-name">
            <label className="eid-employeeinfo-details-name-label">Name: </label>
            <p className="eid-employeeinfo-details-name-text">{selectedEmployee?.name}</p>
          </div>
          <div className="eid-employeeinfo-details-class">
            <label className="eid-employeeinfo-details-class-label">Class: </label>
            <p className="eid-employeeinfo-details-class-text">{selectedEmployee?.class}</p>
          </div>
        </div>
      </section>
      <form className="eid-image">
        <div className="eid-image-group">
          {image ? (
            <img className="eid-image-group-imgviewer" src={image!} alt="Job Camera Image" />
          ) : (
            <Webcam
              className="eid-image-group-camera"
              // videoConstraints
              height={450}
              width={450}
              ref={camRef}
            />
          )}

          {!image ? (
            <button
              className="eid-image-group-capturebtn"
              type="button"
              onClick={e => {
                e.preventDefault();
                capture();
              }}
            >
              Capture
            </button>
          ) : (
            <div className="eid-image-group-retakegrp">
              <button
                className="eid-image-group-retakegrp-retakebtn"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  retake();
                }}
              >
                Retake
              </button>
              <button
                className="eid-image-group-retakegrp-savebtn"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  handleUploadId();
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default EmployeeImageId;
