import axios from "axios";
import { IPdfs } from "../types";

const devUrl = "http://localhost:9000/pdfs";
const prodUrl = "https://server.workforce-daily-report.com/pdfs";

const URL = process.env.NODE_ENV === "development" ? devUrl : prodUrl;
const emailSource = "no-reply@workforce-daily-report.com";
const instance = axios.create({
  baseURL: URL,
});

export const uploadAttachmentPdf = async (data: { name?: string; job?: string; jobName?: string; shift?: number; date: Date; txt?: string; links?: string[]; createdBy?: string; file: File }) => {
  const blob = new Blob([JSON.stringify(data.file, null, 2)]);
  const formData = new FormData();
  data.name && formData.append("name", data.name);
  data.jobName && formData.append("jobName", data.jobName);
  data.job && formData.append("job", data.job);
  data.shift && formData.append("shift", data.shift.toString());
  data.date && formData.append("date", new Date(data.date).toDateString());
  data.txt && formData.append("txt", data.txt);
  data.createdBy && formData.append("createdBy", data.createdBy);
  // formData.append("file", blob);
  formData.append("file", data.file);

  const uploadRes = await instance.post("/createpdf", formData, { headers: { "Content-Type": "multipart/form-data", Accept: "application/json" } });

  return uploadRes;
};

export const getAllPdfs = async () => {
  const pdfData = await instance.get();
  return pdfData;
};

export const removePdfs = async (id: string) => {
  const res = await instance.delete(`/${id}`);
  return res;
};
