import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import EmailReports from "./EmailReports";
import { emailReport } from "../../api/pdf";

import { WorkReports } from "../admin/workreports/WorkReports";
import EditWorkReport2 from "../workreport/EditWorkReport2";

import { getUser } from "../../api/auth";
import { getWorkreportList } from "../../graphql/queries";

import "./Reports.scss";
import WorkReportsField from "../admin/workreports/WorkReportsField";

const Reports: React.FC = () => {
  const [workreportList, setWorkreportList] = useState<any | null>(null);
  const [workReport, setWorkReport] = useState<any | null>(null);
  const [selectedWR, setSelectedWR] = useState<any | null>(null);
  const [showReportsList, setShowReportsList] = useState<boolean>(true);
  const [showReport, setShowReport] = useState<boolean>(true);
  const [showEditReport, setShowEditReport] = useState<boolean>(false);
  const [showEmailControls, setShowEmailControls] = useState<boolean>(false);
  const [emailRecipient, setEmailRecipient] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");

  const fetchWorkreportList = async () => {
    let wrData = await getWorkreportList();
    console.log("WR DATA: ", wrData);
    if (wrData) {
      let editList = wrData?.workReportList?.filter((wr: any) => wr.canRecall === true || !wr.canRecall);
      setWorkreportList(editList);
    }
  };

  useEffect(() => {
    fetchWorkreportList();
  }, []);

  const devUrl = "http://localhost:3000";
  const prodUrl = "http://workforce-daily-report.com";

  const reportUrl = process.env.NODE_ENV === "development" ? devUrl : prodUrl;

  const exportPDF = () => {
    let jsPdf = new jsPDF("p", "pt", "a4");
    var htmlElement = document.getElementById("dailyworkreport");
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt: any = {
      callback: async function (jsPdf: any) {
        // jsPdf.save("Test.pdf");
        // to open the generated PDF in browser window
        // window.open(jsPdf.output("bloburl"));
        jsPdf.setProperties({ title: `${selectedWR.jobName} shift_${selectedWR.shift} Work_Report.pdf` });
        if (showEmailControls) {
          const outputPdf = await jsPdf.output("blob");

          await emailReport(emailRecipient, emailBody, outputPdf, `${reportUrl}/checklistreport-download?checklistid=${selectedWR?.id}`);
          setShowEmailControls(false);
          alert("The checklist has been successfully saved.");
          setEmailRecipient("");
          setEmailBody("");
        } else if (!showEmailControls) {
          // jsPdf.save(`${selectedWR.jobName} shift_${selectedWR.shift} Work_Report.pdf`);
          window.open(jsPdf.output("bloburl"));
        }
      },
      // margin: [72, 72, 72, 72],
      // autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.8,
      },
    };

    jsPdf.html(htmlElement!, opt);
  };
  console.log("SELECTED WR: ", selectedWR);
  const renderWRList = () => {
    return (
      <div className="reports-container-reports">
        <h4 className="reports-container-reports-title">Reports</h4>
        <ul className="reports-container-reports-list">
          {workreportList?.map((wr: any) => {
            return (
              <li
                className="reports-container-reports-list-item"
                key={wr.id}
                onClick={() => {
                  setSelectedWR(wr);
                }}
              >
                <div className="reports-container-reports-list-item-container">
                  <p className="reports-container-reports-list-item-container-title">{wr.jobName}</p>
                  <p className="reports-container-reports-list-item-container-date">Submitted on {new Date(wr.taskCompletionDate).toDateString()}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  return (
    <div className="wr">
      <div className="reports-btngrp">
        <button
          className="reports-btngrp-recallbtn"
          onClick={() => {
            if (selectedWR) {
              setShowEditReport(!showEditReport);
              setShowReport(!showReport);
            } else {
              alert("Select a report from the list");
            }
          }}
        >
          {!showEditReport ? "Recall" : "Done"}
        </button>
        {showEditReport && (
          <button
            className="reports-btngrp-recallcancellbtn"
            onClick={() => {
              if (selectedWR) {
                setShowEditReport(false);
                setShowReport(true);
              } else {
                alert("Select a report from the list");
              }
            }}
          >
            Cancel
          </button>
        )}
      </div>

      <div className="reports-container">
        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showReportsList ? faChevronUp : faChevronDown} onClick={() => setShowReportsList(!showReportsList)} />
          <h4 className="workreport-main-section-divider-title">Work Reports List</h4>
        </div>
        {showReportsList && renderWRList()}
        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showReport ? faChevronUp : faChevronDown} onClick={() => setShowReport(!showReport)} />
          <h4 className="workreport-main-section-divider-title">Work Report</h4>
        </div>
        {showEmailControls && (
          <EmailReports
            sendEmail={() => {
              // saveToPdf();
              exportPDF();
              // convertRecipientString();
            }}
            handleRecipientInput={e => {
              if (e.target.name === "recipient") {
                setEmailRecipient(e.target.value);
              } else if (e.target.name === "body") {
                setEmailBody(e.target.value);
              }
            }}
            emailRecipient={emailRecipient}
            emailBody={emailBody}
          />
        )}
        <div>
          <button
            className="cl-btngrp-email"
            role="link"
            onClick={async () => {
              if (selectedWR) {
                exportPDF();
                // await saveChecklistPdf();
                // openReportInNewTab(`${devUrl}/checklistreports-pdf`);
                // setShowEmailControls(!showEmailControls);
              } else alert("Please select a Checklist to email");
            }}
          >
            Save PDF
          </button>

          <button
            className="cl-btngrp-email"
            role="link"
            onClick={async () => {
              if (selectedWR) {
                // await saveChecklistPdf();
                // openReportInNewTab(`${devUrl}/checklistreports-pdf`);
                setShowEmailControls(!showEmailControls);
              } else alert("Please select a Work Report to email");
            }}
          >
            Email PDF
          </button>
        </div>
        {showReport && !showEditReport && <WorkReportsField report={selectedWR} />}
        {showEditReport && !showReport && <EditWorkReport2 report={selectedWR} />}
      </div>
    </div>
  );
};

export default Reports;
