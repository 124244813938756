import { gql } from "@apollo/client";
import { client } from "./apollo.config";

export const getForemanData = async (id: string) => {
  const query = gql`
    query WorkerQuery($id: String!) {
      worker(id: $id) {
        _id
        firstName
        lastName
        middleInitial
        last4SSN
        class
        jobs {
          job {
            id
            jobName
            contractNo
            contractStartDate
            docPrefix
            workzones
            markups {
              markupType
              percentage
            }
            shouldVerifyId
            agency {
              id
              agencyName
            }
            location
            laborTicketAbv
            contractNo
            startDate
            taskCompletionDate
            siteEmployees {
              id
              foreman {
                _id
                firstName
                lastName
              }
              employees {
                _id
                firstName
                lastName
                middleInitial
                last4SSN
                class
              }
            }
          }
        }
      }
    }
  `;
  const variables = { id };
  const {
    data: { worker },
  } = await client.query({ query, variables });
  return worker;
};

export const getWorksiteData = async (id: string) => {
  const query = gql`
    query WorksiteEmployees($id: String!) {
      worksiteEmployees(formanId: $id) {
        id
        employees {
          _id
          firstName
          lastName
          middleInitial
          class
          last4SSN
        }
        job {
          id
          jobName
          contractNo
          contractStartDate
          location
          laborTicketAbv
          startDate
          taskCompletionDate
          docPrefix
          markups {
            markupType
            percentage
          }
          workzones
          shouldVerifyId
          agency {
            id
            agencyName
          }
        }
        foreman {
          firstName
          lastName
          middleInitial
        }
      }
    }
  `;
  const variables = { id };
  const {
    data: { worksiteEmployees },
  } = await client.query({ query, variables });
  return worksiteEmployees;
};

export const createTimesheet = gql`
  mutation createTimesheet($input: TimesheetInput) {
    createTimesheet(input: $input) {
      id
    }
  }
`;

export const getTimesheets = async () => {
  const query = gql`
    query getTimesheets {
      timesheets {
        id
        jobId
        jobName
        reportDate
        reportHours {
          id
          name
          laborCode
          costCode
          regHours
          otHours
          regRate
          otRate
          regWages
          otWages
          workerClass
          percentage
          wagesOAndPOT
          wagesOAndPRT
          wagesTotalRT
          wagesTotalOT
          total
          date
        }
      }
    }
  `;
  const ts = await client.query({ query });
  return ts;
};

export const getJobTimesheet = async (id: string) => {
  const query = gql`
    query getJobtimesheets($id: ID!) {
      jobTimesheets(id: $id) {
        id
        jobId
        jobName
        reportDate
        reportHours {
          id
          name
          laborCode
          costCode
          regHours
          otHours
          regRate
          otRate
          regWages
          otWages
          workerClass
          percentage
          wagesOAndPOT
          wagesOAndPRT
          wagesTotalRT
          wagesTotalOT
          date
        }
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const createSigninSignoutWr = gql`
  mutation CreateSI($input: CreateSIInput!) {
    createSI(input: $input) {
      id
    }
  }
`;

export const updateSigninSignoutWr = gql`
  mutation UpdateSI($input: UpdateSIInput!) {
    updateSI(input: $input) {
      id
      agency {
        id
        agencyName
      }
      canRecall
      hasBeenRecalled
      contractNo
      titleOfChangeOrder
      shift
      contractStartDate
      startDate
      taskCompletionDate
      jobName
      location
      workLocation {
        name
      }
      workType
      createdAt
      updatedAt
      reportNo
      siteEmployees {
        firstName
        lastName
        middleInitial
        last4SSN
        class
        timeIn
        timeOut
        timeInSignature
        timeOutSignature
        regularTime
        doubleTime
        notes
        costcode
      }
      foreman
      foremanSignature
      projectMgr
      projectMgrSignature
      agencyRep
      agencyRepSignature
      contractorRep
      contractorRepSignature
      temperature
      forcast
      workDescription
      incidentReport
      incidentReportText
      remarks
      tasks {
        title
        status
        progress
        details
      }
      materialsDesc {
        qty
        um
        unitCost
        totalCost
        item
        description
      }
    }
  }
`;

export const createInspectionChecklist = gql`
  mutation createFieldTasks($input: CreateChecklistInput!) {
    createChecklist(input: $input) {
      id
      agency {
        agencyName
      }
    }
  }
`;

export const getJobs = async () => {
  const query = gql`
    query JobsQuery {
      jobs {
        id
        jobName
        contractStartDate
        docPrefix
        markups {
          markupType
          percentage
        }
        workzones
        shouldVerifyId
        agency {
          id
          agencyName
        }
        laborTicketAbv
        siteEmployees {
          id
          employees {
            firstName
            lastName
            middleInitial
            last4SSN
            class
          }
        }
        archive
      }
    }
  `;
  const jobs = await client.query({ query });
  return jobs;
};

export const getJobDetails = async (id: string) => {
  const query = gql`
    query JobQuery($id: String!) {
      job(id: $id) {
        id
        jobName
        agency {
          id
          agencyName
        }
        markups {
          markupType
          percentage
        }
        workzones
        shouldVerifyId
        contractStartDate
        location
        contractNo
        laborTicketAbv
        docPrefix
        shouldVerifyId
        siteEmployees {
          id
        }
        archive
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const updateJob = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(body: $input) {
      id
      jobName
      markups {
        markupType
        percentage
      }
      workzones
      agency {
        id
        agencyName
      }
      location
      contractNo
      laborTicketAbv
      docPrefix
      shouldVerifyId
      siteEmployees {
        id
      }
      archive
    }
  }
`;

export const removeJob = gql`
  mutation removeJob($id: String!) {
    deleteJob(id: $id) {
      jobName
    }
  }
`;

export const createWREmailTemplate = gql`
  mutation createWorkReportEmailTemplate($input: WorkreportEmailTemplateInput!) {
    createWorkReportEmailTemplate(input: $input)
  }
`;

export const createJob = gql`
  mutation CreateJob($body: CreateJobInput!) {
    createJob(body: $body) {
      id
    }
  }
`;

export const getAgencies = async () => {
  const query = gql`
    query Agencies {
      agencies {
        id
        agencyName
      }
    }
  `;
  const agencies = await client.query({ query });
  return agencies;
};

export const createAgency = gql`
  mutation CreateAgencyMutation($input: CreateAgencyInput!) {
    createAgency(agencyName: $input) {
      id
      agencyName
    }
  }
`;

export const editAgency = gql`
  mutation UpdateAgencyMutation($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      agencyName
    }
  }
`;

export const removeAgency = gql`
  mutation removeAgency($id: String!) {
    deleteAgency(id: $id) {
      agencyName
    }
  }
`;

export const getEmployees = async () => {
  const query = gql`
    query WorkersQuery {
      workers {
        _id
        lastName
        firstName
        middleInitial
        last4SSN
        class
        role
        email
        stash
        jobs {
          job {
            id
            jobName
            agency {
              agencyName
            }
            siteEmployees {
              foreman {
                _id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  `;
  const employees = await client.query({ query });
  return employees;
};

export const createEmployee = gql`
  mutation CreateWorker($body: CreateWorkerInput!) {
    createWorker(body: $body) {
      _id
      firstName
      lastName
      middleInitial
      last4SSN
      class
    }
  }
`;

export const updateEmployee = gql`
  mutation UpdateWorker($body: UpdateWorkerInput!) {
    updateWorker(body: $body) {
      firstName
      lastName
      middleInitial
      last4SSN
      class
      stash
    }
  }
`;

export const removeEmployee = gql`
  mutation removeWorker($id: String!) {
    removeWorker(id: $id) {
      last4SSN
    }
  }
`;

export const getEmployeeDetails = async (id: string) => {
  const query = gql`
    query WorkerQuery($id: String!) {
      worker(id: $id) {
        _id
        firstName
        lastName
        middleInitial
        last4SSN
        class
        role
        email
        stash
        jobs {
          job {
            id
            jobName
            contractNo
            agency {
              id
              agencyName
            }
            location
            laborTicketAbv
            contractNo
            startDate
            taskCompletionDate
            shouldVerifyId
            siteEmployees {
              id
              foreman {
                _id
                firstName
                lastName
              }
              employees {
                _id
                firstName
                lastName
                middleInitial
                last4SSN
                class
                role
              }
            }
          }
        }
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const getWorksites = async () => {
  const query = gql`
    query WorksiteEmployeesList {
      worksiteEmployeesList {
        id
        employees {
          _id
          firstName
          lastName
          last4SSN
          class
        }
        job {
          id
          jobName
          location
          startDate
          taskCompletionDate
          laborTicketAbv
          contractNo
          agency {
            id
            agencyName
          }
        }
        foreman {
          firstName
          lastName
          class
        }
        archive
      }
    }
  `;
  const worksites = await client.query({ query });
  return worksites.data;
};

export const createWorksite = gql`
  mutation CreateWorksiteEmployees($body: CreateWorksiteEmployeesInput!) {
    createWorksiteEmployees(body: $body) {
      id
      employees {
        _id
        firstName
        lastName
        last4SSN
        class
      }
      job {
        id
        jobName
        location
        startDate
        taskCompletionDate
        laborTicketAbv
        contractNo
        agency {
          id
          agencyName
        }
      }
      foreman {
        firstName
        lastName
        class
      }
      archive
    }
  }
`;

export const deleteWorksite = gql`
  mutation removeWorksite($id: String!) {
    deleteWorksiteEmployees(id: $id) {
      jobId
    }
  }
`;

export const updateWorksite = gql`
  mutation UpdateWorksiteEmployees($body: UpdateWorksiteEmployeesInput!) {
    updateWorksiteEmployees(body: $body) {
      id
      employees {
        _id
        firstName
        lastName
        last4SSN
        class
      }
      job {
        id
        jobName
        location
        startDate
        taskCompletionDate
        laborTicketAbv
        contractNo
        agency {
          id
          agencyName
        }
      }
      foreman {
        firstName
        lastName
        class
      }
      archive
    }
  }
`;

export const getWorkreportList = async () => {
  const query = gql`
    query workReportList {
      workReportList {
        id
        agency {
          id
          agencyName
        }
        canRecall
        hasBeenRecalled
        contractNo
        titleOfChangeOrder
        shift
        contractStartDate
        startDate
        taskCompletionDate
        jobName
        location
        workLocation {
          name
        }
        workType
        createdAt
        updatedAt
        reportNo
        siteEmployees {
          firstName
          lastName
          middleInitial
          last4SSN
          class
          timeIn
          timeOut
          timeInSignature
          timeOutSignature
          regularTime
          doubleTime
          notes
          costcode
        }
        foreman
        foremanSignature
        projectMgr
        projectMgrSignature
        agencyRep
        agencyRepSignature
        contractorRep
        contractorRepSignature
        temperature
        forcast
        workDescription
        incidentReport
        incidentReportText
        remarks
        tasks {
          title
          status
          progress
          details
        }
        materialsDesc {
          qty
          um
          unitCost
          totalCost
          item
          description
        }
      }
    }
  `;
  const worksites = await client.query({ query });
  return worksites.data;
};

export const getWorkreport = async (id: string) => {
  const query = gql`
    query ($id: ID!) {
      workreport(id: $id) {
        id
        agency {
          id
          agencyName
        }
        canRecall
        hasBeenRecalled
        contractNo
        titleOfChangeOrder
        shift
        contractStartDate
        startDate
        taskCompletionDate
        jobName
        location
        workLocation {
          name
        }
        workType
        createdAt
        updatedAt
        reportNo
        siteEmployees {
          firstName
          lastName
          middleInitial
          last4SSN
          class
          timeIn
          timeOut
          timeInSignature
          timeOutSignature
          regularTime
          doubleTime
          notes
          costcode
        }
        foreman
        foremanSignature
        projectMgr
        projectMgrSignature
        agencyRep
        agencyRepSignature
        contractorRep
        contractorRepSignature
        temperature
        incidentReport
        forcast
        workDescription
        incidentReport
        incidentReportText
        remarks
        tasks {
          title
          status
          progress
          details
        }
        workDescription
        materialsDesc {
          qty
          um
          unitCost
          totalCost
          item
          description
        }
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const getChecklistReport = async (id: any) => {
  const query = gql`
    query getChecklistReport($id: ID!) {
      checklist(id: $id) {
        id
        checklistType
        contractNo
        specificationsNo
        description
        sub_contractor
        contractor
        location
        date
        datePrepared
        preparedBy
        inspectorName
        surveillanceReport
        drawing
        agency {
          # id
          agencyName
        }
        fieldTasks {
          id
          question
          answer
        }
        nonconformanceRemarks
        remarks
        additionalRemarks {
          name1
          title1
          date1
          signature1
          name2
          title2
          date2
          signature2
          name3
          title3
          date3
          signature3
        }
        createdAt
        updatedAt
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const getChecklistReportsList = async () => {
  const query = gql`
    query checklists {
      checklists {
        id
        checklistType
        contractNo
        specificationsNo
        description
        sub_contractor
        contractor
        location
        date
        datePrepared
        preparedBy
        inspectorName
        surveillanceReport
        drawing
        agency {
          # id
          agencyName
        }
        fieldTasks {
          id
          question
          answer
        }
        nonconformanceRemarks
        remarks
        additionalRemarks {
          name1
          title1
          date1
          signature1
          name2
          title2
          date2
          signature2
          name3
          title3
          date3
          signature3
        }
        createdAt
        updatedAt
      }
    }
  `;
  const checklists = await client.query({ query });
  return checklists.data;
};

export const createChecklistCreator = gql`
  mutation createChecklistCreator($input: ChecklistCreatorInput!) {
    createChecklistCreator(input: $input) {
      type
    }
  }
`;

export const getChecklistCreatorList = async () => {
  const query = gql`
    query getChecklistCreators {
      checklistCreators {
        id
        type
        fieldTasks {
          id
          question
          answer
        }
      }
    }
  `;
  const checklistCreators = await client.query({ query });
  return checklistCreators.data;
};

export const getCostCodes = async () => {
  const query = gql`
    query getCostCodes {
      costCodes {
        id
        budget
        laborCode
        costCode
        description
      }
    }
  `;
  const costCodes = await client.query({ query });
  return costCodes.data;
};

export const createCostCodes = gql`
  mutation createCostCodes($input: CreateCostCodeInput!) {
    createCostCodes(input: $input) {
      id
      budget
      costCode
      laborCode
      description
    }
  }
`;

export const updateCostCodes = gql`
  mutation updateCostCode($input: UpdateCostCodeInput!) {
    updateCostCode(input: $input) {
      id
      budget
      laborCode
      costCode
      description
    }
  }
`;

export const removeCostCode = gql`
  mutation deleteCostCodes($id: [String!]!) {
    deleteCostCodes(id: $id) {
      id
    }
  }
`;

export const getChecklistCreator = async (id: string) => {
  const query = gql`
    query getChecklistCreator($id: ID!) {
      checklistCreator(id: $id) {
        id
        type
        fieldTasks {
          id
          question
          answer
        }
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
};

export const updateSubmittedChecklist = gql`
  mutation updateChecklist($input: CreateChecklistInput!) {
    updateChecklist(input: $input) {
      id
    }
  }
`;

export const getEmployeeJobRates = async () => {
  const query = gql`
    query getEmployeeJobRates {
      employeeRates {
        id
        employeeId
        employee
        stPay
        dtPay
      }
    }
  `;
  const rates = await client.query({ query });
  return rates.data;
};

export const createEmployeeJobRates = gql`
  mutation createJobRates($input: CreateEmployeeRatesInput!) {
    createEmployeeRates(input: $input) {
      id
      employeeId
      employee
      stPay
      dtPay
    }
  }
`;

export const updateEmployeeJobRates = gql`
  mutation updateJobRates($input: UpdateEmployeeRatesInput!) {
    updateEmployeeRates(input: $input) {
      id
      employeeId
      employee
      stPay
      dtPay
    }
  }
`;

export const getMaterialsList = async () => {
  const query = gql`
    query GetMaterialsList {
      materialsList {
        id
        jobId
        jobName
        shift
        date
        materials {
          item
          qty
          um
          description
          unitCost
          totalCost
          images
          workzone
        }
      }
    }
  `;
  const materials = await client.query({ query });
  return materials.data;
};

export const getMaterials = async (id: string) => {
  const query = gql`
    query GetMaterials($id: String!) {
      getMaterials(id: $id) {
        id
        jobId
        jobName
        shift
        date
        materials {
          item
          qty
          um
          description
          unitCost
          totalCost
          images
          workzone
        }
      }
    }
  `;
  const variables = { id };
  const data = await client.query({ query, variables });
  return data;
};

export const createMaterialsBudget = gql`
  mutation CreateMaterials($createMaterialsBody: MaterialsInput!) {
    createMaterials(body: $createMaterialsBody) {
      id
      jobId
      jobName
      shift
      date
      images
      materials {
        item
        qty
        um
        description
        unitCost
        totalCost
      }
    }
  }
`;

export const updateMaterialsBudget = gql`
  mutation UpdateMaterials($id: String!, $body: MaterialsInput!) {
    updateMaterials(id: $id, body: $body) {
      id
      jobId
      jobName
      shift
      date
      images
      materials {
        item
        qty
        um
        description
        unitCost
        totalCost
      }
    }
  }
`;
