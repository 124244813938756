import React, { useCallback, useRef, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "../../api/auth";
import Webcam from "react-webcam";
import { getJobs } from "../../graphql/queries";
import { uploadJobImages, getJobImages, getJobImage, uploadJobImage, jobImageList } from "../../api/images";

import "./Camera.scss";

interface IJobImg {
  img: string;
  txt: string;
  job: string;
  shift: number;
  date: string;
  foreman: string;
}

type TJob = { jobName: string };

const shifts = [1, 2, 3];

const videoConstraints = {
  facingMode: { exact: "environment" },
};

type TImage = string;

const dateStamp = new Date();

const Camera: React.FC = () => {
  const [jobs, setJobs] = useState<TJob[]>([]);
  const [image, setImage] = useState<TImage | null>(null);
  const [dbImages, setDbImages] = useState<any[] | null>(null);
  const [selectedJob, setSelectedJob] = useState<TJob>({
    jobName: "",
  });
  const [jobImg, setJobImg] = useState<IJobImg>({
    img: "",
    txt: "",
    job: "",
    shift: 0,
    date: "",
    foreman: "",
  });
  const [images, setImages] = useState<IJobImg[]>([]);
  const camRef: any = useRef(null);

  const fetchImageList = async () => {
    const jobImages = await getJobImages();
    setDbImages(jobImages);
  };
  const fetchJobs = async () => {
    const jobs = await getJobs();
    setJobs(jobs?.data?.jobs.map((j: any) => ({ id: j.id, jobName: j.jobName })));
  };

  const setForeman = () => {
    const data = JSON.parse(localStorage.getItem("siteForeman")!);
    const foreman = `${data.firstName} ${data.lastName}`;
    setJobImg({
      ...jobImg,
      foreman,
    });
  };

  useEffect(() => {
    fetchImageList();
    fetchJobs();
    setForeman();
  }, []);

  const imageConstraints = {
    facingMode: "environment",
  };
  const capture = useCallback(() => {
    const imageSrc = camRef.current?.getScreenshot();
    setImage(imageSrc);
  }, [camRef]);

  const retake = () => {
    setImage(null);
    setJobImg({
      ...jobImg,
      img: "",
    });
  };

  const imageText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJobImg({ ...jobImg, txt: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (jobImg.img === "") {
        alert("Save image before submitting");
        return;
      }
      const res = await uploadJobImage(jobImg);
      setImages([res.data, ...images]);
      setImage(null);
      setJobImg({
        ...jobImg,
        img: "",
        txt: "",
      });
    } catch (err) {
      console.log("UPLOAD JOB IMAGE ERROR: ", err);
    }
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role === "ADMIN") {
    return <Navigate to="/jobs" replace={true} />;
  }
  // console.log("NEW DATE ISO: ", new Date().toISOString().split("T")[0]);
  return (
    <div className="camera">
      {images?.length > 0 && (
        <div className="camera-thumbnailcontainer">
          {images?.length > 0 &&
            images.map((i: any, idx: number) => {
              return <img className="camera-thumbnailcontainer-img" src={i.details.imgString} alt="Job Image" key={idx} />;
            })}
        </div>
      )}

      <form className="camera-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="camera-form-jobscontainer">
          <div className="camera-form-jobscontainer-jobselector">
            <label className="camera-form-jobscontainer-jobselector-label" htmlFor="job-select">
              Select Job
            </label>
            <select
              className="camera-form-jobscontainer-jobselector-selector"
              name="jobSelect"
              id="job-select"
              value={jobImg.job}
              onChange={e => {
                setJobImg({ ...jobImg, job: e.target.value });
              }}
            >
              <option className="camera-form-jobscontainer-jobselector-selector-option" key="default" value="default">
                Select a job
              </option>
              {jobs.map((job: { jobName: string }, idx: number) => (
                <option className="camera-form-jobscontainer-jobselector-selector-option" key={idx} value={job.jobName}>
                  {job.jobName}
                </option>
              ))}
            </select>
          </div>
          <div className="camera-form-jobscontainer-shiftselector">
            <label className="camera-form-jobscontainer-shiftselector-label" htmlFor="job-select">
              Select Shift
            </label>
            <select
              className="camera-form-jobscontainer-shiftselector-selector"
              name="jobSelect"
              id="job-select"
              value={jobImg.shift}
              onChange={e => {
                setJobImg({ ...jobImg, shift: parseInt(e.target.value) });
              }}
            >
              <option className="camera-form-jobscontainer-shiftselector-selector-option" key="default" value="default">
                Select a shift
              </option>
              {shifts.map((shift, idx: number) => (
                <option key={idx} value={shift}>
                  {shift}
                </option>
              ))}
            </select>
          </div>

          <div className="camera-form-jobscontainer-shiftselector">
            <label className="camera-form-jobscontainer-shiftselector-label" htmlFor="job-select">
              Select Date (optional)
            </label>
            <input
              type="date"
              value={jobImg.date !== "" ? new Date(jobImg?.date).toISOString().split("T")[0] : new Date().toDateString()}
              onChange={e => {
                setJobImg({
                  ...jobImg,
                  date: new Date(e.target.value).toDateString(),
                });
              }}
            />
          </div>
        </div>

        <div className="camera-form-camcontainer">{image ? <img className="camera-form-camcontainer-imgviewer" src={image!} alt="Job Camera Image" /> : <Webcam className="camera-form-camcontainer-camera" videoConstraints={imageConstraints} height={450} width={450} ref={camRef} />}</div>

        {image && (
          <div className="camera-form-textcontainer">
            <label className="camera-form-textcontainer-textlabel" htmlFor="image-text-area">
              Image Text
            </label>
            <textarea
              className="camera-form-textcontainer-textarea"
              name="imageTextArea"
              id="image-text-area"
              placeholder="Enter Text for the Work Image"
              onChange={e => {
                imageText(e);
              }}
            />
          </div>
        )}

        <div className="camera-form-btncontainer">
          {image ? (
            <button
              className="camera-form-btncontainer-capturebtn captured"
              onClick={e => {
                e.preventDefault();
                if (jobImg.txt === "") {
                  alert("Add image text before saving");
                  return;
                } else if (jobImg.job === "") {
                  alert("Select a job before saving");
                  return;
                } else if (jobImg.shift === 0) {
                  alert("Select a shift before saving");
                  return;
                } else if (jobImg.date === "") {
                  alert("Select a date before saving");
                  return;
                } else {
                  setJobImg({
                    ...jobImg,
                    img: image!,
                  });
                }
              }}
            >
              Save Image
            </button>
          ) : (
            <button
              className="camera-form-btncontainer-capturebtn captured"
              onClick={e => {
                e.preventDefault();
                capture();
              }}
            >
              Capture Image
            </button>
          )}

          <button
            className="camera-form-btncontainer-retakebtn"
            onClick={e => {
              e.preventDefault();
              retake();
            }}
          >
            Retake Photo
          </button>
        </div>
        <button className="camera-form-submitbtn" type="submit">
          Submit Image
        </button>
        {image && (
          <button
            className="camera-form-submitbtn"
            onClick={e => {
              e.preventDefault();
              setImage(null);
              setJobImg({
                img: "",
                txt: "",
                job: "",
                shift: 0,
                date: "",
                foreman: "",
              });
            }}
          >
            Cancel
          </button>
        )}
      </form>
    </div>
  );
};

export default Camera;
