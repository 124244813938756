import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getEmployees, getEmployeeDetails, createEmployee, updateEmployee, removeEmployee } from "../../../graphql/queries";
import { getUser, userRegistration } from "../../../api/auth";

import AdminMenu from "../AdminMenu";

import "./Employees.scss";

const Employees: React.FC = () => {
  const [employees, setEmployees] = useState<any | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any | null>(null);
  const [employeeFilter, setEmployeeFilter] = useState<string>("active");
  const [createEmployeeData, setCreateEmployeeData] = useState<{ firstName: string; lastName: string; middleInitial: string; last4SSN: string; class: string; jobs: any[]; email: string; stash: boolean } | null>(null);
  const [editsToSend, setEditsToSend] = useState<any | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canAddEmployee, setCanAddEmployee] = useState<boolean>(false);
  const [canAddAuth, setCanAddAuth] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<{ username: string; password: string } | any | null>(null);
  const [shouldRegisterAuth, setShouldRegisterAuth] = useState<boolean>(false);
  const [stashWorker, setStashWorker] = useState<boolean>(false);
  const [addEmployee, { data: addEmployeeData, loading: addEmployeeLoading, error: addEmployeeError }] = useMutation(createEmployee);

  const [modifyEmployee, { data: modifyEmployeeData, loading: updateEmployeeLoading, error: updateEmployeeError }] = useMutation(updateEmployee);

  const [deleteEmployee, { data: deleteEmployeeData, loading: deleteEmployeeLoading, error: deleteEmployeeError }] = useMutation(removeEmployee);

  const fetchEmployees = async () => {
    const currentEmployees = await getEmployees();
    if (currentEmployees) {
      const employeesArr = [...currentEmployees.data.workers];
      const sortedEmployees = employeesArr.sort((a: any, b: any) => a.lastName - b.lastName);
      setEmployees(sortedEmployees);
    }
  };

  const fetchEmployeeDetails = async (id: string) => {
    // console.log("ID: ", id);
    const employee = await getEmployeeDetails(id);
    setSelectedEmployee(employee?.data?.worker);
    if (employee?.data?.worker.stash) {
      setStashWorker(employee?.data?.worker.stash);
    }
  };

  // console.log("SELECTED EMPLOYEE: ", selectedEmployee, "STASH: ", stashWorker, "EDITS TO SEND: ", editsToSend);

  useEffect(() => {
    fetchEmployees();
  }, []);
  const handleSaveEmployee = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (editsToSend === null) {
      alert("You must enter employee data before saving");
      return;
    }
    await addEmployee({ variables: { body: editsToSend } });
    setCanEdit(false);
    setCanAddEmployee(false);
    setEditsToSend(null);
    setShouldRegisterAuth(true);
    // window.location.reload();
  };

  const handleUpdateEmployee = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    // console.log("UPDATED ID: ", id);
    await modifyEmployee({ variables: { body: { ...editsToSend, _id: id } } });
    setShouldRegisterAuth(true);
    window.location.reload();
  };

  const handleRemoveEmployee = async (id: string) => {
    await deleteEmployee({ variables: { id } });
    alert(`${selectedEmployee?.firstName} ${selectedEmployee?.lastName} has been successfully removed`);
    window.location.reload();
  };

  const renderEmployeeList = () => {
    return (
      <div className="employees-wrapper-workerlist">
        <h4 className="employees-wrapper-workerlist-title">Employees</h4>
        <ul className="employees-wrapper-workerlist-list">
          {employees
            ?.filter((e: any) => e.stash === null || e.stash === false)
            ?.sort((a: any, b: any) => a.lastName - b.lastName)
            .map((employee: { _id: string; firstName: string; lastName: string }) => {
              return (
                <li
                  className={`employees-wrapper-workerlist-list-item ${employee._id === selectedEmployee?._id ? "selecteditem" : ""}`}
                  key={employee._id}
                  onClick={() => {
                    setCanEdit(false);
                    fetchEmployeeDetails(employee?._id);
                    // if (employee?._id !== selectedEmployee?._id) {
                    //   setCanEdit(false);
                    // }
                  }}
                >
                  {employee.firstName} {employee.lastName}
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const renderStashedEmployeeList = () => {
    return (
      <div className="employees-wrapper-workerlist">
        <h4 className="employees-wrapper-workerlist-title">Employees</h4>
        <ul className="employees-wrapper-workerlist-list">
          {employees
            .filter((e: any) => e.stash === true)
            ?.sort((a: any, b: any) => a.lastName - b.lastName)
            .map((employee: { _id: string; firstName: string; lastName: string }) => {
              console.log("STASHED EMPLOYEES: ", employee);
              return (
                <li
                  className={`employees-wrapper-workerlist-list-item ${employee._id === selectedEmployee?._id ? "selecteditem" : ""}`}
                  key={employee._id}
                  onClick={() => {
                    fetchEmployeeDetails(employee?._id);
                    if (employee?._id !== selectedEmployee?._id) {
                      setCanEdit(false);
                    }
                  }}
                >
                  {employee.firstName} {employee.lastName}
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const handleAuthSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const res: { ok: boolean; type: string } | any = await userRegistration(credentials?.userName, credentials?.password);

      if (res?.ok !== false) {
        alert("User has been successfully registered");
        setCredentials(null);
        setCanEdit(false);
        setCanAddEmployee(false);
        setEditsToSend(null);
        window.location.reload();
      } else if (res.type === "cors") alert("You have a cors issue");
    } catch (err) {
      console.log("LOGIN SUBMIT ERR FROM COMPONENT: ", err);
      alert("Error logging in");
    }
  };

  const renderEmployeeDetail = () => {
    return (
      <div className="employees-wrapper-workerdetails">
        <h4 className="employees-wrapper-workerdetails-title">Employee Details</h4>
        <form className="employees-wrapper-workerdetails-form" action="">
          <button
            className={`employees-wrapper-workerdetails-form-editbtn ${selectedEmployee !== null && "editbtnactive"}`}
            type="button"
            onClick={() => {
              if (selectedEmployee !== null && !canAddEmployee) {
                setCanEdit(!canEdit);
                setCreateEmployeeData(selectedEmployee);
                setCanAddAuth(!canEdit);
                setShouldRegisterAuth(!canEdit);
              }
            }}
          >
            Edit
          </button>
          <div className="employees-wrapper-workerdetails-form-row1">
            <div className="employees-wrapper-workerdetails-form-row1-firstName">
              <label htmlFor="firstName">First Name</label>
              <input
                className="employees-wrapper-workerdetails-form-row1-firstName-input"
                type="text"
                name="firstname"
                id="firstName"
                value={!canEdit ? selectedEmployee?.firstName : createEmployeeData?.firstName}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    firstName: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    firstName: e.target.value,
                  });
                }}
              />
            </div>

            <div className="employees-wrapper-workerdetails-form-row1-lastName">
              <label htmlFor="lastName">Last Name</label>
              <input
                className="employees-wrapper-workerdetails-form-row1-lastName-input"
                type="text"
                name="lastName"
                id="lastName"
                value={!canEdit ? selectedEmployee?.lastName : createEmployeeData?.lastName}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    lastName: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    lastName: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="employees-wrapper-workerdetails-form-row2">
            <div className="employees-wrapper-workerdetails-form-row2-middleInitial">
              <label htmlFor="middleInitial">Middle Initials</label>
              <input
                className="employees-wrapper-workerdetails-form-row2-middleInitial-input"
                type="text"
                name="middleInitial"
                id="middleInitial"
                value={!canEdit ? selectedEmployee?.middleInitial || "" : createEmployeeData?.middleInitial}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    middleInitial: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    middleInitial: e.target.value,
                  });
                }}
              />
            </div>

            <div className="employees-wrapper-workerdetails-form-row2-class">
              <label htmlFor="class">Class</label>
              <input
                className="employees-wrapper-workerdetails-form-row2-class-input"
                type="text"
                name="class"
                id="class"
                value={!canEdit ? selectedEmployee?.class : createEmployeeData?.class}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    class: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    class: e.target.value,
                  });
                }}
              />
            </div>

            <div className="employees-wrapper-workerdetails-form-row2-last4SSN">
              <label htmlFor="last4SSN">SSN (Last 4)</label>
              <input
                className="employees-wrapper-workerdetails-form-row2-last4SSN-input"
                type="text"
                name="last4SSN"
                id="last4SSN"
                value={!canEdit ? selectedEmployee?.last4SSN : createEmployeeData?.last4SSN}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    last4SSN: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    last4SSN: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="employees-wrapper-workerdetails-form-row2">
            <div className="employees-wrapper-workerdetails-form-row2-email">
              <label htmlFor="email">Email</label>
              <input
                className="employees-wrapper-workerdetails-form-row2-email-input"
                type="email"
                name="email"
                id="email"
                value={!canEdit ? selectedEmployee?.email || "" : createEmployeeData?.email}
                disabled={!canEdit}
                onChange={e => {
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    email: e.target.value,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="employees-wrapper-workerdetails-form-row3">
            {!canEdit && (
              <div className="employees-wrapper-workerdetails-form-row3-jobs">
                <label className="employees-wrapper-workerdetails-form-row3-jobs-label" htmlFor="lastName">
                  Current Jobs
                </label>
                {selectedEmployee?.jobs?.length > 0 ? (
                  <ul className="employees-wrapper-workerdetails-form-row3-jobs-list">
                    {selectedEmployee?.jobs
                      ?.filter((_job: any) => _job.job !== null)
                      .map((job: { job: { jobName: string; id: string } }) => {
                        return (
                          <li className="employees-wrapper-workerdetails-form-row3-jobs-list-item" key={job.job.id}>
                            {job.job.jobName}
                          </li>
                        );
                      })}
                  </ul>
                ) : (
                  <div>No Current Jobs</div>
                )}
              </div>
            )}
            <div className="employees-wrapper-workerdetails-form-row3-verifyid">
              <label className="employees-wrapper-workerdetails-form-row3-verifyid-label">Stash Worker?</label>
              <button
                name="shouldVerifyId"
                className="employees-wrapper-workerdetails-form-row3-verifyid-input"
                disabled={!canEdit}
                type="button"
                onClick={e => {
                  e.preventDefault();
                  console.log("STASH: ", selectedEmployee?.stash);
                  setStashWorker(!stashWorker);
                  setCreateEmployeeData({
                    ...createEmployeeData!,
                    stash: !stashWorker,
                  });
                  setEditsToSend({
                    ...editsToSend!,
                    stash: !stashWorker,
                  });
                  setSelectedEmployee({
                    ...selectedEmployee,
                    stash: !stashWorker,
                  });
                }}
              >
                {stashWorker ? "True" : "False"}
              </button>
            </div>
          </div>

          {canEdit && (
            <div className="employees-wrapper-workerdetails-form-btngroup">
              <button
                className="employees-wrapper-workerdetails-form-btngroup-savebtn"
                type="submit"
                onClick={e => {
                  if (!canAddEmployee) {
                    handleUpdateEmployee(e, selectedEmployee._id);
                  } else if (canAddEmployee) {
                    setEditsToSend({
                      ...editsToSend,
                      role: "FIELD",
                    });
                    handleSaveEmployee(e);
                  }
                  setCanEdit(false);
                }}
              >
                Save
              </button>

              <button
                className="employees-wrapper-workerdetails-form-btngroup-savebtn"
                type="button"
                onClick={e => {
                  setCanEdit(false);
                  setCanAddEmployee(false);
                  setCanAddAuth(false);
                  setCredentials(null);
                  setShouldRegisterAuth(false);
                }}
              >
                Cancel
              </button>
            </div>
          )}

          {canAddAuth && canEdit && (
            <div className="registeruser-authform">
              <div className="registeruser-authform-row1">
                <div className="registeruser-authform-row1-userName">
                  <label htmlFor="userName">Email</label>
                  <input
                    className="registeruser-authform-row1-userName-input"
                    type="text"
                    name="userName"
                    id="userName"
                    disabled={!shouldRegisterAuth}
                    value={credentials?.userName || ""}
                    onChange={e => {
                      setCredentials({
                        ...credentials,
                        [`${e.target.name}`]: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="registeruser-authform-row1-password">
                  <label htmlFor="password">Password</label>
                  <input
                    className="registeruser-authform-row1-password-input"
                    type="password"
                    name="password"
                    id="password"
                    disabled={!shouldRegisterAuth}
                    value={credentials?.password || ""}
                    onChange={e => {
                      setCredentials({
                        ...credentials,
                        [`${e.target.name}`]: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="registeruser-authform-btngroup">
                <button
                  className="registeruser-authform-btngroup-savebtn"
                  type="submit"
                  disabled={!shouldRegisterAuth}
                  onClick={e => {
                    handleAuthSubmit(e);
                  }}
                >
                  Save
                </button>

                <button
                  className="registeruser-fieldform-btngroup-savebtn"
                  type="button"
                  disabled={!shouldRegisterAuth}
                  onClick={() => {
                    setCredentials(null);
                    setCanAddAuth(false);
                    setCanEdit(false);
                    setShouldRegisterAuth(false);
                    setCanAddEmployee(false);
                    setEditsToSend(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }
  return (
    <div className="employees">
      <AdminMenu />
      <div className="employees-topcontainer">
        <div
          className={`employees-topcontainer-addemployee ${canEdit && "addemployeeclicked"}`}
          onClick={e => {
            setCanEdit(true);
            setCanAddEmployee(true);
            setCanAddAuth(true);
            setEditsToSend({
              ...editsToSend!,
              role: "FIELD",
            });
          }}
        >
          <p className="employees-topcontainer-addemployee-text">Add Employee</p>
          <FontAwesomeIcon className="employees-topcontainer-addemployee-button" icon={faPlus} />
        </div>

        <div className="employees-topcontainer-filter">
          <label className="employees-topcontainer-filter-label" htmlFor="filter">
            Filter Employee
          </label>
          <select
            className="employees-topcontainer-filter-selector"
            name="filter"
            id="filter"
            onChange={e => {
              setEmployeeFilter(e.target.value);
            }}
          >
            <option id="filter-option" className="employees-topcontainer-filter-selector-option" value="active">
              Active
            </option>
            <option className="employees-topcontainer-filter-selector-option" value="stashed">
              Stashed
            </option>
          </select>
        </div>

        {getUser().level === 1 && (
          <button
            className={`employees-topcontainer-removebtn ${selectedEmployee && "remove-selected"}`}
            type="button"
            onClick={() => {
              handleRemoveEmployee(selectedEmployee?._id!);
            }}
          >
            Remove Selected Employee
          </button>
        )}
      </div>

      <div className="employees-wrapper">
        <>{employeeFilter === "active" ? renderEmployeeList() : renderStashedEmployeeList()}</>
        <>{renderEmployeeDetail()}</>
      </div>
    </div>
  );
};

export default Employees;
