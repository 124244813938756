import React from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "../../api/auth";
import { Link } from "react-router-dom";
import { getFocus } from "../../api/si";

import "./HomeScreen.scss";

interface IHomeMenuLinks {
  link: string;
  abv: string;
  text1: string;
  text2?: string;
  text3?: string;
}

const homeMenuLinks: IHomeMenuLinks[] = [
  { link: "/si", abv: "SI", text1: "SIGN-IN", text2: "SIGN-OUT" },
  { link: "/wr", abv: "WR", text1: "WORK", text2: "REPORT" },
  { link: "/ic", abv: "IC", text1: "INSPECTION", text2: "CHECKLIST" },
  { link: "/eid", abv: "EID", text1: "EMPLOYEE", text2: "IMAGE", text3: "IDENTIFICATION" },
  { link: "/job-cam", abv: "JC", text1: "JOB", text2: "CAM" },
  { link: "/facial-verification", abv: "FV", text1: "FACIAL", text2: "VERIFICATION" },
  { link: "/submitted-reports", abv: "R", text1: "SUBMITTED", text2: "REPORTS" },
];

const HomeScreen: React.FC = () => {
  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  const renderScreenMenu = () => {
    return homeMenuLinks.map((i: IHomeMenuLinks, idx) => {
      return (
        <Link to={i.link} className="homescreen-menu-item">
          <span className="homescreen-menu-item-abrev">{i.abv}</span>
          <div className="homescreen-menu-item-title">
            <span className="homescreen-menu-item-title-text">{i.text1}</span>
            <span className="homescreen-menu-item-title-text">{i.text2}</span>
            {i.text3 && <span className="homescreen-menu-item-title-text">{i.text3}</span>}
          </div>
        </Link>
      );
    });
  };

  return <div className="homescreen">{renderScreenMenu()}</div>;
};

export default HomeScreen;
