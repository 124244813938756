import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import jsPDF from "jspdf";
import { emailReport } from "../../api/pdf";
import EmailReports from "../reports/EmailReports";

import SignatureCanvas from "react-signature-canvas";
import Button1 from "../../common/button1/Button1";
import Header from "../../common/header/Header";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createInitChecklistData, getChecklistData, updateChecklistData, updateChecklistAdditionalRemarks } from "../../api/checklists";
import { getFocus, getShiftData } from "../../api/si";
import { getUser } from "../../api/auth";
import { createInspectionChecklist, getForemanData, getChecklistCreatorList } from "../../graphql/queries";

import { checklistType, domesticPortableWaterDistributionChecklist, electricDomesticWaterHeatersChecklist, fireStoppingChecklist, fuelFiredDomesticWaterHeatersChecklist, generalChecklist, insulationChecklist, plumbingFixturesChecklist, potableWaterStorageTanksChecklist, sanitaryDrainageChecklist, stormDrainageChecklist, mainChecklistsArr } from "./Checklists";

import { ForemanData } from "../../types";

import "./Checklist.scss";
import { formatTheDate, formattedDate } from "../../helpers/dateTime";
import { ChecklistFieldReport } from "./ChecklistFieldReport";

const checklistTypesArr = ["General Checklist", "Insulation Checklist", "Electric Domestic Water Heater Checklist", "Potable Water Storage Tanks Checklist", "Sanitary Drainage Checklist", "Storm Drainage Checklist", "Domestic Portable Water Distribution Checklist", "Fuel Fired Domestic Water Heaters Checklist", "Plumbing Fixtures Checklist", "Fire Stopping Checklist"];

const Checklist: React.FC = () => {
  const [checklist, setChecklist] = useState<checklistType | null | undefined>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [selectedShift, setSelectedShift] = useState<string | null>(null);
  const [showTopForm, setShowTopForm] = useState<boolean>(true);
  const [showNonconformances, setShowNonconformances] = useState<boolean>(true);
  const [showAdditionalRemarks, setShowAdditionalRemarks] = useState<boolean>(true);
  const [showFieldTasks, setShowFieldTasks] = useState<boolean>(true);
  const [showSignatures, setShowSignatures] = useState<boolean>(true);
  const [selectedChecklistType, setSelectedChecklistType] = useState<string>("");
  const [loadedChecklistTypes, setLoadedChecklistTypes] = useState<any[]>(checklistTypesArr);
  const [loadedChecklists, setLoadedChecklists] = useState<any[]>(mainChecklistsArr);
  const [checklistFieldValues, setChecklistFieldValues] = useState<any | null>(null);
  const [additionalRemarks, setAdditionalRemarks] = useState<any | null>(null);
  const [fieldTasks, setFieldTasks] = useState<any[]>([]);
  const [showEmailControls, setShowEmailControls] = useState<boolean>(false);
  const [savePdfPrep, setSavePdfPrep] = useState<boolean>(false);
  const [emailRecipient, setEmailRecipient] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");

  const [createChecklist, { data, loading, error }] = useMutation(createInspectionChecklist);
  const navigate = useNavigate();
  const initChecklistLocalData = async (data: ForemanData) => {
    try {
      const checklistData = await createInitChecklistData(data);
      setShiftData(checklistData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChecklistLocalData = async () => {
    try {
      const data = await getChecklistData();
      if (Object.keys(data).length > 0) {
        setChecklistFieldValues(data);
        setSelectedChecklistType(data.checklistType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setChecklistCreators = (data: any) => {
    let checklistCreatorArr: any = [];
    let checklistCreatorsListArr: any = [];
    data.checklistCreators.forEach((d: any) => {
      checklistCreatorArr.push(d.type);
      checklistCreatorsListArr.push({
        type: d.type,
        fieldTasks: [...d.fieldTasks],
        contractNo: "",
        description: "",
        contractor: "",
        sub_contractor: "",
        locationOfWork: "",
        date: "",
        preparedBy: "",
        datePrepared: "",
        inspectorName: "",
        surveillanceReport: "",
        drawing: "",
        remarks: "",
        nonconformanceRemarks: "",

        additionalRemarks: {
          text: "",
          name1: "",
          signature1: "",
          title1: "",
          date1: "",
          name2: "",
          signature2: "",
          title2: "",
          date2: "",
          name3: "",
          signature3: "",
          title3: "",
          date3: "",
        },
      });
    });

    setLoadedChecklistTypes([...loadedChecklistTypes, ...checklistCreatorArr]);
    setLoadedChecklists([...loadedChecklists, ...checklistCreatorsListArr]);
  };

  useEffect(() => {
    fetchChecklistLocalData();
    getForemanData(getUser()?.userId)
      .then(data => {
        initChecklistLocalData(data);
      })
      .catch(error => {
        console.log({ ERROR: error.message });
      });

    getChecklistCreatorList().then(data => {
      setChecklistCreators(data);
    });
  }, []);

  useEffect(() => {
    fetchChecklistLocalData();
    if (checklistFieldValues === null) {
      setChecklist(generalChecklist);
      getFocus()
        .then(focus => {
          getShiftData(focus).then(data => {
            setShiftData(data);
          });
        })
        .catch(error => {
          console.log("GET SHIFT DATA ERROR: ", error);
        });
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const val = e.target.value;
    setChecklistFieldValues({ ...checklistFieldValues, [`${name}`]: val });
  };

  // const handleSelectChecklist = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   let updatedChecklistData;
  //   const type = e.target.value;
  //   if (type === checklistTypes.GENERAL_CHECKLIST) {
  //     setChecklist(generalChecklist);
  //     setSelectedChecklistType(checklistTypes.GENERAL_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.GENERAL_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.INSULATION_CHECKLIST) {
  //     setChecklist(insulationChecklist);
  //     setSelectedChecklistType(checklistTypes.INSULATION_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.INSULATION_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.ELECTRIC_DOMESTIC_WATERHEATER_CHECKLIST) {
  //     setChecklist(electricDomesticWaterHeatersChecklist);
  //     setSelectedChecklistType(checklistTypes.ELECTRIC_DOMESTIC_WATERHEATER_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.ELECTRIC_DOMESTIC_WATERHEATER_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.POTABLE_WATER_STORAGE_TANKS_CHECKLIST) {
  //     setChecklist(potableWaterStorageTanksChecklist);
  //     setSelectedChecklistType(checklistTypes.POTABLE_WATER_STORAGE_TANKS_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.POTABLE_WATER_STORAGE_TANKS_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.SANITARY_DRAINAGE_CHECKLIST) {
  //     setChecklist(sanitaryDrainageChecklist);
  //     setSelectedChecklistType(checklistTypes.SANITARY_DRAINAGE_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.SANITARY_DRAINAGE_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.STORM_DRAINAGE_CHECKLIST) {
  //     setChecklist(stormDrainageChecklist);
  //     setSelectedChecklistType(checklistTypes.STORM_DRAINAGE_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.STORM_DRAINAGE_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.DOMESTIC_PORTABLE_WATER_DISTRIBUTION_CHECKLIST) {
  //     setChecklist(domesticPortableWaterDistributionChecklist);
  //     setSelectedChecklistType(checklistTypes.DOMESTIC_PORTABLE_WATER_DISTRIBUTION_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.DOMESTIC_PORTABLE_WATER_DISTRIBUTION_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.FUEL_FIRED_DOMESTIC_WATER_HEATERS_CHECKLIST) {
  //     setChecklist(fuelFiredDomesticWaterHeatersChecklist);
  //     setSelectedChecklistType(checklistTypes.FUEL_FIRED_DOMESTIC_WATER_HEATERS_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.FUEL_FIRED_DOMESTIC_WATER_HEATERS_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.PLUMBING_FIXTURES_CHECKLIST) {
  //     setChecklist(plumbingFixturesChecklist);
  //     setSelectedChecklistType(checklistTypes.PLUMBING_FIXTURES_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.PLUMBING_FIXTURES_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else if (type === checklistTypes.FIRE_STOPPING_CHECKLIST) {
  //     setChecklist(fireStoppingChecklist);
  //     setSelectedChecklistType(checklistTypes.FIRE_STOPPING_CHECKLIST);
  //     updatedChecklistData = await updateChecklistData({
  //       checklistType: checklistTypes.FIRE_STOPPING_CHECKLIST,
  //     });
  //     setChecklistFieldValues(updatedChecklistData);
  //   } else return;
  // };

  const getSelectedChecklist = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    let updatedChecklistData;
    const selectedChecklistElement = loadedChecklists.find((el: any) => el.type === e.target.value);
    setChecklist(selectedChecklistElement);
    setSelectedChecklistType(selectedChecklistElement.type);
    updatedChecklistData = await updateChecklistData({
      checklistType: selectedChecklistElement.type,
    });
    setSelectedChecklistType(updatedChecklistData?.checklistType);
    setChecklistFieldValues(updatedChecklistData);
  };

  const handleTopFormSave = async () => {
    try {
      const updatedChecklist = await updateChecklistData(checklistFieldValues);
      if (updatedChecklist) {
        setChecklistFieldValues(updatedChecklist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderTopForm = () => (
    <div className="checklist-topform">
      <div className="checklist-topform-checklisttype">
        <select
          className="checklist-topform-checklisttype-select"
          name=""
          id=""
          onChange={e => {
            // handleSelectChecklist(e);
            getSelectedChecklist(e);
          }}
          defaultValue={selectedChecklistType}
          value={selectedChecklistType}
        >
          <option className="checklist-topform-checklisttype-select-val" value={"default"}>
            Select Checklist Type
          </option>
          {loadedChecklistTypes?.map((type, idx) => {
            return (
              <option key={idx} className="checklist-topform-checklisttype-select-val" value={type}>
                {type}
              </option>
            );
          })}
        </select>
      </div>
      <div className="checklist-topform-inputgroup">
        {/* <div className='checklist-topform-inputgroup-inner'>
          <label className='checklist-topform-inputgroup-label' htmlFor="">LOCATION:</label>
          <select className='checklist-topform-inputgroup-select'>
            <option>Select Location</option>
          </select>
        </div> */}

        {/* <div className="checklist-topform-inputgroup-inner">
          <label className="checklist-topform-inputgroup-label" htmlFor="">
            Sub Contractor:
          </label>
          <select>
            <option>Select Sub Contractor</option>
          </select>
        </div> */}
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Prepared by:
        </label>
        <input className="checklist-topform-inputgroup-input" name="preparedBy" value={checklistFieldValues?.preparedBy} type="text" onChange={handleInputChange} placeholder="Add Preparer Here..." />
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Inspector Name:
        </label>
        <input className="checklist-topform-inputgroup-input" name="inspectorName" value={checklistFieldValues?.inspectorName} type="text" onChange={handleInputChange} placeholder="Add Inspector Name Here..." />
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Surveillance Report:
        </label>
        <textarea className="checklist-topform-inputgroup-input" name="surveillanceReport" value={checklistFieldValues?.surveillanceReport} id="" cols={30} rows={10} onChange={handleInputChange} placeholder="Add Surveillance Report Here..." />
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Drawing:
        </label>
        <input className="checklist-topform-inputgroup-input" name="drawing" value={checklistFieldValues?.drawing} type="text" onChange={handleInputChange} placeholder="Add Drawing Here..." />
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Description:
        </label>
        <textarea className="checklist-topform-inputgroup-input" name="description" value={checklistFieldValues?.description} id="" cols={30} rows={10} onChange={handleInputChange} placeholder="Add Description Here..." />
      </div>
      <div className="checklist-topform-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Specifications Number:
        </label>
        <input className="checklist-topform-inputgroup-input" name="specificationsNo" value={checklistFieldValues?.specificationsNo} type="text" onChange={handleInputChange} placeholder="Add Specification Number Here..." />
      </div>
    </div>
  );

  const renderNonConformancesRemarks = () => (
    <div className="checklist-nonconformances">
      <div className="checklist-nonconformances-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-nonconformances-inputgroup-label" htmlFor="nonconformanceRemarks">
          Nonconformances Remarks:
        </label>
        <textarea className="checklist-nonconformances-inputgroup-input" name="nonconformanceRemarks" value={checklistFieldValues?.nonconformanceRemarks} id="nonconformanceRemarks" cols={30} rows={10} onChange={handleInputChange} placeholder="Add Nonconformances Remarks Here..." />
      </div>
    </div>
  );

  const renderAdditionalRemarks = () => (
    <div className="checklist-additionalremarks">
      <div className="checklist-additionalremarks-inputgroup" onBlur={handleTopFormSave}>
        <label className="checklist-additionalremarks-inputgroup-label" htmlFor="remarks">
          Additional Remarks:
        </label>
        <textarea className="checklist-additionalremarks-inputgroup-input" name="remarks" value={checklistFieldValues?.remarks} id="remarks" cols={30} rows={10} onChange={handleInputChange} placeholder="Add Additional Remarks Here..." />
      </div>
    </div>
  );

  let sigPad1: SignatureCanvas | null;
  let sigPad2: SignatureCanvas | null;
  let sigPad3: SignatureCanvas | null;

  const trimSignature1 = () => {
    const signatureData = sigPad1?.getTrimmedCanvas().toDataURL("image/png");
    setAdditionalRemarks({
      ...additionalRemarks,
      signature1: signatureData,
    });
    // return signatureData;
  };

  const trimSignature2 = () => {
    const signatureData = sigPad2?.getTrimmedCanvas().toDataURL("image/png");
    setAdditionalRemarks({
      ...additionalRemarks,
      signature2: signatureData,
    });
    // return signatureData;
  };

  const trimSignature3 = () => {
    const signatureData = sigPad3?.getTrimmedCanvas().toDataURL("image/png");
    setAdditionalRemarks({
      ...additionalRemarks,
      signature3: signatureData,
    });
    // return signatureData;
  };

  const renderSignatures = () => (
    <div className="checklist-signatures">
      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-name"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-name-label" htmlFor="name1">
            Print Name
          </label>
          <input
            name="name1"
            className="checklist-signatures-row-name-input"
            type="text"
            placeholder="Add Name Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
        <div
          className="checklist-signatures-row-title"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-title-label" htmlFor="title1">
            Title
          </label>
          <input
            name="title1"
            className="checklist-signatures-row-title-input"
            type="text"
            placeholder="Add Title Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
      </div>
      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-sig"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-sig-label" htmlFor="signature">
            Signature
          </label>
          {!additionalRemarks?.signature1 ? (
            <SignatureCanvas
              ref={ref => (sigPad1 = ref)}
              canvasProps={{
                style: {
                  backgroundColor: "#d6dce1",
                  height: "5rem",
                  width: "50rem",
                },
              }}
              onEnd={() => {
                trimSignature1();
              }}
            />
          ) : (
            <img
              src={additionalRemarks?.signature1 || ""}
              alt="signature1"
              style={{
                backgroundColor: "#d6dce1",
                height: "5rem",
                width: "50rem",
              }}
            />
          )}
        </div>
        <div
          className="checklist-signatures-row-date"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-date-label" htmlFor="date1">
            Date
          </label>
          <input
            name="date1"
            className="checklist-signatures-row-date-input"
            type="date"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: formatTheDate(new Date(val)),
              });
            }}
          />
        </div>
      </div>

      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-name"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-name-label" htmlFor="name2">
            Print Name
          </label>
          <input
            name="name2"
            className="checklist-signatures-row-name-input"
            type="text"
            placeholder="Add Name Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
        <div
          className="checklist-signatures-row-title"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-title-label" htmlFor="title2">
            Title
          </label>
          <input
            name="title2"
            className="checklist-signatures-row-title-input"
            type="text"
            placeholder="Add Title Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
      </div>
      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-sig"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-sig-label" htmlFor="signature">
            Signature
          </label>
          {!additionalRemarks?.signature2 ? (
            <SignatureCanvas
              ref={ref => (sigPad2 = ref)}
              canvasProps={{
                style: {
                  backgroundColor: "#d6dce1",
                  height: "5rem",
                  width: "50rem",
                },
              }}
              onEnd={() => {
                trimSignature2();
              }}
            />
          ) : (
            <img
              src={additionalRemarks?.signature2 || ""}
              alt="signature1"
              style={{
                backgroundColor: "#d6dce1",
                height: "5rem",
                width: "50rem",
              }}
            />
          )}
        </div>
        <div
          className="checklist-signatures-row-date"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-date-label" htmlFor="date2">
            Date
          </label>
          <input
            name="date2"
            className="checklist-signatures-row-date-input"
            type="date"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: formatTheDate(new Date(val)),
              });
            }}
          />
        </div>
      </div>

      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-name"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-name-label" htmlFor="name3">
            Print Name
          </label>
          <input
            name="name3"
            className="checklist-signatures-row-name-input"
            type="text"
            placeholder="Add Name Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
        <div
          className="checklist-signatures-row-title"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-title-label" htmlFor="title3">
            Title
          </label>
          <input
            name="title3"
            className="checklist-signatures-row-title-input"
            type="text"
            placeholder="Add Title Here"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;
              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: val,
              });
            }}
          />
        </div>
      </div>
      <div className="checklist-signatures-row">
        <div
          className="checklist-signatures-row-sig"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-sig-label" htmlFor="signature">
            Signature
          </label>
          {!additionalRemarks?.signature3 ? (
            <SignatureCanvas
              ref={ref => (sigPad3 = ref)}
              canvasProps={{
                style: {
                  backgroundColor: "#d6dce1",
                  height: "5rem",
                  width: "50rem",
                },
              }}
              onEnd={() => {
                trimSignature3();
              }}
            />
          ) : (
            <img
              src={additionalRemarks?.signature3 || ""}
              alt="signature1"
              style={{
                backgroundColor: "#d6dce1",
                height: "5rem",
                width: "50rem",
              }}
            />
          )}
        </div>
        <div
          className="checklist-signatures-row-date"
          onBlur={async () => {
            let data = await updateChecklistAdditionalRemarks(additionalRemarks);
            setChecklistFieldValues(data);
          }}
        >
          <label className="checklist-signatures-row-date-label" htmlFor="date3">
            Date
          </label>
          <input
            name="date3"
            className="checklist-signatures-row-date-input"
            type="date"
            onChange={e => {
              let name = e.target.name;
              let val = e.target.value;

              setAdditionalRemarks({
                ...additionalRemarks,
                [`${name}`]: formatTheDate(new Date(val)),
              });
            }}
          />
        </div>
      </div>
    </div>
  );

  const renderFieldTasks = () => (
    <ul className="checklist-fieldtasks">
      {checklist?.fieldTasks.map((task, idx) => {
        return (
          <li key={idx} className="checklist-fieldtasks-items">
            <span className="checklist-fieldtasks-items-qnumber">{idx + 1}</span>
            <label className="checklist-fieldtasks-items-question" htmlFor="">
              {task.question}
            </label>
            <div
              className="checklist-fieldtasks-items-answer"
              onBlur={async () => {
                try {
                  const updatedChecklist = await updateChecklistData({
                    fieldTasks,
                  });
                  if (updatedChecklist) {
                    setChecklistFieldValues(updatedChecklist);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <input
                className="checklist-fieldtasks-items-answer-input"
                type="checkbox"
                name={task?.question}
                defaultChecked={checklistFieldValues?.fieldTasks && checklistFieldValues?.fieldTasks[`task${task?.id}`]?.answer === "yes"}
                id=""
                onChange={e => {
                  const ans = {
                    id: task?.id,
                    question: task?.question,
                    answer: e.target.checked && "yes",
                  };
                  let tasksArr = fieldTasks;
                  tasksArr.push(ans);
                  setFieldTasks(tasksArr);
                  // setFieldTasks([
                  //   ...fieldTasks,
                  //   ans
                  //   // [`task${task.id}`]: ans,
                  //   // [`${e.target.name}`]: e.target.checked
                  // ]);
                }}
              />
              <label className="checklist-fieldtasks-items-answer-label" htmlFor="">
                YES
              </label>
            </div>

            <div
              className="checklist-fieldtasks-items-answer"
              onBlur={async () => {
                try {
                  const updatedChecklist = await updateChecklistData({
                    fieldTasks,
                  });
                  if (updatedChecklist) {
                    setChecklistFieldValues(updatedChecklist);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <input
                className="checklist-fieldtasks-items-answer-input"
                type="checkbox"
                name={task?.question}
                defaultChecked={checklistFieldValues?.fieldTasks && checklistFieldValues?.fieldTasks[`task${task?.id}`]?.answer === "no"}
                id=""
                onChange={e => {
                  const ans = {
                    id: task?.id,
                    question: task?.question,
                    answer: e.target.checked && "no",
                  };
                  let tasksArr = fieldTasks;
                  tasksArr.push(ans);
                  setFieldTasks(tasksArr);
                  // setFieldTasks([
                  //   ...fieldTasks,
                  //   ans
                  //   // [`task${task.id}`]: ans,
                  //   // [`${e.target.name}`]: e.target.checked
                  // ]);
                }}
              />
              <label className="checklist-fieldtasks-items-answer-label" htmlFor="">
                NO
              </label>
            </div>

            <div
              className="checklist-fieldtasks-items-answer"
              onBlur={async () => {
                try {
                  const updatedChecklist = await updateChecklistData({
                    fieldTasks,
                  });
                  if (updatedChecklist) {
                    setChecklistFieldValues(updatedChecklist);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <input
                className="checklist-fieldtasks-items-answer-input"
                type="checkbox"
                name={task?.question}
                defaultChecked={checklistFieldValues?.fieldTasks && checklistFieldValues?.fieldTasks[`task${task?.id}`]?.answer !== undefined && checklistFieldValues?.fieldTasks[`task${task?.id}`]?.answer === "N/A"}
                onChange={e => {
                  const ans = {
                    id: task?.id,
                    question: task?.question,
                    answer: e.target.checked ? "N/A" : "",
                  };
                  let tasksArr = fieldTasks;
                  tasksArr.push(ans);
                  setFieldTasks(tasksArr);
                  // setFieldTasks([
                  //   ...fieldTasks,
                  //   ans
                  //   // [`task${task.id}`]: ans,
                  // ]);
                }}
              />
              <label className="checklist-fieldtasks-items-answer-label" htmlFor="">
                N/A
              </label>
            </div>
          </li>
        );
      })}
    </ul>
  );

  const handleSubmitChecklist = async () => {
    // if (checklistFieldValues.agency.__typename) {
    //   delete checklistFieldValues.agency.__typename;
    // }

    const submittedChecklist = await createChecklist({ variables: { input: checklistFieldValues } });
    alert("Checklist Successfully Submitted");
    setChecklistFieldValues(null);

    navigate(`/checklistreports-pdf?checklistid=${submittedChecklist?.data?.createChecklist.id}`, { replace: true });
  };

  const exportPDF = () => {
    let jsPdf = new jsPDF("p", "pt", "a4");
    var htmlElement = document.getElementById("clfieldreport");
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt: any = {
      callback: async function (jsPdf: any) {
        // jsPdf.save("Test.pdf");
        // to open the generated PDF in browser window
        // window.open(jsPdf.output("bloburl"));
        jsPdf.setProperties({ title: `${shiftData?.checklistType} Work_Report.pdf` });
        if (showEmailControls && !savePdfPrep) {
          const outputPdf = await jsPdf.output("blob");

          await emailReport(emailRecipient, emailBody, outputPdf);
          setShowEmailControls(false);
          alert("The checklist has been successfully saved.");
          setEmailRecipient("");
          setEmailBody("");
        } else if (!showEmailControls && savePdfPrep) {
          console.log("SAVING PDF...");
          // jsPdf.save(`${selectedWR.jobName} shift_${selectedWR.shift} Work_Report.pdf`);
          window.open(jsPdf.output("bloburl"));
          setSavePdfPrep(false);
        }
      },
      // margin: [72, 72, 72, 72],
      // autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.8,
      },
    };

    jsPdf.html(htmlElement!, opt);
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role === "ADMIN") {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (!shiftData) {
    return <div>Loading Data - Please Wait...</div>;
  }

  return (
    <div className="checklist">
      <Header headerType="checklist" site={shiftData} workShift={(shift: string) => setSelectedShift(shift)} />
      {showEmailControls && (
        <EmailReports
          sendEmail={() => {
            // saveToPdf();
            exportPDF();
            // convertRecipientString();
          }}
          handleRecipientInput={e => {
            if (e.target.name === "recipient") {
              setEmailRecipient(e.target.value);
            } else if (e.target.name === "body") {
              setEmailBody(e.target.value);
            }
          }}
          emailRecipient={emailRecipient}
          emailBody={emailBody}
        />
      )}
      <div className="checklist-controls">
        <Button1
          btnClassName="checklist-controls-submitbtn"
          btnType="button"
          btnName="submit-button"
          btnValue="Submit Checklist"
          shouldDisable={showEmailControls}
          onclick={() => {
            handleSubmitChecklist();
          }}
        />

        <Button1
          btnClassName="checklist-controls-submitbtn"
          btnType="button"
          btnName="submit-button"
          btnValue="Save PDF"
          shouldDisable={showEmailControls}
          onclick={async () => {
            setSavePdfPrep(true);
            exportPDF();
            // await saveChecklistPdf();
            // openReportInNewTab(`${devUrl}/checklistreports-pdf`);
            // setShowEmailControls(!showEmailControls);
          }}
        />

        <Button1
          btnClassName="checklist-controls-submitbtn"
          btnType="button"
          btnName="submit-button"
          btnValue="Email Checklist"
          onclick={() => {
            setShowEmailControls(!showEmailControls);
          }}
        />
      </div>
      {!showEmailControls && !savePdfPrep ? (
        <>
          <div className="workreport-main-section-divider">
            <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showTopForm ? faChevronUp : faChevronDown} onClick={() => setShowTopForm(!showTopForm)} />
            <h4 className="workreport-main-section-divider-title">Checklist Selector/Top Fields</h4>
          </div>
          {showTopForm && renderTopForm()}
          <div className="workreport-main-section-divider">
            <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showFieldTasks ? faChevronUp : faChevronDown} onClick={() => setShowFieldTasks(!showFieldTasks)} />
            <h4 className="workreport-main-section-divider-title">Field Tasks</h4>
          </div>
          {showFieldTasks && renderFieldTasks()}
          <div className="workreport-main-section-divider">
            <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showNonconformances ? faChevronUp : faChevronDown} onClick={() => setShowNonconformances(!showNonconformances)} />
            <h4 className="workreport-main-section-divider-title">Nonconformances Remarks</h4>
          </div>
          {showNonconformances && renderNonConformancesRemarks()}
          <div className="workreport-main-section-divider">
            <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showAdditionalRemarks ? faChevronUp : faChevronDown} onClick={() => setShowAdditionalRemarks(!showAdditionalRemarks)} />
            <h4 className="workreport-main-section-divider-title">Additional Remarks</h4>
          </div>
          {showAdditionalRemarks && renderAdditionalRemarks()}
          <div className="workreport-main-section-divider">
            <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showSignatures ? faChevronUp : faChevronDown} onClick={() => setShowSignatures(!showSignatures)} />
            <h4 className="workreport-main-section-divider-title">Signatures</h4>
          </div>
          {showSignatures && renderSignatures()}
        </>
      ) : (
        <ChecklistFieldReport report={checklistFieldValues} />
      )}
    </div>
  );
};

export default Checklist;
