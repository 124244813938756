import React, { useEffect, useState } from "react";
import { formatTheDate } from "../../helpers/dateTime";

import "./ChecklistFieldReport.scss";

interface CLReportProps {
  reportId?: string;
  report: any;
}

export const ChecklistFieldReport: React.FC<CLReportProps> = ({ report }) => {
  const [clReport, setClReport] = useState<any | null>(null);

  useEffect(() => {
    setClReport(report);
    localStorage.setItem("CLReport", JSON.stringify(report));
  }, [report]);

  return (
    <div className="clfieldreport" id="clfieldreport">
      <div className="clfieldreport-titlegrp">
        <div className="clfieldreport-titlegrp-address">
          <p className="clfieldreport-titlegrp-address-text">BISSETTA & LIST, INC.</p>
          <p className="clfieldreport-titlegrp-address-text">420 WEST 49th STREET</p>
          <p className="clfieldreport-titlegrp-address-text">NEW YORK, NY 10019</p>
        </div>
        <div className="clfieldreport-titlegrp-clname">
          <p>PLUMBING CHECKLIST</p>
        </div>
      </div>

      <div className="clfieldreport-topcontainer">
        <h1 className="clfieldreport-topcontainer-title">{clReport?.checklistType}</h1>
        <div className="clfieldreport-topcontainer-row1">
          <div className="clfieldreport-topcontainer-row1-contractno">
            <label className="clfieldreport-topcontainer-row1-contractno-label" htmlFor="contractNo">
              Contract No:
            </label>
            <p className="clfieldreport-topcontainer-row1-contractno-text">{clReport?.contractNo ? clReport?.contractNo : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row1-description">
            <label className="clfieldreport-topcontainer-row1-description-label" htmlFor="description">
              Description:
            </label>
            <p className="clfieldreport-topcontainer-row1-description-text">{clReport?.description ? clReport?.description : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-topcontainer-row2">
          <div className="clfieldreport-topcontainer-row2-contractor">
            <label className="clfieldreport-topcontainer-row2-contractor-label" htmlFor="contractor">
              Contractor:
            </label>
            <p className="clfieldreport-topcontainer-row2-contractor-text">{clReport?.agency?.agencyName ? clReport?.agency?.agencyName : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row2-sub_contractor">
            <label className="clfieldreport-topcontainer-row2-sub_contractor-label" htmlFor="sub_contractor">
              Sub-Contractor:
            </label>
            <p className="clfieldreport-topcontainer-row2-sub_contractor-text">{clReport?.sub_contractor ? clReport?.sub_contractor : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-topcontainer-row3">
          <div className="clfieldreport-topcontainer-row3-location">
            <label className="clfieldreport-topcontainer-row3-location-label" htmlFor="location">
              Location:
            </label>
            <p className="clfieldreport-topcontainer-row3-location-text">{clReport?.location ? clReport?.location : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row3-date">
            <label className="clfieldreport-topcontainer-row3-date-label" htmlFor="date">
              Date:
            </label>
            <p className="clfieldreport-topcontainer-row3-date-text">{clReport?.date ? formatTheDate(clReport?.date) : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-topcontainer-row4">
          <div className="clfieldreport-topcontainer-row4-preparedBy">
            <label className="clfieldreport-topcontainer-row4-preparedBy-label" htmlFor="preparedBy">
              Prepared By:
            </label>
            <p className="clfieldreport-topcontainer-row4-preparedBy-text">{clReport?.preparedBy ? clReport?.preparedBy : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row4-datePrepared">
            <label className="clfieldreport-topcontainer-row4-datePrepared-label" htmlFor="datePrepared">
              Date Prepared:
            </label>
            <p className="clfieldreport-topcontainer-row4-datePrepared-text">{clReport?.datePrepared ? formatTheDate(clReport?.datePrepared) : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-topcontainer-row5">
          <div className="clfieldreport-topcontainer-row5-inspectorname">
            <label className="clfieldreport-topcontainer-row5-inspectorname-label" htmlFor="inspectorname">
              Inspector's Name:
            </label>
            <p className="clfieldreport-topcontainer-row5-inspectorname-text">{clReport?.inspectorName ? clReport?.inspectorName : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row5-inspectorname">
            <label className="clfieldreport-topcontainer-row5-inspectorname-label" htmlFor="inspectorname">
              Specifications Number:
            </label>
            <p className="clfieldreport-topcontainer-row5-inspectorname-text">{clReport?.specificationsNo ? clReport?.specificationsNo : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-topcontainer-row6">
          <div className="clfieldreport-topcontainer-row6-surveillanceReport">
            <label className="clfieldreport-topcontainer-row6-surveillanceReport-label" htmlFor="surveillanceReport">
              Surveillance Report:
            </label>
            <p className="clfieldreport-topcontainer-row6-surveillanceReport-text">{clReport?.surveillanceReport ? clReport?.surveillanceReport : "N/A"}</p>
          </div>
          <div className="clfieldreport-topcontainer-row6-drawing">
            <label className="clfieldreport-topcontainer-row6-drawing-label" htmlFor="drawing">
              Drawing:
            </label>
            <p className="clfieldreport-topcontainer-row6-drawing-text">{clReport?.drawing ? clReport?.drawing : "N/A"}</p>
          </div>
        </div>
      </div>

      <div className="clfieldreport-fieldtasks">
        <h4 className="clfieldreport-fieldtasks-title">FIELD TASKS</h4>
        <ul className="clfieldreport-fieldtasks-list">
          {clReport?.fieldTasks?.map((task: { id: number; question: string; answer: string }, idx: number) => (
            <li className="clfieldreport-fieldtasks-list-item" key={task.id}>
              <div className="clfieldreport-fieldtasks-list-item-grp">
                <span className="clfieldreport-fieldtasks-list-item-grp-number">{idx + 1}.</span>
                <p className="clfieldreport-fieldtasks-list-item-grp-q">{task.question}</p>
                <p className="clfieldreport-fieldtasks-list-item-grp-ans">{task.answer}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="clfieldreport-nonconformancesrmks">
        <label className="clfieldreport-nonconformancesrmks-label" htmlFor="nonconformancesrmks">
          Nonconformances Remarks:
        </label>
        <p className="clfieldreport-nonconformancesrmks-text">{clReport?.nonconformanceRemarks}</p>
      </div>

      <div className="clfieldreport-additionalrmks">
        <label className="clfieldreport-additionalrmks-label" htmlFor="additionalrmks">
          Additional Remarks:
        </label>
        <p className="clfieldreport-additionalrmks-text">{clReport?.remarks}</p>
      </div>

      <div className="clfieldreport-sigcontainer">
        <div className="clfieldreport-sigcontainer-row1">
          <div className="clfieldreport-sigcontainer-row1-name">
            <label className="clfieldreport-sigcontainer-row1-name-label" htmlFor="name">
              Name:
            </label>
            <p className="clfieldreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks?.name1 ? clReport?.additionalRemarks?.name1 : "N/A"}</p>
          </div>
          <div className="clfieldreport-sigcontainer-row1-title">
            <label className="clfieldreport-sigcontainer-row1-title-label" htmlFor="title">
              Title:
            </label>
            <p className="clfieldreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks.title1 ? clReport?.additionalRemarks.title1 : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-sigcontainer-row2">
          <div className="clfieldreport-sigcontainer-row2-signature">
            <label className="clfieldreport-sigcontainer-row2-signature-label" htmlFor="signature">
              Signature:
            </label>
            <img className="clfieldreport-sigcontainer-row2-signature-text" id="signature1" src={clReport?.additionalRemarks?.signature1 ? clReport?.additionalRemarks?.signature1 : "N/A"} />
          </div>
          <div className="clfieldreport-sigcontainer-row2-date">
            <label className="clfieldreport-sigcontainer-row2-date-label" htmlFor="date">
              Date:
            </label>
            <p className="clfieldreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks?.date1 ? formatTheDate(clReport?.date) : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-sigcontainer-row1">
          <div className="clfieldreport-sigcontainer-row1-name">
            <label className="clfieldreport-sigcontainer-row1-name-label" htmlFor="name">
              Name:
            </label>
            <p className="clfieldreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks.name2 ? clReport?.additionalRemarks.name2 : "N/A"}</p>
          </div>
          <div className="clfieldreport-sigcontainer-row1-title">
            <label className="clfieldreport-sigcontainer-row1-title-label" htmlFor="title">
              Title:
            </label>
            <p className="clfieldreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks.title2 ? clReport?.additionalRemarks.title2 : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-sigcontainer-row2">
          <div className="clfieldreport-sigcontainer-row2-signature">
            <label className="clfieldreport-sigcontainer-row2-signature-label" htmlFor="signature">
              Signature:
            </label>
            <img className="clfieldreport-sigcontainer-row2-signature-text" id="signature2" src={clReport?.additionalRemarks?.signature2 ? clReport?.additionalRemarks?.signature2 : "N/A"} />
          </div>
          <div className="clfieldreport-sigcontainer-row2-date">
            <label className="clfieldreport-sigcontainer-row2-date-label" htmlFor="date">
              Date:
            </label>
            <p className="clfieldreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks.date2 ? formatTheDate(clReport?.date) : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-sigcontainer-row1">
          <div className="clfieldreport-sigcontainer-row1-name">
            <label className="clfieldreport-sigcontainer-row1-name-label" htmlFor="name">
              Name:
            </label>
            <p className="clfieldreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks.name3 ? clReport?.additionalRemarks.name3 : "N/A"}</p>
          </div>
          <div className="clfieldreport-sigcontainer-row1-title">
            <label className="clfieldreport-sigcontainer-row1-title-label" htmlFor="title">
              Title:
            </label>
            <p className="clfieldreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks.title3 ? clReport?.additionalRemarks.title3 : "N/A"}</p>
          </div>
        </div>

        <div className="clfieldreport-sigcontainer-row2">
          <div className="clfieldreport-sigcontainer-row2-signature">
            <label className="clfieldreport-sigcontainer-row2-signature-label" htmlFor="signature">
              Signature:
            </label>
            <img className="clfieldreport-sigcontainer-row2-signature-text" id="signature3" src={clReport?.additionalRemarks?.signature3 ? clReport?.additionalRemarks?.signature3 : "N/A"} />
          </div>
          <div className="clfieldreport-sigcontainer-row2-date">
            <label className="clfieldreport-sigcontainer-row2-date-label" htmlFor="date">
              Date:
            </label>
            <p className="clfieldreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks.date3 ? formatTheDate(clReport?.date) : "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
