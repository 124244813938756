import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { faEdit, faMinus, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createCostCodes, getCostCodes, updateCostCodes, removeCostCode, getEmployeeJobRates, createEmployeeJobRates, updateEmployeeJobRates, getJobs, getEmployees } from "../../../graphql/queries";
import { getUser } from "../../../api/auth";

import "./EmployeeRates.scss";
import AdminMenu from "../AdminMenu";

interface IEmployeeRate {
  employee: string;
  employeeId: string;
  stPay: number;
  otPay: number;
  dtPay: number;
  ptPay: number;
}

const EmployeeRates: React.FC = () => {
  const [xlData, setXlData] = useState<any>([]);
  const [jobs, setJobs] = useState<any[]>([]);
  const [selectedRates, setSelectedRates] = useState<IEmployeeRate>({
    employee: "",
    employeeId: "",
    stPay: 0,
    otPay: 0,
    dtPay: 0,
    ptPay: 0,
  });
  const [employees, setEmployees] = useState<any[]>([]);
  const [employeeToEnter, setEmployeeToEnter] = useState<IEmployeeRate>({
    employee: "",
    employeeId: "",
    stPay: 0,
    otPay: 0,
    dtPay: 0,
    ptPay: 0,
  });
  const [ratesList, setRatesList] = useState<any[]>([]);
  const [newRates, setNewRates] = useState<any>({});
  const [individualCostcode, setIndivudualCostCode] = useState<{ laborCode: string; costCode: string; description: string }>({
    laborCode: "",
    costCode: "",
    description: "",
  });
  const [deleteCostCodes, setDeleteCostCodes] = useState<any>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const [submitEmployeeRates, { data, loading, error }] = useMutation(createEmployeeJobRates);
  const [updateEmployeeRates] = useMutation(updateEmployeeJobRates);
  const [removeSelectedCostCodes] = useMutation(removeCostCode);

  const fetchEmployeeRates = async () => {
    const data: any = await getEmployeeJobRates();
    const d = { ...data };
    const updatedData = d?.employeeRates.map((e: IEmployeeRate) => {
      console.log("RATE DATA: ", e);
      if (!e.otPay) {
        e = { ...e, otPay: e.stPay + e.stPay / 2 };
      }
      if (!e.ptPay) {
        e = { ...e, ptPay: e.dtPay - e.stPay };
      }
      return e;
    });
    setRatesList(updatedData);
  };

  const fetchJobs = async () => {
    const jobsData: any = await getJobs();
    const jobsArr = jobsData.data.jobs;
    setJobs(jobsArr);
  };

  const fetchEmployees = async () => {
    const employeeData: any = await getEmployees();
    const workersArr = [...employeeData?.data.workers];
    const sorted = workersArr?.sort((a: any, b: any) => {
      const aName = a.lastName.toUpperCase();
      const bName = b.lastName.toUpperCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
    console.log("WORKERS ARR: ", workersArr);
    setEmployees(sorted);
  };

  const updateRates = async () => {
    const updatedData = await updateEmployeeRates({ variables: { input: selectedRates } });
    console.log(updatedData?.data);
    if (updatedData?.errors) {
      console.log("UPDATE RATES ERROR: ", updatedData?.errors);
    }
  };

  const removeRow = (idx: number) => {
    const splicedData = [...ratesList];
    splicedData.splice(idx, 1);
    setRatesList(splicedData);
  };

  useEffect(() => {
    fetchEmployeeRates();
    fetchJobs();
    fetchEmployees();
  }, []);

  // const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const reader = new FileReader();
  //   if (e.target.files) {
  //     reader.readAsBinaryString(e.target.files[0]);
  //     reader.onload = (e) => {
  //       const xlData = e.target?.result;
  //       const workbook = XLSX.read(xlData, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const parsedData = XLSX.utils.sheet_to_json(sheet);
  //       setXlData(parsedData);
  //     }
  //   }
  // }

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  // if (ratesList.length === 0) {
  //   return (
  //     <div>
  //       <h4>Loading...</h4>
  //     </div>
  //   )
  // }
  return (
    <div className="costcodes">
      <AdminMenu />
      <form className="costcodes-individual">
        <h3 className="costcodes-individual-header">New Employee Rates</h3>
        <div className="costcodes-individual-codegroup">
          <div className="costcodes-individual-codegroup-description">
            <label className="costcodes-individual-codegroup-description-label" htmlFor="">
              Employee
            </label>
            <div>
              <select
                name=""
                id=""
                onChange={e => {
                  const foundEmployee = employees.find((el: any) => el._id === e.target.value);
                  setEmployeeToEnter({
                    ...employeeToEnter,
                    employeeId: e.target.value,
                    employee: `${foundEmployee?.firstName} ${foundEmployee.lastName}`,
                  });
                }}
              >
                <option value="default">Select An Employee</option>
                {employees.map((employee: any, idx: number) => {
                  return (
                    <option key={idx} value={employee._id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="costcodes-individual-codegroup-labor">
            <label className="costcodes-individual-codegroup-labor-label" htmlFor="">
              ST Pay
            </label>
            <input
              className="costcodes-individual-codegroup-labor-input"
              type="number"
              value={employeeToEnter.stPay === 0 ? "" : employeeToEnter.stPay}
              onChange={e =>
                setEmployeeToEnter({
                  ...employeeToEnter,
                  stPay: parseFloat(e.target.value),
                })
              }
            />
          </div>
          <div className="costcodes-individual-codegroup-costcode">
            <label className="costcodes-individual-codegroup-costcode-label" htmlFor="">
              DT Pay
            </label>
            <input
              className="costcodes-individual-codegroup-costcode-input"
              type="number"
              value={employeeToEnter.dtPay === 0 ? "" : employeeToEnter.dtPay}
              onChange={e =>
                setEmployeeToEnter({
                  ...employeeToEnter,
                  dtPay: parseFloat(e.target.value),
                })
              }
            />
          </div>
        </div>
        {deleteCostCodes.length > 0 ? (
          <button
            className="costcodes-individual-delete"
            onClick={async e => {
              if (deleteCostCodes.length > 0) {
                e.preventDefault();
                const res = await removeSelectedCostCodes({ variables: { input: { id: deleteCostCodes } } });
                setDeleteCostCodes([]);
                if (res.errors) {
                  alert("There has been an error removing the selected Cost Codes");
                } else {
                  alert("Selected Cost Codes Have Been Successfully Removed");
                }
              }
            }}
          >
            Delete Selected Cost Code
          </button>
        ) : (
          <button
            className="costcodes-individual-submit"
            onClick={async e => {
              e.preventDefault();
              try {
                const res = await submitEmployeeRates({ variables: { input: employeeToEnter } });
                setNewRates([]);
                alert(`${employeeToEnter.employee} has been successfully entered.`);
                setEmployeeToEnter({
                  employee: "",
                  employeeId: "",
                  stPay: 0,
                  otPay: 0,
                  dtPay: 0,
                  ptPay: 0,
                });
                window.location.reload();
              } catch (error: any) {
                alert(error.message);
                setEmployeeToEnter({
                  employee: "",
                  employeeId: "",
                  stPay: 0,
                  otPay: 0,
                  dtPay: 0,
                  ptPay: 0,
                });
              }
              // window.location.reload();
            }}
          >
            Submit Employee Rate
          </button>
        )}
      </form>

      <div className="costcodes-bottomwrapper">
        {canEdit && (
          <div>
            <button
              onClick={() => {
                updateRates();
                setCanEdit(false);
              }}
            >
              Save Updates
            </button>
          </div>
        )}

        <table style={{ height: "100%", width: "100%" }}>
          <thead>
            <tr>
              <th>Employee</th>
              <th>ST Pay</th>
              <th>OT Pay</th>
              <th>DT Pay</th>
              <th>PT Pay</th>
            </tr>
          </thead>
          <tbody style={{}}>
            {ratesList.map((rr: any, idx: number) => {
              return (
                <tr>
                  <td style={{ textAlign: "center" }}>{rr.employee}</td>
                  <td style={{ textAlign: "center" }}>
                    &#x24;
                    {canEdit && rr.employeeId === selectedRates.employeeId ? (
                      <input
                        className="budget-mlist-tasks-table-row-content-input matQtyInput"
                        id="matUnitCost"
                        type="number"
                        name="unitCost"
                        value={rr?.stPay}
                        onChange={e => {
                          const updatedList = ratesList?.map((m: IEmployeeRate, index: number) => {
                            if (idx === index) {
                              console.log("indexes match...");
                              let i = { ...m };
                              i.stPay = parseFloat(e.target.value);
                              i.otPay = i.stPay + i.stPay / 2;
                              setSelectedRates({
                                ...selectedRates,
                                stPay: parseFloat(e.target.value),
                                otPay: i.stPay + i.stPay / 2,
                              });
                              return i;
                            } else return m;
                          });
                          setRatesList(updatedList);
                        }}
                        step="0.001"
                      />
                    ) : (
                      rr.stPay
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>&#x24;{rr.otPay}</td>
                  <td style={{ textAlign: "center" }}>
                    &#x24;
                    {canEdit && rr.employeeId === selectedRates.employeeId ? (
                      <input
                        className="budget-mlist-tasks-table-row-content-input matQtyInput"
                        id="matUnitCost"
                        type="number"
                        name="unitCost"
                        value={rr?.dtPay}
                        onChange={e => {
                          const updatedList = ratesList?.map((m: IEmployeeRate, index: number) => {
                            if (idx === index) {
                              let i = { ...m };
                              i.dtPay = parseFloat(e.target.value);
                              i.ptPay = i.dtPay - i.stPay;
                              setSelectedRates({
                                ...selectedRates,
                                dtPay: parseFloat(e.target.value),
                                ptPay: i.dtPay - i.stPay,
                              });
                              return i;
                            } else return m;
                          });
                          setRatesList(updatedList);
                        }}
                        step="0.001"
                      />
                    ) : (
                      rr.dtPay
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>&#x24;{rr.ptPay.toFixed(2)}</td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <FontAwesomeIcon
                        className="header-2-col1-inputwrapper-removeicon"
                        icon={faPen}
                        onClick={() => {
                          setCanEdit(!canEdit);
                          setSelectedRates(rr);
                        }}
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <FontAwesomeIcon
                        className="header-2-col1-inputwrapper-removeicon"
                        icon={faMinus}
                        onClick={() => {
                          removeRow(idx);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeRates;
