
const getCoord = () => {
  if (!navigator.geolocation) {
    console.log('Browser doesn\'t support geolocation...');
  }
  navigator.geolocation.getCurrentPosition(position => {
    const currentPosition = {
      lon: position.coords.longitude || -73.9783360404487,
      lat: position.coords.latitude || 40.76375185158217,
    }
    localStorage.setItem('currentPosition', JSON.stringify(currentPosition))
  })
}

const newyorkCoords = {
  lon: -73.9783360404487,
  lat: 40.76375185158217,
}

export const setCurrentWeather = () => {
  getCoord();
  const weatherAPI = 'a34dd6b4e373d39073d9af3549100780';
  // const currentPosition = JSON.parse(localStorage.getItem('currentPosition')!);
  const currentPosition = newyorkCoords;
  const weatherURL =
    `https://api.openweathermap.org/data/2.5/weather?lat=${currentPosition?.lat}&lon=${currentPosition?.lon}&appid=${weatherAPI}&units=imperial`

  fetch(weatherURL)
    .then(res => res.json())
    .then(data => {
      localStorage.setItem('currentWeather', JSON.stringify(data));
    })
    .catch(err => {
      console.log(err.message)
      alert(err.message);
    })
}

export const getCurrentWeather = (callback: (arg: {}) => any) => {
  const currentWeather = JSON.parse(localStorage.getItem('currentWeather')!);
  callback(currentWeather);
}



