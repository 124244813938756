import React from "react";

import "./Button.scss";

interface button1Props {
  btnClassName?: string;
  btnName: string;
  btnType: "submit" | "button" | "reset";
  btnValue: string;
  onclick: () => JSX.Element | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | object | void;
  shouldDisable?: boolean;
}

const Button1: React.FC<button1Props> = ({ btnClassName, btnName, btnType, btnValue, onclick, shouldDisable }) => {
  return (
    <>
      <button className={`button1 ${btnClassName}`} name="btnName" type={btnType} onClick={() => onclick()} disabled={shouldDisable!}>
        {btnValue}
      </button>
    </>
  );
};

export default Button1;
