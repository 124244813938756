import React, { useState } from "react";
import jsPDF from "jspdf";

import "./EmailReports.scss";

interface EmailReportsProps {
  sendEmail: () => void;
  handleRecipientInput: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  emailRecipient: string;
  emailBody: string;
}

const EmailReports: React.FC<EmailReportsProps> = ({ sendEmail, handleRecipientInput, emailRecipient, emailBody }) => {
  const [emailData, setEmailData] = useState<{ recipient: string; body: string; attachment?: string }>({
    recipient: "",
    body: "",
  });

  return (
    <div className="emailreports">
      <form className="emailreports-form">
        <div className="emailreports-form-grp">
          <label className="emailreports-form-grp-label" htmlFor="email-recipient">
            Email Recipients
          </label>
          <input
            className="emailreports-form-grp-input"
            placeholder="jane.doe@email.com, jon.doe@email.com"
            type="email"
            name="recipient"
            value={emailRecipient}
            onChange={e => {
              e.preventDefault();
              setEmailData({
                ...emailData,
                recipient: e.target.value,
              });
              handleRecipientInput(e);
            }}
          />
        </div>
        <div className="emailreports-form-grp emailbody">
          <label className="emailreports-form-grp-label" htmlFor="email-body">
            Message (Optional)
          </label>
          <textarea
            className="emailreports-form-grp-textarea"
            placeholder="Add message body here..."
            name="body"
            rows={30}
            value={emailBody}
            onChange={e => {
              e.preventDefault();
              setEmailData({
                ...emailData,
                body: e.target.value,
              });
              handleRecipientInput(e);
            }}
          />
        </div>
        <button
          className="emailreports-form-sendbtn"
          type="submit"
          onClick={e => {
            e.preventDefault();
            sendEmail();
          }}
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default EmailReports;
