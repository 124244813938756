import axios from "axios";

// const prodReportUrl = "https://server.workforce-daily-report.com:9000/api/reports"
const produrl = "https://server.workforce-daily-report.com";
const devUrl = "http://localhost:9000";

const URL = process.env.NODE_ENV === "development" ? devUrl : produrl;

const instance = axios.create({
  baseURL: URL,
});

export const getReportNo = async () => {
  try {
    let reportNo = await instance.get("/reports/report-count");
    return reportNo.data;
  } catch (error) {
    return error;
  }
};

export const getChecklistReportNo = async () => {
  try {
    let checklistNo = await instance.get("/reports/checklistcount");
    return checklistNo;
  } catch (error) {
    return error;
  }
};
