import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from "@apollo/client";
import Cookies from "js-cookie";

const URI = "http://localhost:9000/graphql";
const prodURI = "https://server.workforce-daily-report.com/graphql";

const URL = process.env.NODE_ENV === "development" ? URI : prodURI;

const httpLink = new HttpLink({
  uri: URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const userToken = Cookies.get("userToken");
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${userToken}` || null,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
