import { ForemanData } from "../types";
import { getChecklistReportNo } from "./report";

export const createInitChecklistData = async (data: ForemanData) => {
  if (!(await localStorage.getItem("initChecklistData"))) {
    await localStorage.setItem("initChecklistData", JSON.stringify(data));
    await localStorage.setItem("ChecklistData", JSON.stringify({}));
  }
  const checklistData = await localStorage.getItem("initChecklistData");
  return JSON.parse(checklistData!);
};

export const getInitChecklistData = async () => {
  if (!(await localStorage.getItem("initChecklistData"))) {
    throw new Error("Checklist local data does not exist");
  }
  const checklistData = await localStorage.getItem("initChecklistData");
  return JSON.parse(checklistData!);
};

export const updateChecklistData = async (data: any) => {
  const currentData = await JSON.parse(localStorage.getItem("ChecklistData")!);
  if (!currentData) {
    throw new Error("Checklist does not exist");
  }
  const updatedData = {
    ...currentData,
    ...data,
  };
  await localStorage.setItem("ChecklistData", JSON.stringify(updatedData));
  const updatedChecklist = await localStorage.getItem("ChecklistData");
  return JSON.parse(updatedChecklist!);
};

export const getChecklistData = async () => {
  const checklist = await localStorage.getItem("ChecklistData");
  if (!checklist) {
    throw new Error("Checklist does not exist");
  }
  return JSON.parse(checklist);
};

export const updateChecklistDate = async (date: Date) => {
  const currentData = await JSON.parse(localStorage.getItem("ChecklistData")!);
  if (!currentData) {
    throw new Error("Checklist does not exist");
  }
  const updatedData = {
    ...currentData,
    date,
  };
  await localStorage.setItem("ChecklistData", JSON.stringify(updatedData));
  const updatedChecklist = await localStorage.getItem("ChecklistData");
  return JSON.parse(updatedChecklist!);
};

export const updateChecklistDatePrepared = async (datePrepared: Date) => {
  const currentData = await JSON.parse(localStorage.getItem("ChecklistData")!);
  if (!currentData) {
    throw new Error("Checklist does not exist");
  }
  const updatedData = {
    ...currentData,
    datePrepared,
  };
  await localStorage.setItem("ChecklistData", JSON.stringify(updatedData));
  const updatedChecklist = await localStorage.getItem("ChecklistData");
  return JSON.parse(updatedChecklist!);
};

export const updateChecklistAdditionalRemarks = async (additionalRemarks: string) => {
  const currentData = await JSON.parse(localStorage.getItem("ChecklistData")!);
  if (!currentData) {
    throw new Error("Checklist does not exist");
  }

  const updatedData = {
    ...currentData,
    additionalRemarks,
  };
  await localStorage.setItem("ChecklistData", JSON.stringify(updatedData));
  const updatedChecklist = await localStorage.getItem("ChecklistData");
  return JSON.parse(updatedChecklist!);
};
