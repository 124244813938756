import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import { getShiftData, updateCostCode, getFocus, createVerifiedWorker, setVerifiedWorkerSignout, getVerifiedWorker } from "../../api/si";
import { getCostCodes } from "../../graphql/queries";

import "./WorkersList.scss";

type employee = {
  id: string;
  firstName: string;
  lastName: string;
  last4SSN: string;
  class: string;
  costcode: string;
};

interface WorkerListProps {
  employees: [employee];
  setTimeIn: (firstName: string, lastName: string, id: string, type: string, renderTimeModal: boolean) => void;
  setTimeOut: (firstName: string, lastName: string, id: string, type: string, renderTimeModal: boolean) => void;
  timesheetData: (id: string) => { timeIn?: string; timeOut?: string };
  notesModal: (firstName: string, lastName: string, id: string, renderNotesModal: boolean, notesValue?: string | number | undefined) => void;
  notesValue?: string;
}

const WorkersList: React.FC<WorkerListProps> = ({ employees, setTimeIn, setTimeOut, timesheetData, notesModal }) => {
  const [costCode, setCostCode] = useState<string>("");
  const [laborCode, setLaborCode] = useState<string>("");
  const [costCodes, setCostCodes] = useState<any>([]);
  const [_employees, setEmployees] = useState<any>([]);
  const [shiftData, setShiftData] = useState<any>([]);
  const [shouldVerify, setShouldVerify] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchCostCodes = async () => {
    const data = await getCostCodes();
    setCostCodes(data?.costCodes);
  };

  const fetchShiftData = async () => {
    const focus = await getFocus();
    const data = await getShiftData(focus);
    if (data?.shouldVerifyId !== null || data?.shouldVerifyId !== undefined) {
      setShouldVerify(data?.shouldVerifyId);
    }
    setShiftData(data);
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchCostCodes();
    }

    setEmployees(employees);
    fetchShiftData();
  }, [employees, navigator.onLine && getCostCodes]);

  const isWorkerVerified = (id: any) => {
    const data = getVerifiedWorker(id);
    // console.log("IS VERIFIED DATA: ", data?.verified);
    if (!data?.hasOwnProperty("verified")) return false;
    return data.verified;
  };

  return (
    <div className="workers">
      <ul className="workers-list">
        {employees?.map((employee: any) => {
          return (
            <li className="workers-list-item" key={employee?._id}>
              <select
                className="workers-list-item-costcode"
                value={employee?.costcode !== null ? employee?.costcode : "Cost Code"}
                onChange={async e => {
                  const costAndLabor = costCodes.find((c: any) => c.costCode === e.target.value);
                  setLaborCode(costAndLabor.laborCode);
                  setCostCode(e.target.value);
                  const data = await updateCostCode(await getFocus(), employee?._id, costCode, laborCode);
                  setEmployees(data.employees);
                }}
                disabled={!navigator.onLine}
                onBlur={async () => {
                  const data = await updateCostCode(await getFocus(), employee?._id, costCode, laborCode);
                  setEmployees(data.employees);
                  window.location.reload();
                }}
              >
                <option className="workers-list-item-costcode-defaultoption" value="">
                  Cost
                </option>
                {costCodes?.length > 0 &&
                  costCodes.map((code: any, idx: number) => {
                    return (
                      <option className={`workers-list-item-costcode-option costcodeoption${idx}`} style={{ width: "10%" }} key={idx} value={code.costCode}>
                        <p style={{ width: "200px" }}>{code.costCode}</p> | <p>{code.description}</p>
                      </option>
                    );
                  })}
              </select>

              {/* <Dropdown
                className="workers-list-item-costcode"
                placeholder="Cost Codes"
                value={employee?.costcode !== null ? employee?.costcode : "Cost Code"}
                onChange={(e) => {
                  setCostCode(e.currentTarget.innerText.replace(/\D/g, ''))
                }}
                onBlur={async () => {
                  await updateCostCode(await getFocus(), employee?._id, costCode)
                  window.location.reload();
                }}
                scrolling
                options={
                  costCodes?.length > 0 && costCodes.map((code: any, idx: number) => {
                    return {
                      key: idx,
                      text: `${code.costCode} | ${code.description}`,
                      value: code.costCode
                    }
                  })
                }
              /> */}

              <div className="workers-list-item-name">
                {employee?.firstName} {employee?.lastName} {employee?.middleInitial}
              </div>

              <div className="workers-list-item-last4ssn">{employee?.last4SSN}</div>
              <div className="workers-list-item-class">{employee?.class}</div>
              <div
                className={`workers-list-item-timein ${employee?.timeIn && "employeein"}`}
                onClick={async () => {
                  if (shouldVerify && !isWorkerVerified(employee?._id)) {
                    createVerifiedWorker(employee);
                    navigate("/facial-verification");
                    // return;
                  } else if (shouldVerify && (await isWorkerVerified(employee?._id))) {
                    setTimeIn(employee?.firstName, employee?.lastName, employee?._id, "timeIn", true);
                  } else {
                    setTimeIn(employee?.firstName, employee?.lastName, employee?._id, "timeIn", true);
                  }
                }}
              >
                {employee?.timeIn ? <span className="workers-list-item-timein-time">{`${new Date(employee?.timeIn).toLocaleString()}`}</span> : <span className="workers-list-item-timein-placeholder">Time In</span>}
              </div>
              <div
                className={`workers-list-item-timeout ${employee?.timeOut && "employeeout"}`}
                onClick={async () => {
                  if (!employee?.timeIn) {
                    alert("Error: TimeIn has not been set");
                    return;
                  }
                  if (shouldVerify && isWorkerVerified(employee?._id)) {
                    setVerifiedWorkerSignout(employee?._id);
                    navigate("/facial-verification");
                  } else {
                    setTimeOut(employee?.firstName, employee?.lastName, employee?._id, "timeOut", true);
                  }
                }}
              >
                {employee?.timeOut ? <span className="workers-list-item-timeout-time">{`${new Date(employee?.timeOut).toLocaleString()}`}</span> : <span className="workers-list-item-timeout-placeholder">Time Out</span>}
              </div>
              <div
                className={`workers-list-item-notes ${employee?.notes && "employeenotes"}`}
                onClick={() => {
                  notesModal(employee?.firstName, employee?.lastName, employee?._id, true, employee?.notes);
                }}
              >
                Notes
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WorkersList;
