import React, { useState, useEffect } from "react";
import { getTimesheets, getCostCodes } from "../../../graphql/queries";
import { ApolloQueryResult } from "@apollo/client";
import numeral from "numeral";
import DatePicker from "react-datepicker";

import "./JobCostingReport.scss";

interface jobCostingReportProps {
  reportJobId: string;
}

const JobCostingReport: React.FC<jobCostingReportProps> = ({ reportJobId }) => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [timesheets, setTimesheets] = useState<any[]>([]);
  const [wagesTotals, setWagesTotals] = useState<number>(0);
  const [laborCodeChunks, setLaborCodeChunks] = useState<any[]>([]);
  const [laborCodeTotals, setLaborCodeTotals] = useState<any[]>([]);
  const [timeBreakdown, setTimeBreakdown] = useState<any[]>([]);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [lcDates, setLcDates] = useState<any[]>([]);
  const [job, setJob] = useState<{
    jobId: string;
    jobName: string;
    reportDate: string;
    laborSubtotal?: number;
  }>({
    jobId: "",
    jobName: "",
    reportDate: new Date().toString(),
    laborSubtotal: 0,
  });

  const fetchTimesheets = async () => {
    const ccList = await getCostCodes();
    setCostCodes([...ccList.costCodes]);
    const tsList: ApolloQueryResult<any> = await getTimesheets();
    if (tsList.data) {
      setTimesheets(tsList.data.timesheets);
      let listArr = [...tsList.data.timesheets.filter((t: any) => t.jobId === reportJobId)];
      let tempCC = [];
      let interArr: any = [];
      let wagesTotals: any = [];
      let wagesOTTotals: any = [];
      listArr.forEach((t: any) => {
        t.reportHours.forEach((r: any) => {
          interArr.push({ ...r, date: new Date(t.reportDate) });
          wagesTotals.push(r.wagesTotalRT);
          wagesTotals.push(r.wagesTotalOT);
        });
        setTimeBreakdown([...timeBreakdown, ...interArr]);
      });

      setWagesTotals(parseFloat(wagesTotals.reduce((a: any, c: any) => a + c).toFixed(2)));
      console.log("WAGE TOTALS: ", wagesTotals.reduce((a: any, c: any) => a + c).toFixed(2));
    }

    const sheets = tsList.data.timesheets;
    const d = new Date(sheets[0].reportDate).toString();
    d.split(" ");
    setJob({
      jobId: sheets[0].jobId,
      jobName: sheets[0].jobName,
      reportDate: d.split(" ").slice(0, 4).join(" "),
    });
  };

  const getLcChunks = () => {
    const tb = timeBreakdown.sort((a: any, b: any) => parseInt(a.laborCode) - parseInt(b.laborCode)).map((l: any) => l);
    let temp: any = [];
    let chunks: any = [];
    let begin = 0;
    let end = 0;
    for (let i = 0; i < tb.length; i++) {
      temp = [...temp, { lc: tb[i].laborCode, tb: tb[i] }];
    }
    console.log("TEMP: ", chunks, begin);
    setLaborCodeChunks(temp);
  };

  const workWeekGenerator = (sd: any) => {
    const dt = new Date();
    const currD = new Date(sd);
    let startDate = new Date(sd);
    startDate.setDate(startDate.getDate() - 6);
    const filteredDate = timeBreakdown.filter((d: any) => new Date(d.date).getDate() >= startDate.getDate() && new Date(d.date).getDate() <= currD.getDate());
    const ccListArr = [...costCodes];
    const tempCC = [];
    for (let i of filteredDate) {
      for (let j of ccListArr) {
        if (i.laborCode === j.laborCode) {
          i.description = j.description;
          i.budget = j.budget;
        }
      }
    }
    const laborCodeSum = filteredDate.filter((c: any) => parseInt(c.lc) === 11);
    let workDatesArr: any[] = [];
    setLcDates([]);
    setLcDates(filteredDate);
    getLCTotals();

    console.log("FILTERED DATE WORK WEEK GEN: ", filteredDate);
    const workDayChunks = filteredDate.reduce((agg: any, wd) => {
      const wdDate = new Date(wd?.date);
      const wdDay = wdDate.getDate();
      if (!agg[wdDay]) {
        agg[wdDay] = { date: wdDate, arr: [] };
      }
      agg[wdDay].arr.push(wd);
      return agg;
    }, {});

    let workWeekChunksArr: any[] = [];
    let lcChunksArr: any[] = [];
    for (let c in workDayChunks) {
      workWeekChunksArr.push(workDayChunks[c]);
    }
    workWeekChunksArr.forEach((a: any) => {
      a.arr.forEach((el: any) => {
        el.total = el.wagesTotalRT + el.wagesTotalOT;
        el.totalHours = el.regHours + el.otHours;
      });
      lcChunksArr = a.arr.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) a.push(b.laborCode);
        return a;
      }, []);
    });

    let newChunks = [...new Set(lcChunksArr)];
    let newArr: any[] = [];
    let newLCArr: any[] = [];
    for (let i = 0; i < workWeekChunksArr.length; i++) {
      newChunks.forEach((el: any) => {
        let temp = workWeekChunksArr[i].arr.filter((c: any) => c.laborCode === el);
        newArr.push({
          date: workWeekChunksArr[i].date,
          laborCode: el,

          totalHours: parseFloat(temp?.reduce((a: any, b: any) => a + b?.totalHours, 0)),
          total: parseFloat(temp?.reduce((a: any, b: any) => a + b.total, 0).toFixed(2)),
        });
      });
    }
    newChunks.forEach((el: any) => {
      let fa = newArr.filter((e: any) => e.laborCode === el);
      newLCArr.push({
        dateEnding: selectedDate,
        laborCode: el,
        totalHours: fa.reduce((a: any, b: any) => a + b.totalHours, 0),
        total: fa.reduce((a: any, b: any) => a + b.total, 0),
      });
    });
  };

  useEffect(() => {
    fetchTimesheets();
  }, [reportJobId]);

  useEffect(() => {
    if (timesheets.length > 0) {
      getLcChunks();
    }
  }, [timeBreakdown]);

  // console.log('LABOR BREAKDOWN CHUNKS: ', laborCodeChunks,)

  const laborTotals = () => {
    if (laborCodeTotals.length > 0) {
      const totals = laborCodeTotals?.reduce((acc: any, val: any) => {
        return acc + val.sum;
      }, 0);
      const hours = laborCodeTotals?.reduce((acc: any, val: any) => {
        return acc + val.hours;
      }, 0);
      return { totals, hours };
    }
  };

  const getLCTotals = () => {
    let temp: any[] = [];
    let reduceArr: any[] = [];
    let chunkArr: any[] = [];
    let count = 0;
    let start, end;
    let lc2Arr: any[] = [...lcDates];

    for (let i = 0; i < lc2Arr.length; i++) {
      if (i > 0) {
        if (parseInt(lc2Arr[i].laborCode) !== parseInt(lc2Arr[i - 1].laborCode)) {
          start = 0;
          end = i;
          temp.push(lc2Arr[i - 1].laborCode);
          count++;
        }
      }
      if (i < lc2Arr.length - 1) {
        if (parseInt(lc2Arr[i].laborCode) !== parseInt(lc2Arr[i + 1].laborCode)) {
          start = 0;
          end = i;
          temp.push(lc2Arr[i + 1].laborCode);
          count++;
        }
      }
    }
    console.log("TEMP: ", temp);
    reduceArr = temp.reduce((a: any, b: any) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    console.log("REDUCE ARR: ", reduceArr);
    for (let el of reduceArr.sort()) {
      chunkArr.push(lcDates.filter((c: any) => c.laborCode === el));
    }
    console.log("CHUNK ARR: ", chunkArr);
    let totals: any[] = [];
    let sum = 0;
    let hours = 0;
    let idx = 0;
    let lc = "";
    let description = "";
    let cc = "";
    let date: Date;
    let budget = 0;
    // chunkArr.forEach((c: any) => {
    //   console.log('CHUNK ARR "C": ', c);
    //   for (let d = 0; d < c.length; d++) {
    //     sum = (c[d].total)
    //     hours += (c[d].regHours + c[d].otHours)
    //     description = c[d].description
    //     date = c[d].date
    //     cc = c[d].costCode
    //   }
    //   lc = reduceArr[idx]
    //   totals.push({ date, lc, cc, description, hours, sum: parseFloat(sum.toFixed(2)) });
    //   idx++;
    // })
    let temp2: any = [];
    for (let i = 0; i < chunkArr.length; i++) {
      let desc = "",
        date = "",
        cc = "";

      for (let j = 0; j < chunkArr[i].length; j++) {
        chunkArr[i][j].totalHours = chunkArr[i][j].regHours + chunkArr[i][j].otHours;
        desc = chunkArr[i][0].description;
        date = chunkArr[i][0].date;
        cc = chunkArr[i][0].costCode;
        budget = chunkArr[i][0].budget || 0;
      }
      let sum = chunkArr[i].reduce((acc: any, val: any) => acc + val.total, 0);
      let hours = chunkArr[i].reduce((acc: any, val: any) => acc + val.totalHours, 0);
      let lc = reduceArr[i];
      let diff = budget - sum;

      temp2.push({ budget, lc, cc, hours, sum, desc, date, diff });
    }

    console.log("TOTALS: ", temp2);
    setLaborCodeTotals(temp2);
    return totals;
  };

  const laborBreakdownTable_ = () => {
    getLcChunks();
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Employee</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">FM</th>
            <th className="laborbreakdown-table-head-toprow-col">JM</th>
            <th className="laborbreakdown-table-head-toprow-col">AP</th>
            <th className="laborbreakdown-table-head-toprow-col">Total HRS.</th>
            <th className="laborbreakdown-table-head-toprow-col">FM Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">BTJ Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">AP Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Sub-Total</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages O&P</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Total</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {timeBreakdown
            .sort((a: any, b: any) => a.date - b.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.regRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.regWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPRT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalRT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                  <tr key={idx + 0.5}>
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.otRate.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.otWages.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPOT.toFixed(2)).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalOT.toFixed(2)).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const laborBreakdownTable = () => {
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            {/* <th className='laborbreakdown-table-head-toprow-col'>
              WE
            </th > */}
            <th className="laborbreakdown-table-head-toprow-col">Budget</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor Code</th>
            <th className="laborbreakdown-table-head-toprow-col">Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">Description</th>
            <th className="laborbreakdown-table-head-toprow-col">Hours To Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Cost To Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Difference</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {laborCodeTotals
            .sort((a: any, b: any) => a.date - a.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className={`laborbreakdown-table-body-row ${idx % 2 === 0 ? "roweven" : "rowodd"}`}>
                    {/* <td className='laborbreakdown-table-body-row-col'>
                    {new Date(selectedDate).toDateString()}
                  </td> */}
                    <td className="laborbreakdown-table-body-row-col">
                      {/* {numeral(ts.regWages + ts.otWages).format('$0,0.00')} */}
                      {numeral(ts.budget).format("$0,0.00")}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.lc}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.cc}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.desc}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {/* {parseFloat(ts.regHours) + parseFloat(ts.otHours)} */}
                      {ts.hours}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">
                      {/* {numeral(ts.regWages + ts.otWages).format('$0,0.00')} */}
                      {numeral(ts.sum).format("$0,0.00")}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.diff).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const dateGenerator = () => {
    const d = new Date(),
      year = d.getFullYear(),
      mondays: any = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all other Mondays in the month
    while (d.getFullYear() === year) {
      const pushDate = new Date(d.getTime());
      mondays.push(new Date(pushDate.getMonth() + 1 + "-" + pushDate.getDate() + "-" + pushDate.getFullYear()));
      d.setDate(d.getDate() + 7);
    }
    // console.log('MONDAYS: ', mondays);
    return mondays;
  };

  useEffect(() => {
    if (laborCodeChunks.length > 0) {
      getLCTotals();
    }
  }, [laborCodeChunks]);

  const openReportInNewTab = (uri: any) => {
    localStorage.setItem("reportJobId", reportJobId);
    localStorage.setItem("selectedDate", selectedDate);
    localStorage.setItem("laborCodeTotals", JSON.stringify(laborCodeTotals));
    window.open(uri, "_blank", "noreferrer");
  };

  console.log("LABOR CODE TOTALS: ", laborCodeTotals);

  return (
    <div className="laborbreakdown">
      <div className="laborbreakdown-datePicker">
        <DatePicker
          wrapperClassName="laborbreakdown-datePicker-date"
          selected={new Date()}
          includeDates={dateGenerator()}
          onChange={(e: any) => {
            console.log("DATE PICKER: ", e[0]);
            setSelectedDate(new Date(e[0]).toDateString());
            workWeekGenerator(e[0]);
          }}
          selectsRange
          inline
        />
      </div>
      <div className="laborbreakdown-printcontainer">
        <button
          className="laborbreakdown-printcontainer-btn"
          onClick={() => {
            openReportInNewTab("/job-costing-report-print");
          }}
        >
          Prepare To Print
        </button>
      </div>
      <div className="laborbreakdown-headercontainer">
        <div>
          <h2 className="laborbreakdown-headercontainer-title">Bissetta & List Job Costing Report WE {selectedDate.toString()}</h2>
        </div>
      </div>
      {laborBreakdownTable()}
      <div>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <label htmlFor="laborHours">Total Hours</label>
          <p id="laborHours" style={{ marginLeft: "10px" }}>
            {laborTotals()?.hours}
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <label htmlFor="laborTotal">Total Cost</label>
          <p id="laborTotal" style={{ marginLeft: "10px" }}>
            {numeral(laborTotals()?.totals).format("$0,0.00")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobCostingReport;
