import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Navigate, Link } from "react-router-dom";
import { WorkReports } from "./WorkReports";
import AdminMenu from "../AdminMenu";

import { getUser } from "../../../api/auth";
import { getWorkreportList, getWorkreport, createWREmailTemplate, updateSigninSignoutWr } from "../../../graphql/queries";

import "./index.scss";
import WorkReportsAdminEdit from "./WorkReportsAdminEdit";

const devUrl = "http://localhost:3000";
const prodUrl = "http://workforce-daily-report.com";

const reportUrl = process.env.NODE_ENV === "development" ? devUrl : prodUrl;

export default () => {
  const [workreportList, setWorkreportList] = useState<any | null>(null);
  const [workReport, setWorkReport] = useState<any | null>(null);
  const [editSelected, setEditSelected] = useState<boolean>(false);
  const [selectedWR, setSelectedWR] = useState<any | null>(null);
  const [saveUpdatedReport, setSaveUpdatedReport] = useState<boolean>(false);

  const [updateSIWR, { data, loading, error }] = useMutation(updateSigninSignoutWr);
  const fetchWorkreportList = async () => {
    const wrData = await getWorkreportList();
    if (wrData) {
      setWorkreportList(wrData?.workReportList);
    }
  };

  const openReportInNewTab = (uri: any) => {
    window.open(uri, "_blank")?.focus();
  };

  useEffect(() => {
    fetchWorkreportList();
  }, []);

  const handleCloseRecall = async () => {
    if (!selectedWR) {
      alert("You must select a Work Report first");
    }
    await updateSIWR({
      variables: {
        input: {
          id: selectedWR.id,
          canRecall: false,
        },
      },
    });
    alert("Work Report Successfully Closed For Foreman Editing");
    window.location.reload();
  };

  const getDay = (date: Date) => {
    const theDate = new Date(date);
    return theDate.getDay();
  };

  const renderWRList = () => {
    return (
      <div className="wr-container-reports">
        <h4 className="wr-container-reports-title">Reports</h4>
        <ul className="wr-container-reports-list">
          {workreportList?.map((wr: any, idx: number) => {
            return (
              <li
                className={`wr-container-reports-list-item ${wr?.id === selectedWR?.id ? "itemselected" : ""}`}
                key={wr.id}
                onClick={() => {
                  setSelectedWR(wr);
                }}
              >
                <div className="wr-container-reports-list-item-container">
                  <p className="wr-container-reports-list-item-container-title">{wr.jobName}</p>
                  <p className="wr-container-reports-list-item-container-date">Submitted on {new Date(wr.taskCompletionDate).toDateString()}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="wr">
      <AdminMenu />
      <div className="wr-btngrp">
        <button className="wr-btngrp-recallbtn" onClick={handleCloseRecall}>
          Close Recall
        </button>

        <button
          className="wr-btngrp-editselectedlbtn"
          onClick={() => {
            setEditSelected(!editSelected);
          }}
        >
          {editSelected ? "Cancel" : "Edit Selected"}
        </button>

        {editSelected && (
          <button
            className="wr-btngrp-saveeditlbtn"
            onClick={() => {
              setSaveUpdatedReport(true);
            }}
          >
            Save Selected
          </button>
        )}
        <button className="wr-btngrp-printbtn" role="link" onClick={() => openReportInNewTab(`${reportUrl}/workreport-to-print`)}>
          Prepare For Printing
        </button>
      </div>

      <div className="wr-container">
        {renderWRList()}
        {editSelected ? <WorkReportsAdminEdit report={selectedWR} save={saveUpdatedReport} /> : <WorkReports report={selectedWR} />}
      </div>
    </div>
  );
};
