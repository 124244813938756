import Cookies from "js-cookie";
import React, { useState } from "react";
import { decodeToken } from "react-jwt";
import { Navigate } from "react-router-dom";

import { getUser, setUser, userLogin, confirmUser } from "../../../api/auth";

import "./UserLogin.scss";

const UserLogin: React.FC = () => {
  const [credentials, setCredentials] = useState<{ username: string; password: string } | any | null>(null);
  const [userCookies, setUserCookies] = useState<any | null>(null);
  const [activeUser, setActiveUser] = useState<boolean>(true);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [`${e.target.name}`]: e.target.value });
  };

  const resendCodes = async () => {
    const res = confirmUser(credentials?.username);
    return res;
  };

  const handleLoginSubmit = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const res: { ok: boolean; type: string } | any = await userLogin(credentials?.username, credentials?.password);
      if (res?.userToken && res?.userToken !== undefined) {
        const decoded: { userId: string; role: string; level: number } = decodeToken(res.userToken)!;
        setUser(decoded.userId, decoded.role, decoded.level);
        alert("You have successfully been logged in...");
        setCredentials(null);
        window.location.reload();
      } else if (res.name === "PreconditionFailedException") {
        setActiveUser(false);
        resendCodes();
      } else {
        alert("There was a problem logging you in.  Please check your credentials");
        setCredentials(null);
      }
    } catch (err) {
      console.log("LOGIN SUBMIT ERR FROM COMPONENT: ", err);
      -alert("Error logging in");
    }
  };

  const renderCheckEmail = () => {
    return (
      <div>
        <h1>Your Email Has Not Been Confirmed Yet.</h1>
        <p>
          An email containing a 6 digit code has been sent to{" "}
          <strong>
            <span>{credentials?.username}</span>
          </strong>
          .
        </p>
        <p>Please check your email - make sure it isn't in your junk mail. Then follow the click the link to enter the 6 digit confirmation code.</p>
      </div>
    );
  };

  const renderLoginForm = () => (
    <form>
      <div className="userlogin-input-group">
        <label className="userlogin-input-group-label" htmlFor="username">
          Username
        </label>
        <input
          className="userlogin-input-group-input"
          type="text"
          id="username"
          name="username"
          onChange={e => {
            handleInputChange(e);
          }}
          value={credentials ? credentials?.username : ""}
        />
      </div>

      <div className="userlogin-input-group">
        <label className="userlogin-input-group-label" htmlFor="password">
          Password
        </label>
        <input
          className="userlogin-input-group-input"
          type="password"
          id="password"
          name="password"
          onChange={e => {
            handleInputChange(e);
          }}
          value={credentials ? credentials?.password : ""}
        />
      </div>
      <input
        className="userlogin-submit"
        type="submit"
        value="Login"
        onClick={e => {
          handleLoginSubmit(e);
        }}
      />
      <div className="userlogin-forgotcredentials">
        <input className="userlogin-forgotcredentials-btn" type="button" value="Forgot Password?" />
      </div>
    </form>
  );

  if (getUser() && getUser().role === "ADMIN") {
    return <Navigate to="/jobs" replace={true} />;
  } else if (getUser() && getUser().role === "FIELD") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="userlogin">
      <div className="userlogin-header">
        <h1 className="userlogin-header-title">Workforce Daily Report</h1>
        <h2 className="userlogin-header-title2">Login</h2>
      </div>
      {activeUser ? renderLoginForm() : renderCheckEmail()}
    </div>
  );
};

export default UserLogin;
