import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { getFocus, getShiftData } from "../../api/si";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PdfViewer.scss";
import Button1 from "../button1/Button1";
import { uploadAttachmentPdf } from "../../api/pdfAttachments";

interface IPdfViewerProps {
  pdfDoc: any;
  showModal: (s: boolean) => void;
}
const PdfViewer: React.FC<IPdfViewerProps> = ({ showModal }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [file, setFile] = useState<any>(null);
  const [fileMetaData, setFileMetaData] = useState<{ name?: string; job?: string; jobName?: string; shift?: number; date?: Date; txt?: string; links?: string[]; createdBy?: string; creatorName?: string } | null>();
  const [shiftData, setShiftData] = useState<any>(null);
  const [focus, setFocus] = useState<any>(null);

  const getWorksiteData = async () => {
    const focusData = await getFocus();
    const siteData = await getShiftData(focusData);
    if (siteData) {
      setFocus(focusData);
      setShiftData(siteData);
      setFileMetaData({
        job: siteData?.id,
        jobName: siteData?.jobName,
        shift: siteData?.shift,
        date: new Date(),
        createdBy: siteData?.siteEmployees.foreman._id,
        creatorName: `${siteData?.siteEmployees.foreman.firstName} ${siteData?.siteEmployees.foreman.lastName} `,
      });
    }
  };

  const handleSavePdf = async () => {
    const data: any = { ...fileMetaData };
    data.file = file;

    await uploadAttachmentPdf(data);
    // if (res?.statusText === "Created") {
    //   alert(`Your file ${fileMetaData?.name && fileMetaData?.name} has successfully been attached.`);
    //   setFile(null);
    //   setFileMetaData(null);
    // } else {
    //   alert(`Error attaching your file ${fileMetaData?.name && fileMetaData?.name}.`);
    // }
    alert(`Your file ${fileMetaData?.name && fileMetaData?.name} has successfully been attached.`);
    setFile(null);
    setFileMetaData(null);
    // console.log("UPLOAD PDF RESPONSE: ", res);
  };

  // console.log("FILE ATTACHMENT: ,", file?.name);

  useEffect(() => {
    getWorksiteData();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  console.log("IS ONLINE? ", navigator.onLine, "FILE: ", file);

  return (
    <div className="pdfviewer">
      <div className="pdfviewer-topbtngrp">
        <FontAwesomeIcon
          className="pdfviewer-topbtngrp-closeicon"
          icon={faX}
          onClick={async () => {
            showModal(false);
          }}
        />
      </div>
      <form
        className="pdfviewer-form"
        onSubmit={e => {
          e.preventDefault();
          if (file) {
            handleSavePdf();
          } else {
            alert("There are no files attached.");
          }
        }}
      >
        <div className="pdfviewer-form-attachment">
          <label className="pdfviewer-form-attachment-label" htmlFor="pdfAttachment">
            Attachment
          </label>
          <input
            className="pdfviewer-form-attachment-input"
            id="pdfAttachment"
            type="file"
            accept=".pdf"
            placeholder="Add PDF here"
            onChange={e => {
              if (e?.target?.files) {
                setFile(e?.target?.files[0]);
              }
            }}
          />
        </div>
        <div className="pdfviewer-form-name">
          <label className="pdfviewer-form-name-label" htmlFor="pdfAttachment">
            Name (Optional)
          </label>
          <input
            className="pdfviewer-form-name-input"
            id="pdfAttachment"
            type="text"
            placeholder="Add Name Here"
            value={fileMetaData?.name ? fileMetaData?.name : ""}
            onChange={e => {
              if (file) {
                setFileMetaData({
                  ...fileMetaData,
                  name: e.target.value,
                });
              } else {
                alert("Choose a PDF before adding a name.");
              }
            }}
          />
        </div>

        <div className="pdfviewer-form-notes">
          <label className="pdfviewer-form-notes-label" htmlFor="pdfAttachment">
            Notes (Optional)
          </label>
          <textarea
            className="pdfviewer-form-notes-input"
            id="pdfAttachment"
            placeholder="Add Name Here"
            value={fileMetaData?.txt ? fileMetaData?.txt : ""}
            onChange={e => {
              if (file) {
                setFileMetaData({
                  ...fileMetaData,
                  txt: e.target.value,
                });
              } else {
                alert("Choose a PDF before adding notes.");
              }
            }}
          />
        </div>
        <div className="pdfviewer-form-btngrp">
          <button name="submitBtn" id="submitBtn" type="submit" className="pdfviewer-form-btngrp-submitbtn">
            Save File
          </button>

          <button
            name="submitBtn"
            id="submitBtn"
            type="button"
            className="pdfviewer-form-btngrp-submitbtn"
            onClick={() => {
              showModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      <div className="pdfviewer-document">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, Array(numPages))
            .map((x: any, i: number) => i + 1)
            .map((page: any, idx: number) => (
              <Page key={idx} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
            ))}
        </Document>
        {numPages && (
          <p>
            Page {pageNumber} of {numPages}
          </p>
        )}
      </div>
    </div>
  );
};

export default PdfViewer;
