import React, { useEffect, useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { getUser } from "../../api/auth";

import { createInitData, createJobData, createShift, focusType, getFocus, getShiftData, setFocus, updateEmployeeTimeIn, upateEmployeeTimeOut, updateAgencyRepSignature, updateContractorRepSignature, updateNotesSignature, updatePM, updatePMSignature, updateTimeInSignature, updateTimeOutSignature, updateEmployeeRegularTime } from "../../api/si";
import { getForemanData } from "../../graphql/queries";

const JobsList: React.FC = () => {
  const [showJobsList, setShowJobsList] = useState<boolean>(false);
  const [showJobSearch, setShowJobSearch] = useState<boolean>(true);
  const [jobId, setJobId] = useState<string | null>(null);
  const [worksiteData, setWorksiteData] = useState<any | null>(null);
  const [worker, setWorker] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showSI, setShowSI] = useState<boolean>(false);

  const navigate = useNavigate();

  const focus = async () => {
    const f = await getFocus();
    return f;
  };

  const fetchShiftData = async () => {
    const data = await getShiftData(await focus());
    return data;
  };

  useEffect(() => {
    if (navigator.onLine) {
      getForemanData(getUser()?.userId)
        .then(worker => {
          setWorker({ ...worker });
        })
        .catch(error => {
          console.log({ ERROR: "Error getting foreman data" });
          setError("Cannot Get Foreman Data");
        });
    }
    focus();
  }, []);

  const renderJobSearch = () => {
    return (
      <div className="si-jobsearch">
        <label className="si-jobsearch-label">Job Name</label>
        <button
          className="si-jobsearch-btn"
          onClick={() => {
            setShowJobsList(true);
            setShowJobSearch(false);
          }}
        >
          Select A Job Name
        </button>
      </div>
    );
  };

  const navigateToSI = () => {
    setShowSI(true);
  };

  const renderJobsList = () => {
    if (worker.jobs.length > 0) {
      return (
        <ul className="si-jobslist">
          {worker.jobs
            .filter((_job: any) => _job?.job !== null)
            .map((job: { job: { id: string; jobName: string } }) => {
              return (
                <li
                  key={job?.job?.id}
                  className="si-jobslist-item"
                  onClick={async () => {
                    // setJobId(job.job?.id);
                    // setWorksiteData(job.job);
                    createInitData(job.job);
                    setShowJobsList(false);
                    setShowJobSearch(false);
                    await setFocus({ jobName: job?.job.jobName, shift: "0" });
                    setShowSI(true);
                    navigateToSI();
                  }}
                >
                  <p className="si-jobslist-item-name">{job?.job?.jobName}</p>
                </li>
              );
            })}
        </ul>
      );
    } else {
      return <div>You Have No Jobs</div>;
    }
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }
  console.log("SHOW SI: ", showSI);

  return (
    <div>
      {showJobSearch && renderJobSearch()}
      {showJobsList && renderJobsList()}
      {showSI && <Navigate to={"/si"} replace={true} />}
    </div>
  );
};

export default JobsList;
