import React, { useCallback, useRef, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getUser } from "../../api/auth";
import { getShiftData, getFocus, createVerifiedWorker, setWorkerVerification, getVerifiedWorker, removeVerifiedWorker } from "../../api/si";
import Webcam from "react-webcam";
import { getEmployees } from "../../graphql/queries";
import { getImageId, uploadImageId } from "../../api/images";
import * as faceapi from "face-api.js";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./FacialVerification.scss";
interface IEmployee {
  id: string;
  name: string;
  class: string;
}

interface IVerification {
  noFacesFound?: boolean;
  moreThanOneFace?: boolean;
  matchFound?: string | null;
}

interface IVerificationProps {
  onWorksite?: boolean;
}

const FacialVerification: React.FC = () => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(null);
  const [employeeImg, setEmployeeImg] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [verification, setVerification] = useState<IVerification | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [captureVideo, setCaptureVideo] = useState<boolean>(false);
  const [onWorksite, setOnWorksite] = useState<any>(null);
  const navigate = useNavigate();

  const camRef: any = useRef(null);
  const canvas1: any = useRef(null);
  const canvas2: any = useRef(null);
  const image1: any = useRef(null);
  const image2: any = useRef(null);
  const analysisRef: any = useRef(null);
  const videoHeight = 450;
  const videoWidth = 450;

  const loadModels = async () => {
    //Load face-api models
    try {
      await faceapi.nets.faceExpressionNet.loadFromUri("/models");
      await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
      await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      await faceapi.nets.mtcnn.loadFromUri("/models");
      await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      await faceapi.nets.faceLandmark68TinyNet.loadFromUri("/models");
      await faceapi.nets.ageGenderNet.loadFromUri("/models");
    } catch (e) {
      console.log("LOAD MODELS ERROR: ", e);
    }
  };

  const fetchAllEmployees = async () => {
    const e = await getEmployees();
    if (!e.error) {
      setEmployees(e.data.workers);
    }
  };

  const fetchWorksiteEmployees = async () => {
    const focus = await getFocus();

    if (focus !== undefined) {
      const data = await getShiftData(focus);
      console.log("DATA: ", data);
      if (data?.shouldVerifyId) {
        setOnWorksite(data?.shouldVerifyId);
      } else setOnWorksite(false);
      setEmployees(data?.siteEmployees.employees);
      // console.log("THE DATA: ", data);
    } else {
      fetchAllEmployees();
    }
  };

  console.log("FV ON WORKSITEEMPLOYEES: ", employees);
  console.log("SELECTED EMPLOYEE: ", selectedEmployee);

  const handleSelectedEmployee = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEmployeeImg(null);
    const id = e.target.value;
    const foundEmployee = employees.find((e: any) => e._id === id);
    console.log("FOUND EMPLOYEE: ", foundEmployee);
    const name = `${foundEmployee?.firstName} ${foundEmployee?.lastName}`;
    setSelectedEmployee({
      id: foundEmployee?._id,
      name: foundEmployee?.firstName,
      class: foundEmployee?.class,
    });
    const imageRes: any = await getImageId(id);
    if (imageRes.err) {
      console.log("IMAGE RES ERR: ", imageRes.err);
      return;
    } else if (imageRes.data) {
      setEmployeeImg(imageRes?.data);
    }
  };

  const handleSetVerification = async () => {
    const data = await getVerifiedWorker(selectedEmployee?.id!);
    if (data.sign === "in" && !data.verified) {
      setWorkerVerification(selectedEmployee?.id!);
    } else if (data.sign === "out" && data.verified) {
      removeVerifiedWorker(selectedEmployee?.id!);
    }
  };

  const renderEmployees = () => {
    return (
      <div className="facialverify-employeeinfo-dropdown">
        <label className="facialverify-employeeinfo-dropdown-label" htmlFor="employeeDropdown">
          Employee
        </label>
        <select className="facialverify-employeeinfo-dropdown-selector" name="employeeDropdown" id="employeeDropdown" onChange={e => handleSelectedEmployee(e)}>
          <option selected className="facialverify-employeeinfo-dropdown-selector-options" value="none">
            Select Employee
          </option>
          {employees &&
            employees.map((e: any, idx: number) => (
              <option key={idx} value={e._id}>
                {e.firstName} {e.lastName}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const handleUploadId = async () => {
    try {
      const uploadRes = await uploadImageId({
        img: image!,
        employeeId: selectedEmployee?.id!,
        employeeName: selectedEmployee?.name!,
      });
      console.log("IMAGE ID UPLOAD RES: ", uploadRes);
      setImage(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadModels();
    // if (onWorksite) {
    //   fetchWorksiteEmployees();
    // } else {
    //   fetchAllEmployees();
    // }
    fetchWorksiteEmployees();
    // if (worksiteEmployees) console.log("WORKSITE EMPLOYEES FV: ", worksiteEmployees);
    console.log("tesgting");
  }, []);

  // console.log(employees);

  const capture = useCallback(() => {
    const imageSrc = camRef.current?.getScreenshot();
    setImage(imageSrc);
    setCaptureVideo(false);
    checkMatch();
  }, [camRef]);

  const retake = () => {
    setImage(null);
    setVerification(null);
  };

  const checkMatch = async () => {
    setLoading(true);
    setVerification({
      ...verification,
      matchFound: null,
    });
    let faces = await faceapi.detectAllFaces(image1.current).withFaceLandmarks().withFaceDescriptors().withFaceExpressions().withAgeAndGender();
    faces = faceapi.resizeResults(faces, { height: 200, width: 270 });
    faceapi.draw.drawDetections(canvas1.current, faces);

    switch (faces.length) {
      case 0:
        setVerification({ ...verification, noFacesFound: true });
        setLoading(false);
        break;
      case 1:
        findMatch(faces[0]);
        break;
      default:
        setVerification({ ...verification, moreThanOneFace: true });
        break;
    }
  };

  const findMatch = async (face: any) => {
    let matchScore = 0.63;
    // let secondImg = document.getElementById("second-img");
    let faces = await faceapi.detectAllFaces(image2.current).withFaceLandmarks().withFaceDescriptors();
    let labledFace = new faceapi.LabeledFaceDescriptors("Face", [face.descriptor]);
    let faceMatcher = new faceapi.FaceMatcher(labledFace, matchScore);
    let results = await faces.map(f => {
      return faceMatcher.findBestMatch(f.descriptor);
    });
    if (results.findIndex((i: any) => i._label == "Face") !== -1) {
      let matched = [faces[results.findIndex((i: any) => i._label == "Face")]];
      matched = faceapi.resizeResults(matched, { height: 500, width: 500 });
      faceapi.draw.drawDetections(canvas2.current, matched);
      setVerification({ ...verification, matchFound: "found" });
      setLoading(false);
    } else {
      setVerification({ ...verification, matchFound: "not found" });
      setLoading(false);
    }
  };

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      if (analysisRef && analysisRef.current && analysisRef.current.innerHTML) {
        analysisRef.current.innerHTML = await faceapi.createCanvasFromMedia(camRef.current);
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        faceapi.matchDimensions(analysisRef.current, displaySize);

        const detections = await faceapi.detectAllFaces(camRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        analysisRef && analysisRef.current && analysisRef.current.getContext("2d").clearRect(0, 0, videoWidth, videoHeight);
        analysisRef && analysisRef.current && faceapi.draw.drawDetections(analysisRef.current, resizedDetections);
        analysisRef && analysisRef.current && faceapi.draw.drawFaceLandmarks(analysisRef.current, resizedDetections);
        analysisRef && analysisRef.current && faceapi.draw.drawFaceExpressions(analysisRef.current, resizedDetections);
      }
    }, 100);
  };

  return (
    <div className="facialverify">
      <section className="facialverify-employeeinfo">
        {renderEmployees()}
        <div className="facialverify-employeeinfo-details">
          <div className="facialverify-employeeinfo-details-name">
            <label className="facialverify-employeeinfo-details-name-label">Name: </label>
            <p className="facialverify-employeeinfo-details-name-text">{selectedEmployee?.name}</p>
          </div>
          <div className="facialverify-employeeinfo-details-class">
            <label className="facialverify-employeeinfo-details-class-label">Class: </label>
            <p className="facialverify-employeeinfo-details-class-text">{selectedEmployee?.class}</p>
          </div>
          <div className="facialverify-employeeinfo-details-image">
            <label className="facialverify-employeeinfo-details-image-label">Image ID: </label>
            <div className="facialverify-employeeinfo-details-image-container">
              <div className="facialverify-employeeinfo-details-image-container-wrapper">
                <img ref={image1} className="facialverify-employeeinfo-details-image-container-wrapper-img" src={employeeImg!} alt="Employee Image" />
                {image && <canvas className="facialverify-employeeinfo-details-image-container-wrapper-canvas" ref={canvas1} id="canvas1"></canvas>}
              </div>
            </div>
          </div>
        </div>
      </section>
      <form className="facialverify-image">
        <div className="facialverify-image-group">
          {image ? (
            <div className="facialverify-image-group-container">
              <div className="facialverify-image-group-container-wrapper">
                <img ref={image2} className="facialverify-image-group-container-wrapper-imgviewer" src={image!} alt="Job Camera Image" />
                {image && <canvas className="facialverify-image-group-container-wrapper-canvas" ref={canvas2} id="canvas2" width="460px" height="400px"></canvas>}
              </div>
            </div>
          ) : (
            <>
              <Webcam
                className="facialverify-image-group-camera"
                // itemRef={videoRef}
                onPlay={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                  const isConnected = e.currentTarget.isConnected;
                  isConnected && handleVideoOnPlay();
                }}
                // videoConstraints
                height={450}
                width={450}
                ref={camRef}
              />
              {/* <canvas  ref={analysisRef} style={{ position: "absolute" }} /> */}
            </>
          )}

          {!image ? (
            <button
              className="facialverify-image-group-capturebtn"
              type="button"
              disabled={!selectedEmployee && true}
              onClick={e => {
                if (!employeeImg) {
                  alert("Employee Does Not Have Image ID");
                  return;
                }
                e.preventDefault();
                capture();
              }}
            >
              Capture
            </button>
          ) : (
            <div className="facialverify-image-group-retakegrp">
              <button
                className="facialverify-image-group-retakegrp-retakebtn"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  retake();
                }}
              >
                Retake
              </button>
              <button
                className="facialverify-image-group-retakegrp-savebtn"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  if (verification?.matchFound) {
                    handleSetVerification();
                  }

                  setImage(null);
                  // setSelectedEmployee(null);
                  // setEmployeeImg(null);
                  setVerification(null);
                  navigate("/si");
                }}
              >
                Done
              </button>
            </div>
          )}
          {verification?.matchFound == "found" ? <p className="facialverify-image-group-verified">VERIFIED</p> : verification?.matchFound == "not found" ? <p className="facialverify-image-group-unverified">UNVERIFIED</p> : ""}

          {verification?.noFacesFound ? <p>No faces found in first image. Please upload image with 1 face</p> : ""}

          {verification?.moreThanOneFace ? <p className="facialverify-image-group-unverified">More than One Person In Captured Image</p> : ""}

          {verification?.noFacesFound ? <p className="facialverify-image-group-unverified">Retake Image</p> : ""}

          {loading ? (
            <Backdrop open={loading} style={{ zIndex: 100000, color: "fff" }}>
              <p style={{ color: "#fff", fontSize: 20, fontWeight: 900 }}>Analyzing Images...</p>
              <CircularProgress color="secondary" />
            </Backdrop>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default FacialVerification;
