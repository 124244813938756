import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { faMinus, faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AdminMenu from "../AdminMenu";
import { getJobs, getJobDetails, createJob, updateJob, getAgencies, removeJob } from "../../../graphql/queries";
import { getUser } from "../../../api/auth";
import { IJob } from "../../../types";

import "./Jobs.scss";
import { table } from "console";

const markupTypes: string[] = ["Labor", "Equipment", "Permit"];

interface IMarkup {
  markupType: string;
  percentage: number;
}
type TSelectedMarkup = {
  idx: number;
  markupType: string;
  percentage: number;
};

const Jobs: React.FC = () => {
  const [jobs, setJobs] = useState<any | null>(null);
  const [selectedJob, setSelectedJob] = useState<any | null>(null);
  const [jobsFilter, setJobsFilter] = useState<string>("active");
  const [jobDetailEdit, setJobDetailEdit] = useState<any | null>(null);
  const [shouldEdit, setShouldEdit] = useState<boolean>(false);
  const [shouldCreate, setShouldCreate] = useState<boolean>(false);
  const [editsToSend, setEditsToSend] = useState<any | null>(null);
  const [agencies, setAgencies] = useState<any | null>(null);
  const [shouldVerifyID, setShouldVerifyID] = useState<boolean>(false);
  const [workzones, setWorkzones] = useState<[string] | null>(null);
  const [markups, setMarkups] = useState<IMarkup[]>([
    {
      markupType: "",
      percentage: 0,
    },
  ]);
  const [markupsEdit, setMarkupsEdit] = useState<IMarkup[]>([
    {
      markupType: "",
      percentage: 0,
    },
  ]);

  const [modifyJob, { data: modifyJobData, loading: modifyJobLoading, error: modifyJobError }] = useMutation(updateJob);

  const [addJob, { data: addJobData, loading: addJobLoading, error: addJobError }] = useMutation(createJob);

  const [deleteJob, { data: deleteJobData, loading: deleteJobLoading, error: deleteJobError }] = useMutation(removeJob);

  const fetchJobs = async () => {
    const jobsList = await getJobs();
    setJobs(jobsList);
  };

  const fetchAgencies = async () => {
    const agencyList = await getAgencies();
    setAgencies(agencyList.data.agencies);
  };

  const fetchJobDetails = async (id: string) => {
    const jobDetails = await getJobDetails(id);
    const { job } = jobDetails?.data;
    setSelectedJob(job);

    if (jobDetails?.data?.job.shouldVerifyId !== null || jobDetails?.data?.job.shouldVerifyId !== undefined) {
      setShouldVerifyID(jobDetails?.data?.job.shouldVerifyId);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchAgencies();
  }, []);

  const renderActiveJobsList = () => {
    return (
      <div className="jobs-jobs-jobslist">
        <h4 className="jobs-jobs-jobslist-title">Active Jobs</h4>
        <div className="jobs-jobs-jobslist-wrapper">
          <ul className="jobs-jobs-jobslist-wrapper-ul">
            {jobs?.data?.jobs
              .filter((j: any) => j.archive === false || j.archive === null)
              .map((job: { jobName: string; id: string }) => {
                return (
                  <li
                    key={job?.id}
                    className={`jobs-jobs-jobslist-wrapper-ul-item itemactive ${job.id === selectedJob?.id ? "selecteditemactive" : ""}`}
                    onClick={() => {
                      if (!shouldCreate) {
                        fetchJobDetails(job?.id);
                        if (job?.id !== selectedJob?.id) {
                          setShouldEdit(false);
                        }
                      }
                    }}
                  >
                    {job?.jobName}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  };

  const renderArchivedJobsList = () => {
    return (
      <div className="jobs-jobs-jobslist">
        <h4 className="jobs-jobs-jobslist-title">Archived Jobs</h4>
        <div className="jobs-jobs-jobslist-wrapper">
          <ul className="jobs-jobs-jobslist-wrapper-ul">
            {jobs?.data?.jobs
              .filter((j: any) => j?.archive === true)
              .map((job: { jobName: string; id: string }) => {
                return (
                  <li
                    key={job?.id}
                    className={`jobs-jobs-jobslist-wrapper-ul-item itemactive ${job.id === selectedJob?.id ? "selecteditemactive" : ""}`}
                    onClick={() => {
                      if (!shouldCreate) {
                        fetchJobDetails(job?.id);
                        if (job?.id !== selectedJob?.id) {
                          setShouldEdit(false);
                        }
                      }
                    }}
                  >
                    {job?.jobName}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  };

  const handleSubmitUpdatedJob = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await modifyJob({ variables: { input: { ...editsToSend, id: jobDetailEdit?.id } } });
  };

  const handleCreateJob = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await addJob({ variables: { body: { ...editsToSend } } });
    setEditsToSend(null);
    setSelectedJob(null);
    window.location.reload();
  };

  const handleRemoveJob = async (id: string) => {
    await deleteJob({ variables: { id } });
    alert(`${selectedJob?.jobName} has been successfully removed`);
    window.location.reload();
  };

  const handleAddMarkups = () => {
    if (jobDetailEdit?.markups?.length < 3) {
      const tempMarkups = [...jobDetailEdit.markups];

      const newMarkup: IMarkup = {
        markupType: "",
        percentage: 0,
      };

      tempMarkups.push(newMarkup);

      setJobDetailEdit({
        ...jobDetailEdit,
        markups: tempMarkups,
      });
      setSelectedJob({
        ...jobDetailEdit,
        markups: tempMarkups,
      });
    }
  };

  const handleAddWorkzone = () => {
    const j = [...selectedJob?.workzones];
    const blank = "";
    j.push(blank);

    setJobDetailEdit({
      ...jobDetailEdit,
      workzones: j,
    });
    setSelectedJob({
      ...jobDetailEdit,
      workzones: j,
    });
  };

  const handleRemoveWorkzone = (idx: number) => {
    const tempArr = [...jobDetailEdit?.workzones];
    tempArr.splice(idx, 1);
    setJobDetailEdit({
      ...jobDetailEdit,
      workzones: tempArr,
    });

    setSelectedJob({
      ...jobDetailEdit,
      workzones: tempArr,
    });

    setEditsToSend({
      ...editsToSend,
      workzones: tempArr,
    });
  };

  const handleRemoveMarkup = (idx: number) => {
    const tempArr = [...jobDetailEdit?.markups];
    tempArr.splice(idx, 1);
    setJobDetailEdit({
      ...jobDetailEdit,
      markups: tempArr,
    });

    setSelectedJob({
      ...jobDetailEdit,
      markups: tempArr,
    });

    setEditsToSend({
      ...editsToSend,
      markups: tempArr,
    });
  };

  const renderMarkups = () => {
    return (
      <div className="jobs-jobs-jobdetails-row5-markups">
        <table className="jobs-jobs-jobdetails-row5-markups-table">
          <caption className="jobs-jobs-jobdetails-row5-markups-table-caption">Choose Markup</caption>
          <thead className="jobs-jobs-jobdetails-row5-markups-table-head">
            <tr className="jobs-jobs-jobdetails-row5-markups-table-head-row">
              <th className="jobs-jobs-jobdetails-row5-markups-table-head-row-header" scope="col">
                Type
              </th>
              <th className="jobs-jobs-jobdetails-row5-markups-table-head-row-headerpercent" scope="col">
                %
              </th>
              <th className="jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup" scope="col">
                <div className={`jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup-btn ${!shouldEdit && "addmarkup-disabled"}`} onClick={handleAddMarkups}>
                  <p className="jobs-addmarkup-text">Add Markup</p>
                  <FontAwesomeIcon className="jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup-btn-icon" icon={faPlus} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="jobs-jobs-jobdetails-row5-markups-table-body">
            {selectedJob?.markups.map((markup: { markupType: string; percentage: number }, idx: number) => {
              return (
                <tr className="jobs-jobs-jobdetails-row5-markups-table-body-row" key={idx}>
                  <td className="jobs-jobs-jobdetails-row5-markups-table-body-row-element">
                    <select
                      className="jobs-jobs-jobdetails-row5-markups-table-body-row-element-selector"
                      name="markup"
                      id="markup"
                      value={!shouldEdit ? markup?.markupType : jobDetailEdit?.markups[idx]?.markupType}
                      disabled={!shouldEdit}
                      defaultValue="none"
                      onChange={e => {
                        if (jobDetailEdit.markups.find((el: any) => el.markupType === e.target.value)) {
                          alert(`${e.target.value} has already been selected`);
                          markup.markupType = "";
                        } else {
                          const tempArr = [...jobDetailEdit.markups];
                          let tempMarkup = { ...tempArr[idx] };
                          tempMarkup.markupType = e.target.value;

                          tempArr.splice(idx, 1, tempMarkup);
                          setJobDetailEdit({
                            ...jobDetailEdit,
                            markups: tempArr,
                          });

                          setEditsToSend({
                            ...editsToSend,
                            markups: tempArr,
                          });
                        }
                      }}
                    >
                      <option className="jobs-jobs-jobdetails-row5-markups-table-body-row-element-selector-option" value="none" selected>
                        None Selected
                      </option>
                      {markupTypes.map((markup: string, idx: number) => {
                        return (
                          <option key={idx} value={markup}>
                            {markup}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="jobs-jobs-jobdetails-row5-markups-table-body-row-element">
                    <input
                      name="percentage"
                      className="jobs-jobs-jobdetails-row5-markups-table-body-row-element-input"
                      value={!shouldEdit ? markup.percentage : jobDetailEdit?.markups[idx]?.jobName}
                      disabled={!shouldEdit}
                      type={"number"}
                      onChange={e => {
                        const tempArr = [...jobDetailEdit.markups];
                        let tempMarkup = { ...tempArr[idx] };
                        tempMarkup.percentage = parseFloat(e.target.value);

                        tempArr.splice(idx, 1, tempMarkup);
                        setJobDetailEdit({
                          ...jobDetailEdit,
                          markups: tempArr,
                        });

                        setEditsToSend({
                          ...editsToSend,
                          markups: tempArr,
                        });
                      }}
                    />
                  </td>
                  <td className="jobs-jobs-jobdetails-row5-markups-table-body-row-element">
                    <FontAwesomeIcon
                      className={`jobs-jobs-jobdetails-row5-markups-table-body-row-element-minusicon ${!shouldEdit && "minusicon-disabled"}`}
                      icon={faMinusCircle}
                      onClick={() => {
                        const { markups } = jobDetailEdit;
                        if (markups?.length > 1 && idx === markups.indexOf(markups[idx])) {
                          handleRemoveMarkup(idx);
                        } else if (markups?.length === 1) {
                          setMarkups([{ markupType: "", percentage: 0 }]);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderWorkzones = () => {
    return (
      <div className="jobs-jobs-jobdetails-row5-markups">
        <table className="jobs-jobs-jobdetails-row5-markups-table">
          <caption className="jobs-jobs-jobdetails-row5-markups-table-caption">Add Workzone</caption>
          <thead className="jobs-jobs-jobdetails-row5-markups-table-head">
            <tr className="jobs-jobs-jobdetails-row5-markups-table-head-row">
              <th className="jobs-jobs-jobdetails-row5-markups-table-head-row-header" scope="col">
                Workzone Location
              </th>
              <th className="jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup" scope="col">
                <div className={`jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup-btn ${!shouldEdit && "addmarkup-disabled"}`} onClick={handleAddWorkzone}>
                  <p className="jobs-addmarkup-text">Add Workzone</p>
                  <FontAwesomeIcon className="jobs-jobs-jobdetails-row5-markups-table-head-row-addmarkup-btn-icon" icon={faPlus} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="jobs-jobs-jobdetails-row5-markups-table-body">
            {selectedJob?.workzones?.map((zone: string, idx: number) => {
              return (
                <tr className="jobs-jobs-jobdetails-row5-markups-table-body-row" key={idx}>
                  <td className="jobs-jobs-jobdetails-row5-markups-table-body-row-element">
                    <input
                      className="jobs-jobs-jobdetails-row5-markups-table-body-row-element-selector"
                      type="text"
                      name="workzone"
                      value={zone ? zone : ""}
                      disabled={!shouldEdit}
                      onChange={e => {
                        const updatedZones = selectedJob.workzones.map((z: string, i: number) => {
                          if (i === idx) {
                            let newZone = e.target.value;
                            z = newZone;
                          }
                          return z;
                        });
                        setSelectedJob({
                          ...selectedJob,
                          workzones: updatedZones,
                        });
                        setJobDetailEdit({
                          ...jobDetailEdit,
                          workzones: updatedZones,
                        });
                        setEditsToSend({
                          ...editsToSend,
                          workzones: updatedZones,
                        });
                      }}
                    />
                  </td>
                  <td className="jobs-jobs-jobdetails-row5-markups-table-body-row-element">
                    <FontAwesomeIcon
                      className={`jobs-jobs-jobdetails-row5-markups-table-body-row-element-minusicon ${!shouldEdit && "minusicon-disabled"}`}
                      icon={faMinusCircle}
                      onClick={() => {
                        const { workzones } = selectedJob;
                        if (workzones?.length > 1 && idx === workzones.indexOf(workzones[idx])) {
                          handleRemoveWorkzone(idx);
                        } else if (workzones?.length === 1) {
                          setMarkups([{ markupType: "", percentage: 0 }]);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderJobDetails = () => {
    return (
      <form className="jobs-jobs-jobdetails">
        <h4 className="jobs-jobs-jobdetails-title">Job Details</h4>
        <div className="jobs-jobs-jobdetails-editbtn">
          <button
            className="jobs-jobs-jobdetails-editbtn-btn editbtn-active"
            onClick={() => {
              if (selectedJob) {
                setShouldEdit(!shouldEdit);
                setJobDetailEdit({ ...selectedJob });
                // setEditsToSend({ ...selectedJob });
                setMarkupsEdit(markups);
              }
            }}
            type="button"
            disabled={shouldCreate}
          >
            Edit
          </button>
        </div>
        <div className="jobs-jobs-jobdetails-row1">
          <div className="jobs-jobs-jobdetails-row1-jobname">
            <label className="jobs-jobs-jobdetails-row1-jobname-label">Job Name</label>
            <input
              name="jobName"
              className="jobs-jobs-jobdetails-row1-jobname-input"
              value={!shouldEdit ? selectedJob?.jobName : jobDetailEdit?.jobName}
              placeholder={`${shouldCreate ? "Enter A Job Name" : ""}`}
              disabled={!shouldEdit}
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  jobName: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>
          <div className="jobs-jobs-jobdetails-row1-agencyname">
            <label className="jobs-jobs-jobdetails-row1-agencyname-label">Agency</label>
            <select
              name="agency"
              id="agency"
              className="jobs-jobs-jobdetails-row1-agencyname-input"
              disabled={!shouldEdit ? true : false}
              onChange={e => {
                setEditsToSend({
                  ...editsToSend,
                  agencyId: e.target.value,
                });
              }}
            >
              {shouldCreate ? <option>Select An Agency</option> : <option>{selectedJob?.agency?.agencyName}</option>}

              {agencies &&
                agencies.map((agency: { agencyName: string; id: string }) => {
                  return (
                    <option key={agency?.id} value={agency?.id}>
                      {agency?.agencyName}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="jobs-jobs-jobdetails-row2">
          <div className="jobs-jobs-jobdetails-row2-contractno">
            <label className="jobs-jobs-jobdetails-row2-contractno-label">Contract No</label>
            <input
              name="contractNo"
              className="jobs-jobs-jobdetails-row2-contractno-input"
              value={!shouldEdit ? selectedJob?.contractNo : shouldCreate ? editsToSend?.contractNo : jobDetailEdit?.contractNo}
              placeholder={`${shouldCreate ? "Enter Contract No" : ""}`}
              disabled={!shouldEdit}
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  contractNo: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>
          <div className="jobs-jobs-jobdetails-row2-laborticketabv">
            <label className="jobs-jobs-jobdetails-row2-laborticketabv-label">Labor Ticket</label>
            <input
              name="laborTicketAbv"
              className="jobs-jobs-jobdetails-row2-laborticketabv-input"
              value={!shouldEdit ? selectedJob?.laborTicketAbv : shouldCreate ? editsToSend?.laborTicketAbv : jobDetailEdit?.laborTicketAbv}
              placeholder={`${shouldCreate ? "Enter Labor Ticket" : ""}`}
              disabled={!shouldEdit}
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  laborTicketAbv: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>
          <div className="jobs-jobs-jobdetails-row2-location">
            <label className="jobs-jobs-jobdetails-row2-location-label">Location</label>
            <input
              name="location"
              className="jobs-jobs-jobdetails-row2-location-input"
              value={!shouldEdit ? selectedJob?.location : shouldCreate ? editsToSend?.location : jobDetailEdit?.location}
              disabled={!shouldEdit}
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  location: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
              placeholder={`${shouldCreate ? "Enter Job Location" : ""}`}
            />
          </div>
        </div>
        <div className="jobs-jobs-jobdetails-row3">
          <div className="jobs-jobs-jobdetails-row3-docprefix">
            <label className="jobs-jobs-jobdetails-row3-docprefix-label">Document Prefix</label>
            <input
              name="docPrefix"
              className="jobs-jobs-jobdetails-row3-docprefix-input"
              value={!shouldEdit ? selectedJob?.docPrefix : shouldCreate ? editsToSend?.docprefix : jobDetailEdit?.docPrefix}
              placeholder={`${shouldCreate ? "Enter Document Prefix" : ""}`}
              disabled={!shouldEdit}
              type="text"
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  docPrefix: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>
          <div className="jobs-jobs-jobdetails-row3-workssiteassigned">
            <label className="jobs-jobs-jobdetails-row3-workssiteassigned-label">Assigned Worksite</label>
            <p className="jobs-jobs-jobdetails-row3-workssiteassigned-text">{selectedJob?.siteEmployees?.id ? "YES" : "NO"}</p>
          </div>
        </div>
        <div className="jobs-jobs-jobdetails-row4">
          <div className="jobs-jobs-jobdetails-row4-contractstartdate">
            <label className="jobs-jobs-jobdetails-row4-contractstartdate-label">Contract Start Date</label>
            <input
              name="contractStartDate"
              className="jobs-jobs-jobdetails-row4-contractstartdate-input"
              value={!shouldEdit && selectedJob?.contractStartDate ? new Date(selectedJob?.contractStartDate).toDateString() : shouldEdit ? jobDetailEdit?.contractStartDate : ""}
              disabled={!shouldEdit}
              type={shouldEdit ? "date" : "text"}
              onChange={e => {
                setJobDetailEdit({
                  ...jobDetailEdit,
                  contractStartDate: e.target.value,
                });
                setEditsToSend({
                  ...editsToSend,
                  [`${e.target.name}`]: e.target.value.replace(/-/g, "/").replace(/T.+/, ""),
                });
              }}
            />
          </div>

          <div className="jobs-jobs-jobdetails-row4-verifyid">
            <label className="jobs-jobs-jobdetails-row4-verifyid-label">Verify Facial ID?</label>
            <button
              name="shouldVerifyId"
              className="jobs-jobs-jobdetails-row4-verifyid-input"
              disabled={!shouldEdit}
              type="button"
              onClick={() => {
                setShouldVerifyID(!shouldVerifyID);
                setEditsToSend({
                  ...editsToSend,
                  shouldVerifyId: !shouldVerifyID,
                });
              }}
            >
              {shouldVerifyID ? "True" : "False"}
            </button>
          </div>
        </div>
        <div className="jobs-jobs-jobdetails-row5">
          {renderMarkups()}
          <div className="jobs-jobs-jobdetails-row4-verifyid">
            <label className="jobs-jobs-jobdetails-row4-verifyid-label">Archive?</label>
            <button
              name="shouldVerifyId"
              className="jobs-jobs-jobdetails-row4-verifyid-input"
              disabled={!shouldEdit}
              type="button"
              onClick={() => {
                setSelectedJob({
                  ...selectedJob,
                  archive: !selectedJob?.archive,
                });
                setEditsToSend({
                  ...editsToSend,
                  archive: !selectedJob?.archive,
                });
              }}
            >
              {selectedJob?.archive ? "True" : "False"}
            </button>
          </div>
        </div>

        <div className="jobs-jobs-jobdetails-row6">{renderWorkzones()}</div>

        {shouldEdit && (
          <div className="jobs-jobs-jobdetails-btngroup">
            <button
              className="jobs-jobs-jobdetails-btngroup-savebtn"
              type="submit"
              onClick={async e => {
                if (shouldCreate) {
                  await handleCreateJob(e);
                } else if (!shouldCreate) {
                  handleSubmitUpdatedJob(e);
                  fetchJobDetails(jobDetailEdit?.id);
                  window.location.reload();
                }
                setShouldEdit(false);
              }}
            >
              Save
            </button>

            <button
              className="jobs-jobs-jobdetails-btngroup-savebtn"
              type="button"
              onClick={e => {
                setEditsToSend(null);
                setJobDetailEdit(selectedJob);
                setShouldEdit(false);
                setShouldCreate(false);
                setMarkups([{ markupType: "", percentage: 0 }]);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="jobs">
      <AdminMenu />
      <div className="jobs-costcodesbtn">
        <Link className="jobs-costcodesbtn-link" to="/costcodes">
          Cost Codes
        </Link>
      </div>

      <div className="jobs-filtercontainer">
        <div
          className="jobs-filtercontainer-addjob"
          onClick={() => {
            setEditsToSend(null);
            setShouldCreate(true);
            setShouldEdit(true);
            setSelectedJob(null);
          }}
        >
          <p className="jobs-filtercontainer-addjob-text">Add Job</p>
          <FontAwesomeIcon className="jobs-addjob-button" icon={faPlus} />
        </div>

        <div className="jobs-filtercontainer-filter">
          <label className="jobs-filtercontainer-filter-label" htmlFor="filter">
            Filter Jobs
          </label>
          <select
            className="jobs-filtercontainer-filter-selector"
            name="filter"
            id="filter"
            onChange={e => {
              setJobsFilter(e.target.value);
            }}
          >
            <option id="filter-option" className="jobs-filtercontainer-filter-selector-option" value="active">
              Active
            </option>
            <option className="jobs-filtercontainer-filter-selector-option" value="stashed">
              Archived
            </option>
          </select>
        </div>

        {getUser().level === 1 && (
          <button
            className={`jobs-filtercontainer-removebtn ${selectedJob && "remove-selected"}`}
            type="button"
            onClick={() => {
              handleRemoveJob(selectedJob?.id!);
            }}
          >
            Remove Selected Job
          </button>
        )}
      </div>

      <div className="jobs-jobs">
        <>{jobsFilter === "active" ? renderActiveJobsList() : renderArchivedJobsList()}</>
        <>{renderJobDetails()}</>
      </div>
    </div>
  );
};

export default Jobs;
