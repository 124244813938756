import React, { useState, useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { getJobTimesheet, getJobs, getTimesheets } from '../../../graphql/queries';
import LaborBreakdown from '../laborBreakdown/LaborBreakdown';
import JobCostingWkSht from '../jobCostingWkSht/JobCostingWkSht';
import JobCostingSummary from '../jobCostingSummary/JobCostingSummary';
import JobCostingReport from '../jobCostingReport/JobCostingReport';
import AdminMenu from '../AdminMenu';


export default () => {
  const [jobs, setJobs] = useState<any[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string>('');
  const [selectedLaborType, setSelectedLaborType] = useState<string>('');
  const [timesheets, setTimesheets] = useState<any[]>([]);

  const fetchJobs = async () => {
    const _jobs: ApolloQueryResult<any> = await getJobs();
    if (_jobs) {
      setJobs(_jobs.data.jobs);
    }
  }

  useEffect(() => {
    fetchJobs();
  }, [])

  const laborReportTypes = [
    { value: 'laborBreakdown', name: 'Labor Breakdown' },
    { value: 'jobCostingSummary', name: 'Job Costing Summary' },
    { value: 'jobCostingReport', name: 'Job Costing Report' },
    { value: 'jobCostingWorksheet', name: 'Job Costing Worksheet' }
  ]
  console.log('LABOR TYPES: ', selectedLaborType)
  return (
    <div className='laborreports'>
      <AdminMenu />
      <h2 className='laborreports-title'>Labor Reports</h2>
      <div>
        <div>
          <form
            style={{
              padding: '30px'
            }}
          >
            <div style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }}>
              <div>
                <label htmlFor="jobsPicker">Jobs</label>
                <select
                  name="jobsPickerSelect"
                  id="jobsPicker"
                  onChange={e => {
                    setSelectedJobId(e.target.value)
                    setSelectedLaborType('');
                  }}
                >
                  <option value="">Select A Job</option>
                  {jobs?.map((job: any, idx: number) => {
                    return (
                      <option key={idx} value={job.id}>{job.jobName}</option>
                    )
                  })}
                </select>
              </div>
              <div>
                <label htmlFor="reportTypeSelect">Labor Report Types</label>
                <select
                  name="reportTypeSelect"
                  id="reportTypeSelect"
                  onChange={e => {
                    setSelectedLaborType(e.target.value);
                  }}
                >
                  <option value="default">Select Labor Report Type</option>
                  {laborReportTypes.map((l: any, idx: number) => {
                    return (
                      <option value={l.value}>{l.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            {/* <button
              type='submit'
            >Enter</button> */}
          </form>

          <section>
            <div>
              {
                selectedLaborType === 'laborBreakdown' && <LaborBreakdown reportJobId={selectedJobId} />
              }
              {
                selectedLaborType === 'jobCostingWorksheet' && <JobCostingWkSht reportJobId={selectedJobId} />
              }
              {
                selectedLaborType === 'jobCostingSummary' && <JobCostingSummary reportJobId={selectedJobId} />
              }
              {
                selectedLaborType === 'jobCostingReport' && <JobCostingReport reportJobId={selectedJobId} />
              }
            </div>
          </section>

        </div>
      </div>

    </div>
  )
}