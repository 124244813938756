import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import WeatherIcon from "react-icons-weather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faQuestionCircle, faBars } from "@fortawesome/free-solid-svg-icons";

import Button1 from "../../common/button1/Button1";
import SideMenu from "../sidemenu/SideMenu";

import { getCurrentWeather } from "../../api/weather";
import { getTheDate, getTheTime } from "../../helpers/dateTime";
import { getForemanData } from "../../graphql/queries";
import { setSiteForeman } from "../../api/si";
import { getUser, logout } from "../../api/auth";

import "./NavBar.scss";

const menuItemNames = {
  signinsignout: "SI",
  workreport: "WR",
  inspectionChecklist: "IC",
  reports: "R",
  admin: "ADMIN",
  login: "LOGIN",
  mainMenu: "MENU",
  jobcam: "CAM",
  employeeImageId: "EID",
  facialVerification: "FV",
  jobImages: "JI",
  pdfAttachments: "PDF",
};
const menuItems = [
  { name: menuItemNames.signinsignout, link: "/si" },
  { name: menuItemNames.workreport, link: "/wr" },
  { name: menuItemNames.inspectionChecklist, link: "/ic" },
  { name: menuItemNames.reports, link: "/submitted-reports" },
  { name: menuItemNames.jobcam, link: "/job-cam" },
  { name: menuItemNames.employeeImageId, link: "/eid" },
  { name: menuItemNames.facialVerification, link: "/facial-verification" },
  { name: menuItemNames.jobImages, link: "/job-images" },
  { name: menuItemNames.pdfAttachments, link: "/pdf-attachments" },
];

const adminMenuItems = [{ name: menuItemNames.admin, link: "/jobs" }];

const loggedOutMenuItems = [{ name: menuItemNames.login, link: "/user-login" }];

const NavBar: React.FC = () => {
  const [foreman, setForeman] = useState<any | null>(null);
  const [weather, setWeather] = useState<any | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const location = useLocation();
  const [viewAction, setViewAction] = useState<any>(null);

  const getPath = () => window.location.pathname;
  const navigate = useNavigate();

  const navMenuRef = useRef(null);

  useEffect(() => {
    setUser(getUser());
    if (getUser().role === "FIELD") {
      getForemanData(getUser().userId)
        .then(worker => {
          setForeman(worker);
          setSiteForeman(worker);
        })
        .catch(error => {
          console.log({ ERROR: error.message });
        });
    }
    getCurrentWeather(currentWeather => {
      setWeather(currentWeather);
    });
    getPath();
    setViewAction(JSON.parse(localStorage.getItem("materialImageAction")!));
  }, [getCurrentWeather, getCurrentWeather, window.location.pathname]);

  const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => {
        if (ref.current && !ref.current.contains(event.currentTarget)) {
          setShowMenu(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(navMenuRef);

  const renderMenu = () => {
    if (!user) {
      return (
        <div className={"nav-menu-container"}>
          <FontAwesomeIcon className="nav-menu-container-cancel" icon={faXmark} onClick={() => setShowMenu(false)} />
          <ul className="nav-menu-container-list">
            {loggedOutMenuItems.map((item, idx) => {
              return (
                <li className="nav-menu-container-list-item" key={idx}>
                  <Link to={item.link} onClick={() => setShowMenu(false)}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          {/* <Button1
            btnName="logoutBtn"
            btnClassName="nav-menu-container-logoutBtn"
            btnType="button"
            btnValue="Log Out"
            onclick={() => {
              logout();
              window.location.reload();
            }}
          /> */}
        </div>
      );
    } else if (user && user.role === "ADMIN") {
      return (
        <div className={"nav-menu-container"}>
          <FontAwesomeIcon className="nav-menu-container-cancel" icon={faXmark} onClick={() => setShowMenu(false)} />
          <ul className="nav-menu-container-list">
            {adminMenuItems.map((item, idx) => {
              return (
                <li className="nav-menu-container-list-item" key={idx}>
                  <Link style={{ textDecoration: "none" }} to={item.link} onClick={() => setShowMenu(false)}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <Button1
            btnName="logoutBtn"
            btnClassName="nav-menu-container-logoutBtn"
            btnType="button"
            btnValue="Log Out"
            onclick={() => {
              logout();
              window.location.reload();
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={"nav-menu-container"}>
          <FontAwesomeIcon className="nav-menu-container-cancel" icon={faXmark} onClick={() => setShowMenu(false)} />
          <ul className="nav-menu-container-list">
            {menuItems.map((item, idx) => {
              return (
                <li className="nav-menu-container-list-item" key={idx}>
                  <Link style={{ textDecoration: "none" }} to={item.link} onClick={() => setShowMenu(false)}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <Button1
            btnName="logoutBtn"
            btnClassName="nav-menu-container-logoutBtn"
            btnType="button"
            btnValue="Log Out"
            onclick={() => {
              logout();
              window.location.reload();
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="wrapper">
      {(getPath() !== "/" && getPath() !== "/user-login" && getPath() !== "/jobs" && getPath() !== "/agencies" && getPath() !== "/employees" && getPath() !== "/worksites" && getPath() !== "/workreports" && getPath() !== "/checklistreports" && getPath() !== "/budget" && getPath() !== "/labor-reports" && getPath() !== "/employee-job-rates" && getPath() !== "/costcodes") || getPath() === "/register-user" ? (
        <div
          className="nav-menu"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          {getPath() === "/si" || getPath() === "/jobs-list" ? (
            "SI"
          ) : getPath() === "/wr" ? (
            "WR"
          ) : getPath() === "/ic" ? (
            "IC"
          ) : getPath() === "/submitted-reports" ? (
            "R"
          ) : getPath() === "/checklistreports-pdf" ? (
            "ICR"
          ) : user?.role !== "ADMIN" && getPath() === "/pdf-attachments" ? (
            "PDF"
          ) : user?.role !== "ADMIN" && getPath() === "/job-images" ? (
            "JI"
          ) : (
            <div
              className="nav-logout"
              onClick={() => {
                logout();
                window.location.reload();
              }}
            >
              Log Out
            </div>
          )}
        </div>
      ) : (
        <div
          className="nav-logout"
          onClick={() => {
            logout();
            window.location.reload();
          }}
        >
          Log Out
        </div>
      )}
      <div className="nav">
        {getPath() === "/" && <p className="nav-companyName">Bissetta & List</p>}
        <div className="nav-weather">
          {weather ? (
            <div>
              {weather?.cod !== "400" && (
                <>
                  <WeatherIcon name="owm" iconId={weather?.weather[0]?.id?.toString()} />
                  <span>{Math.floor(weather.main.temp)}</span>
                </>
              )}
            </div>
          ) : (
            <p>No Weather Yet</p>
          )}
        </div>
        <div>
          {getTheDate()} {getTheTime()}
        </div>
        {getUser()?.role === "FIELD" ? (
          <div>
            Foreman: {foreman?.firstName} {foreman?.lastName}
          </div>
        ) : (
          <div>
            {getUser()?.firstName} {getUser()?.lastName}
          </div>
        )}
      </div>
      {showMenu && renderMenu()}
      <FontAwesomeIcon className="nav-menu-container-cancel" icon={faQuestionCircle} onClick={() => console.log("HELP MENU CLICKED...")} />
    </div>
  );
};

export default NavBar;
