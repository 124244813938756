import "core-js/actual";
import "raf/polyfill";
import "whatwg-fetch";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/apollo.config";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "semantic-ui-css/semantic.min.css";
import "./index.scss";

const root = createRoot(document.getElementById("app-root")!);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

serviceWorkerRegistration.unregister();
