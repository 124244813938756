import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom";
import * as XLSX from "xlsx";
import numeral from "numeral";
import { faEdit, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createCostCodes, getCostCodes, updateCostCodes, removeCostCode } from "../../../graphql/queries";
import { getUser } from "../../../api/auth";

import "./XlUpload.scss";
import AdminMenu from "../AdminMenu";

const XlUpload: React.FC = () => {
  const [xlData, setXlData] = useState<any>([]);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [individualCostcode, setIndivudualCostCode] = useState<{ budget: number; laborCode: string; costCode: string; description: string }>({
    budget: 0,
    laborCode: "",
    costCode: "",
    description: "",
  });
  const [editCostCodes, setEditCostCodes] = useState<{ id: string; budget: number; laborCode: string; costCode: string; description: string }>({
    id: "",
    budget: 0,
    laborCode: "",
    costCode: "",
    description: "",
  });
  const [deleteCostCodes, setDeleteCostCodes] = useState<any>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const [submitCostCodes, { data, loading, error }] = useMutation(createCostCodes);
  const [removeSelectedCostCodes] = useMutation(removeCostCode);
  const [editCostcode] = useMutation(updateCostCodes);

  const fetchCostCodes = async () => {
    const data = await getCostCodes();
    setCostCodes(data.costCodes);
  };

  useEffect(() => {
    fetchCostCodes();
  }, []);

  const handleSubmitCostCodes = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (xlData.length === 0) {
      alert("You Have Not Uploaded A Spreadsheet.");
      return;
    }
    if (xlData.length > 0 && !xlData[0]["Cost Code"]) {
      alert("Your Spreadsheet Does Not Contain A Cost Code Column.");
      return;
    } else if (xlData.length > 0 && !xlData[0]["Labor Code"]) {
      alert("Your Spreadsheet Does Not Contain A Labor Code Column.");
      return;
    } else if (xlData.length > 0 && !xlData[0]["Labor Code"]) {
      alert("Your Spreadsheet Does Not Contain A Labor Code Column.");
      return;
    }
    e.preventDefault();
    if (xlData.length > 0) {
      xlData.forEach(async (item: any) => {
        if (item["Cost Code"] === "" || item["Cost Code"] === 0) {
          alert("Cost Code in spreadsheet cannot be 0");
          return;
        } else if (item["Labor Code"] === "" || item["Labor Code"] === 0) {
          alert("Labor Code in spreadsheet cannot be 0");
          return;
        } else if (item["Description"] === "") {
          alert("Description in spreadsheet cannot be blank");
          return;
        }
        await submitCostCodes({ variables: { input: { costCode: item["Cost Code"], laborCode: item["Labor Code"], description: item["Description"] } } });
      });
    }
    // await submitCostCodes({ variables: { input: { id: clReport?.id, fieldTasks } } });
    alert("Cost Codes have been Successfully Submitted");
    setXlData([]);
  };

  // const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const reader = new FileReader();
  //   if (e.target.files) {
  //     reader.readAsBinaryString(e.target.files[0]);
  //     reader.onload = (e) => {
  //       const xlData = e.target?.result;
  //       const workbook = XLSX.read(xlData, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const parsedData = XLSX.utils.sheet_to_json(sheet);
  //       setXlData(parsedData);
  //     }
  //   }
  // }

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div className="costcodes">
      <AdminMenu />
      <form className="costcodes-spreadsheet">
        <h4 className="costcodes-spreadsheet-header">Spreadsheet Cost Codes</h4>
        <label className="costcodes-spreadsheet-label" htmlFor="fileuploader">
          Upload Your Spreadsheet Here
        </label>
        <input
          className="costcodes-spreadsheet-input"
          type="file"
          accept=".xlsx, .xls"
          onChange={e => {
            // handleUpload(e)
            alert("Network connection too slow.  Upload individual cost code or try again later.");
          }}
        />
        <button
          className="costcodes-spreadsheet-submit"
          onClick={e => {
            e.preventDefault();
            // handleSubmitCostCodes(e)
            alert("Network connection too slow.  Upload individual cost code or try again later.");
          }}
        >
          Submit Spreadsheet
        </button>
      </form>

      <form className="costcodes-individual">
        <h4 className="costcodes-individual-header">Individual Cost Codes</h4>
        <div className="costcodes-individual-codegroup">
          <div className="costcodes-individual-codegroup-labor">
            <label className="costcodes-individual-codegroup-labor-label" htmlFor="">
              Budget
            </label>
            <input
              className="costcodes-individual-codegroup-labor-input"
              name="budget"
              type="number"
              value={individualCostcode.budget !== parseFloat("") ? individualCostcode.budget : parseFloat("")}
              onChange={e =>
                setIndivudualCostCode({
                  ...individualCostcode,
                  budget: parseFloat(e.target.value),
                })
              }
            />
          </div>
          <div className="costcodes-individual-codegroup-labor">
            <label className="costcodes-individual-codegroup-labor-label" htmlFor="">
              Labor Code
            </label>
            <input
              className="costcodes-individual-codegroup-labor-input"
              type="number"
              value={individualCostcode.laborCode !== "" ? individualCostcode.laborCode : parseInt("")}
              onChange={e =>
                setIndivudualCostCode({
                  ...individualCostcode,
                  laborCode: e.target.value,
                })
              }
            />
          </div>
          <div className="costcodes-individual-codegroup-costcode">
            <label className="costcodes-individual-codegroup-costcode-label" htmlFor="">
              Cost Code
            </label>
            <input
              className="costcodes-individual-codegroup-costcode-input"
              type="number"
              value={individualCostcode.costCode !== "" ? individualCostcode.costCode : parseInt("")}
              onChange={e =>
                setIndivudualCostCode({
                  ...individualCostcode,
                  costCode: e.target.value,
                })
              }
            />
          </div>
          <div className="costcodes-individual-codegroup-description">
            <label className="costcodes-individual-codegroup-description-label" htmlFor="">
              Description
            </label>
            <textarea
              className="costcodes-individual-codegroup-description-input"
              value={individualCostcode.description}
              onChange={e =>
                setIndivudualCostCode({
                  ...individualCostcode,
                  description: e.target.value,
                })
              }
            />
          </div>
        </div>
        {deleteCostCodes.length > 0 ? (
          <button
            className="costcodes-individual-delete"
            onClick={async e => {
              if (deleteCostCodes.length > 0) {
                e.preventDefault();
                const res = await removeSelectedCostCodes({ variables: { id: deleteCostCodes } });
                setDeleteCostCodes([]);
                if (res.errors) {
                  alert("There has been an error removing the selected Cost Codes");
                } else {
                  alert("Selected Cost Codes Have Been Successfully Removed");
                  window.location.reload();
                }
              }
            }}
          >
            Delete Selected Cost Code
          </button>
        ) : (
          <button
            className="costcodes-individual-submit"
            onClick={async e => {
              if (individualCostcode.costCode === "") {
                alert("Please Enter A Cost Code.");
                // return;
              } else if (individualCostcode.laborCode === "") {
                alert("Please Enter A Labor Code.");
                // return;
              } else if (individualCostcode.description === "") {
                alert("Please Enter A Description.");
                // return;
              } else {
                e.preventDefault();
                await submitCostCodes({ variables: { input: individualCostcode } });
                alert("Cost Code Submission Successful");
                setIndivudualCostCode({
                  budget: 0,
                  laborCode: "",
                  costCode: "",
                  description: "",
                });
                window.location.reload();
              }
            }}
          >
            Submit Cost Code
          </button>
        )}
      </form>
      <div className="costcodes-bottomwrapper">
        <table className="costcodes-bottomwrapper-codestable">
          <thead className="costcodes-bottomwrapper-codestable-head">
            <tr className="costcodes-bottomwrapper-codestable-head-row">
              <th className="costcodes-bottomwrapper-codestable-head-row-item">Budget</th>
              <th className="costcodes-bottomwrapper-codestable-head-row-item">Cost Code</th>
              <th className="costcodes-bottomwrapper-codestable-head-row-item">Labor Code</th>
              <th className="costcodes-bottomwrapper-codestable-head-row-item">Description</th>
              <th className="costcodes-bottomwrapper-codestable-head-row-item">Edit</th>
            </tr>
          </thead>
          <tbody className="costcodes-bottomwrapper-codestable-body">
            {costCodes.length > 0 &&
              costCodes.map((code: any, idx: number) => (
                <tr key={idx} className={`costcodes-bottomwrapper-codestable-body-row ${code.id === deleteCostCodes.find((el: any) => el === code.id) && "selectedRow"}`}>
                  <td className="costcodes-bottomwrapper-codestable-body-row-item">
                    {canEdit && editCostCodes.id === code.id ? (
                      <input
                        className="costcodes-bottomwrapper-codestable-body-row-item-ccedit"
                        name="costCode"
                        type="number"
                        value={editCostCodes.budget}
                        onChange={e =>
                          setEditCostCodes({
                            ...editCostCodes,
                            budget: parseFloat(e.target.value),
                          })
                        }
                      />
                    ) : (
                      numeral(code.budget).format("$0,0.00")
                    )}
                  </td>
                  <td className="costcodes-bottomwrapper-codestable-body-row-item">
                    {canEdit && editCostCodes.id === code.id ? (
                      <input
                        className="costcodes-bottomwrapper-codestable-body-row-item-ccedit"
                        name="costCode"
                        type="number"
                        value={editCostCodes.costCode}
                        onChange={e =>
                          setEditCostCodes({
                            ...editCostCodes,
                            costCode: e.target.value,
                          })
                        }
                      />
                    ) : (
                      code.costCode
                    )}
                  </td>
                  <td className="costcodes-bottomwrapper-codestable-body-row-item">
                    {canEdit && editCostCodes.id === code.id ? (
                      <input
                        className="costcodes-bottomwrapper-codestable-body-row-item-lcedit"
                        name="laborCode"
                        type="text"
                        value={editCostCodes.laborCode}
                        onChange={e =>
                          setEditCostCodes({
                            ...editCostCodes,
                            laborCode: e.target.value,
                          })
                        }
                      />
                    ) : (
                      code.laborCode
                    )}
                  </td>
                  <td className="costcodes-bottomwrapper-codestable-body-row-item">
                    {canEdit && editCostCodes.id === code.id ? (
                      <input
                        className="costcodes-bottomwrapper-codestable-body-row-item-descedit"
                        name="laborCode"
                        type="text"
                        value={editCostCodes.description}
                        onChange={e =>
                          setEditCostCodes({
                            ...editCostCodes,
                            description: e.target.value,
                          })
                        }
                      />
                    ) : (
                      code.description
                    )}
                  </td>
                  <td className="costcodes-bottomwrapper-codestable-body-row-item icons">
                    <div className="costcodes-bottomwrapper-codestable-body-row-item-iconwrapper">
                      {canEdit && editCostCodes.id === code.id ? (
                        <p
                          onClick={async () => {
                            setCanEdit(false);
                            await editCostcode({
                              variables: {
                                input: {
                                  id: editCostCodes.id,
                                  budget: editCostCodes.budget,
                                  laborCode: editCostCodes.laborCode,
                                  costCode: editCostCodes.costCode,
                                  description: editCostCodes.description,
                                },
                              },
                            });
                            window.location.reload();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Save
                        </p>
                      ) : (
                        <FontAwesomeIcon
                          className="costcodes-bottomwrapper-codestable-body-row-item-iconwrapper-edit"
                          icon={faEdit}
                          onClick={() => {
                            if (idx === costCodes.indexOf(code)) {
                              setEditCostCodes({
                                id: code.id,
                                budget: code.budget,
                                costCode: code.costCode,
                                laborCode: code.laborCode,
                                description: code.description,
                              });
                            }
                            setCanEdit(!canEdit);
                          }}
                        />
                      )}

                      {canEdit && editCostCodes.id === code.id ? (
                        <p
                          onClick={() => {
                            setCanEdit(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Cancel
                        </p>
                      ) : (
                        <FontAwesomeIcon
                          className="costcodes-bottomwrapper-codestable-body-row-item-iconwrapper-delete"
                          icon={faMinus}
                          onClick={() => {
                            setDeleteCostCodes([...deleteCostCodes, code.id]);
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default XlUpload;
