import React, { useState, useEffect } from "react";
import { getTimesheets, getEmployeeJobRates, getCostCodes } from "../../../graphql/queries";
import { ApolloQueryResult } from "@apollo/client";
import numeral from "numeral";
import DatePicker from "react-datepicker";

import "./LaborBreakdown.scss";

interface laborBreakdownProps {
  reportJobId: string;
}

const LaborBreakdown: React.FC<laborBreakdownProps> = ({ reportJobId }) => {
  const [timesheets, setTimesheets] = useState<any[]>([]);
  const [laborCodeBreakdowns, setLaborCodeBreadowns] = useState<any[]>([]);
  const [wagesTotals, setWagesTotals] = useState<number>(0);
  const [timeBreakdown, setTimeBreakdown] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [laborCodeChunks, setLaborCodeChunks] = useState<any[]>([]);
  const [lcDates, setLcDates] = useState<any[]>([]);
  const [employeeRates, setEmployeeRates] = useState<any[]>([]);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [reportRates, setReportRates] = useState<any[]>([]);
  const [shouldEdit, setShouldEdit] = useState<boolean>(false);
  const [job, setJob] = useState<{
    jobId: string;
    jobName: string;
    reportDate: string;
    laborSubtotal?: number;
  }>({
    jobId: "",
    jobName: "",
    reportDate: new Date().toString(),
    laborSubtotal: 0,
  });

  const fetchTimesheets = async () => {
    const tsList: ApolloQueryResult<any> = await getTimesheets();
    const ccList = await getCostCodes();
    setCostCodes([...ccList.costCodes]);
    if (tsList.data) {
      let listArr = [...tsList.data.timesheets.filter((t: any) => t.jobId === reportJobId)];
      setTimesheets(listArr);
      let interArr: any = [];
      let wagesTotals: any = [];
      let wagesOTTotals: any = [];
      listArr.forEach((t: any) => {
        t.reportHours.forEach((r: any) => {
          interArr.push({ ...r, date: new Date(t.reportDate) });
          wagesTotals.push(r.wagesTotalRT);
          wagesTotals.push(r.wagesTotalOT);
        });

        setTimeBreakdown([...timeBreakdown, ...interArr]);
      });
    }

    const sheets = tsList.data.timesheets;
    const d = new Date(sheets[0].reportDate).toString();
    d.split(" ");
    setJob({
      jobId: sheets[0].jobId,
      jobName: sheets[0].jobName,
      reportDate: d.split(" ").slice(0, 4).join(" "),
    });
  };

  const fetchEmployeeRates = async () => {
    try {
      const data = await getEmployeeJobRates();
      setEmployeeRates([...data.employeeRates]);
    } catch (error) {
      console.log("EMPLOYEE RATES ERROR IN LABOR BREAKDOWN: ", error);
    }
  };

  const getEmployeeRatesForReport = () => {
    let updatedRates: number[] = [];
    for (let i = 0; i < lcDates.length; i++) {
      for (let j = 0; j < employeeRates.length; j++) {
        if (lcDates[i].id === employeeRates[j].employeeId) {
          updatedRates.push(employeeRates[j]);
        }
      }
    }
    return updatedRates;
  };

  const getLaborTotals = () => {
    let totals = lcDates.reduce((acc: any, val: any) => acc + val.total, 0);
    setWagesTotals(totals);
  };

  useEffect(() => {
    setTimesheets([]);
    fetchTimesheets();
    fetchEmployeeRates();
    getLcChunks();
  }, [reportJobId]);

  const dateGenerator = () => {
    const d = new Date(),
      year = d.getFullYear(),
      mondays: Date[] = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all other Mondays in the month
    while (d.getFullYear() === year) {
      const pushDate = new Date(d.getTime());
      mondays.push(new Date(pushDate.getMonth() + 1 + "-" + pushDate.getDate() + "-" + pushDate.getFullYear()));
      d.setDate(d.getDate() + 7);
    }
    // console.log('MONDAYS: ', mondays);
    return mondays;
  };

  const getLcChunks = () => {
    // console.log('DOING LC CHUNKS.')
    const tb = timeBreakdown.sort((a: any, b: any) => parseInt(a.laborCode) - parseInt(b.laborCode)).map((l: any) => l);
    // console.log('TB: ', tb);
    let temp: any = [];
    let chunks: any = [];
    let begin = 0;
    let end = 0;
    for (let i = 0; i < tb.length; i++) {
      temp = [...temp, { lc: tb[i].laborCode, tb: tb[i] }];
    }
    setLaborCodeChunks(temp);
  };

  const workWeekGenerator = (sd: any) => {
    const dt = new Date();
    const currD = new Date(sd);
    let startDate = new Date(sd);
    startDate.setDate(startDate.getDate() - 6);
    const filteredDate = timeBreakdown.filter((d: any) => new Date(d.date).getDate() >= startDate.getDate() && new Date(d.date).getDate() <= currD.getDate());
    const laborCodeSum = filteredDate.filter((c: any) => parseInt(c.lc) === 11);
    let workDatesArr: any[] = [];
    setLcDates([]);
    setLcDates(filteredDate);
    getLaborCodeBreakdowns();
    getLaborTotals();
    const workDayChunks = filteredDate.reduce((agg: any, wd) => {
      const wdDate = new Date(wd?.date);
      const wdDay = wdDate.getDate();
      if (!agg[wdDay]) {
        agg[wdDay] = { date: wdDate, arr: [] };
      }
      agg[wdDay].arr.push(wd);
      return agg;
    }, {});

    let workWeekChunksArr: any[] = [];
    let lcChunksArr: any[] = [];
    for (let c in workDayChunks) {
      workWeekChunksArr.push(workDayChunks[c]);
    }
    workWeekChunksArr.forEach((a: any) => {
      a.arr.forEach((el: any) => {
        el.total = el.wagesTotalRT + el.wagesTotalOT;
        el.totalHours = el.regHours + el.otHours;
      });
      lcChunksArr = a.arr.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) a.push(b.laborCode);
        return a;
      }, []);
    });

    let newChunks = [...new Set(lcChunksArr)];
    let newArr: any[] = [];
    let newLCArr: any[] = [];
    for (let i = 0; i < workWeekChunksArr.length; i++) {
      // console.log('THIS IS J: ', workWeekChunksArr[i].arr.length)
      newChunks.forEach((el: any) => {
        let temp = workWeekChunksArr[i].arr.filter((c: any) => c.laborCode === el);
        newArr.push({
          date: workWeekChunksArr[i].date,
          laborCode: el,
          totalHours: parseFloat(temp?.reduce((a: any, b: any) => a + b?.totalHours, 0)),
          total: parseFloat(temp?.reduce((a: any, b: any) => a + b.total, 0).toFixed(2)),
        });
      });
    }
    newChunks.forEach((el: any) => {
      let fa = newArr.filter((e: any) => e.laborCode === el);
      newLCArr.push({
        dateEnding: selectedDate,
        laborCode: el,
        totalHours: fa.reduce((a: any, b: any) => a + b.totalHours, 0),
        total: fa.reduce((a: any, b: any) => a + b.total, 0),
      });
    });
    // console.log('REVISED LC CHUNKS ARR: ', newLCArr);
    // console.log('NEW LC CHUNKS ARR: ', newArr);
    // console.log('NEW CHUNKS: ', newChunks);
    // console.log('WORK WEEK CHUNKS: ', workWeekChunksArr);
    // console.log('LC CHUNKS ARR: ', workDayChunks);
  };

  const laborTotals = () => {
    if (wagesTotals) {
      return numeral(wagesTotals.toFixed(2)).format("$0,0.00");
    }
  };

  const laborBreakdownTable = () => {
    return (
      <table className="laborbreakdown-table">
        <thead className="laborbreakdown-table-head">
          <tr className="laborbreakdown-table-head-toprow">
            <th className="laborbreakdown-table-head-toprow-col">Date</th>
            <th className="laborbreakdown-table-head-toprow-col">Employee</th>
            <th className="laborbreakdown-table-head-toprow-col">Labor/Cost Code</th>
            <th className="laborbreakdown-table-head-toprow-col">FM</th>
            <th className="laborbreakdown-table-head-toprow-col">JM</th>
            <th className="laborbreakdown-table-head-toprow-col">AP</th>
            <th className="laborbreakdown-table-head-toprow-col">Total HRS.</th>
            <th className="laborbreakdown-table-head-toprow-col">FM Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">BTJ Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">AP Rate</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Sub-Total</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages O&P</th>
            <th className="laborbreakdown-table-head-toprow-col">Wages Total</th>
          </tr>
        </thead>
        <tbody className="laborbreakdown-table-body">
          {lcDates
            .sort((a: any, b: any) => a.date - b.date)
            .map((ts: any, idx: number) => {
              return (
                <>
                  <tr key={idx} className="laborbreakdown-table-body-row">
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.regHours}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{ts.workerClass === "BTF" && numeral(ts.regRate).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={ts.workerClass === "BTF" ? numeral(ts.regRate).format("$0,0.00") : ""} placeholder={ts.workerClass === "BTF" ? numeral(ts.regRate).format("$0,0.00") : ""} />}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{ts.workerClass === "BTJ" && numeral(ts.regRate).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={ts.workerClass === "BTJ" ? numeral(ts.regRate).format("$0,0.00") : ""} placeholder={ts.workerClass === "BTJ" ? numeral(ts.regRate).format("$0,0.00") : ""} />}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{ts.workerClass === "BTA" && numeral(ts.regRate).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={ts.workerClass === "BTA" ? numeral(ts.regRate).format("$0,0.00") : ""} placeholder={ts.workerClass === "BTA" ? numeral(ts.regRate).format("$0,0.00") : ""} />}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{numeral(ts.regWages).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={numeral(ts.regWages).format("$0,0.00")} placeholder={numeral(ts.regWages).format("$0,0.00")} />}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{numeral(ts.wagesOAndPRT).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={numeral(ts.wagesOAndPRT).format("$0,0.00")} placeholder={numeral(ts.wagesOAndPRT).format("$0,0.00")} />}</td>
                    <td className="laborbreakdown-table-body-row-col">{shouldEdit === false ? <>{numeral(ts.wagesTotalRT).format("$0,0.00")}</> : <input className="laborbreakdown-table-body-row-editinput" type="number" step={0.001} value={numeral(ts.wagesTotalRT).format("$0,0.00")} placeholder={numeral(ts.wagesTotalRT).format("$0,0.00")} />}</td>
                  </tr>
                  <tr key={idx + 0.5}>
                    <td className="laborbreakdown-table-body-row-col">{new Date(ts.date).toString().split(" ").slice(0, 4).join(" ")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.name}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      {ts.laborCode}, {ts.costCode}
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTF" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && "1"}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.otHours || 0}</td>
                    <td className="laborbreakdown-table-body-row-col">
                      <>{ts.workerClass === "BTF" && numeral(ts.otRate).format("$0,0.00")}</>
                    </td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTJ" && numeral(ts.otRate).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{ts.workerClass === "BTA" && numeral(ts.otRate).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.otHours * ts.otRate).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesOAndPOT).format("$0,0.00")}</td>
                    <td className="laborbreakdown-table-body-row-col">{numeral(ts.wagesTotalOT).format("$0,0.00")}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  };

  const getLaborCodeBreakdowns = () => {
    let temp: any[] = [];
    costCodes.forEach((c: any) => {
      for (let d of lcDates) {
        if (c.laborCode === d.laborCode) {
          temp.push(c);
        }
      }
    });
    setLaborCodeBreadowns([...temp]);
    console.log("LABOR CODE BREAKDOWNS: ", temp, "COST CODES: ", costCodes);
  };

  // getLaborCodeBreakdowns();

  console.log("LC DATES: ", lcDates);

  return (
    <div className="laborbreakdown">
      <div className="laborbreakdown-datePicker">
        <DatePicker
          wrapperClassName="laborbreakdown-datePicker-date"
          selected={new Date()}
          includeDates={dateGenerator()}
          onChange={(e: any) => {
            // console.log('DATE PICKER: ', e[0]);
            setSelectedDate(new Date(e[0]).toDateString());
            workWeekGenerator(e[0]);
          }}
          selectsRange
          inline
        />
      </div>
      <div className="laborbreakdown-edit">
        <input
          className="laborbreakdown-edit-btn"
          type="button"
          value="Edit Report"
          onClick={() => {
            setShouldEdit(!shouldEdit);
          }}
        />
      </div>
      <div className="laborbreakdown-laborcodebreakdown">
        <h2 className="laborbreakdown-laborcodebreakdown-title">Labor Codes & Cost Codes</h2>
        <table className="laborbreakdown-laborcodebreakdown-table">
          <thead className="laborbreakdown-laborcodebreakdown-table-h">
            <tr className="laborbreakdown-laborcodebreakdown-table-h-row">
              <th className="laborbreakdown-laborcodebreakdown-table-h-row-item">Labor Code</th>
              <th className="laborbreakdown-laborcodebreakdown-table-h-row-item">Cost Code</th>
              <th className="laborbreakdown-laborcodebreakdown-table-h-row-item">Description Code</th>
            </tr>
          </thead>
          <tbody className="laborbreakdown-laborcodebreakdown-table-b">
            {laborCodeBreakdowns?.map((lc: any, idx: number) => {
              return (
                <tr className="laborbreakdown-laborcodebreakdown-table-b-row">
                  <td className="laborbreakdown-laborcodebreakdown-table-b-row-item">{lc.laborCode}</td>
                  <td className="laborbreakdown-laborcodebreakdown-table-b-row-item">{lc.costCode}</td>
                  <td className="laborbreakdown-laborcodebreakdown-table-b-row-item">{lc.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {laborBreakdownTable()}
      <div className="laborbreakdown-labortotal">
        <h3 className="laborbreakdown-labortotal-title">Labor Subtotal with OH&P (8%)</h3>
        <span className="laborbreakdown-labortotal-text">{laborTotals()}</span>
      </div>
    </div>
  );
};

export default LaborBreakdown;
