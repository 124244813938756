export type checklistType = {
  type: string;
  fieldTasks:
  {
    id: number;
    question: string;
    answer: string
  }[];
  contractNo: string;
  description: string;
  contractor: string;
  sub_contractor: string;
  locationOfWork: string;
  date: string;
  preparedBy: string;
  datePrepared: string;
  inspectorName: string;
  surveillanceReport: string;
  drawing: string;
  remarks: string
  nonconformanceRemarks: string

  additionalRemarks: {
    text: string;
    name1: string;
    signature1: string;
    title1: string;
    date1: string;
    name2: string;
    signature2: string;
    title2: string;
    date2: string;
    name3: string;
    signature3: string;
    title3: string;
    date3: string;
  },
}

export const mainChecklistsArr = [
  {
    type: 'General Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Have shop drawings been approved",
        answer: ""
      },
      {
        id: 2,
        question: "Has plumber obtained all necessary permits",
        answer: ""
      },
      {
        id: 3,
        question: "Are plumbing materials in proper sizes, as per contract documents",
        answer: ""
      },
      {
        id: 4,
        question: "Verify the proper use of plumbing materials, as per contract",
        answer: ""
      },
      {
        id: 5,
        question: "Is contractor protecting openings of installed pipe at end of shift",
        answer: ""
      },
      {
        id: 6,
        question: "Check for clearance for future maintenance",
        answer: ""
      },
      {
        id: 7,
        question: "Are clean outs installed and accessible",
        answer: ""
      },
      {
        id: 8,
        question: "Is proper slope being maintained where required",
        answer: ""
      },
      {
        id: 9,
        question: "Are pipes threaded properly",
        answer: ""
      },
      {
        id: 10,
        question: "Are personnel utilizing proper techniques",
        answer: ""
      },
      {
        id: 11,
        question: "Do supply pipes to fixtures have shut off valves at each inlet",
        answer: ""
      },
      {
        id: 12,
        question: "Are all traps set at proper level and vented properly to avoid siphoning",
        answer: ""
      },
      {
        id: 13,
        question: "Do all pipes bring installed above frost line have proper insulation and/or heat tracing",
        answer: ""
      },
      {
        id: 14,
        question: "Is testing of pipes, etc., being performed according to specifications ",
        answer: ""
      },
      {
        id: 15,
        question: "Are all pipes properly supported",
        answer: ""
      },
      {
        id: 16,
        question: "Are hanger components approved",
        answer: ""
      },
      {
        id: 16,
        question: "Are hanger components approved",
        answer: ""
      },
      {
        id: 17,
        question: "Have valves and pipes been approved",
        answer: ""
      },
      {
        id: 18,
        question: "Has installed pipe been visually inspected prior to concrete placement",
        answer: ""
      },
      {
        id: 19,
        question: "Has system been vented at high sports to remove air prior to hydro",
        answer: ""
      },
      {
        id: 20,
        question: "Supply testing - has pipe been hydrostatically tested at 125 PSI or 125% of water utility pressure",
        answer: ""
      },
      {
        id: 21,
        question: "Are testing pressure gauges calibrated",
        answer: ""
      },
      {
        id: 22,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Insulation Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Plumbing and equipment tested and operational before applying Insulation",
        answer: ""
      },
      {
        id: 9,
        question: "Area to be insulated is free of rust// scale// dirt// and moisture",
        answer: ""
      },
      {
        id: 10,
        question: "Adhesive/Anchors/Staples/Wrapping utilized is compatible with Insulation type",
        answer: ""
      },
      {
        id: 11,
        question: "Insulation through penetrations maintains fire rating of structure",
        answer: ""
      },
      {
        id: 12,
        question: "Insulation protected from chafe at all supports and contact points",
        answer: ""
      },
      {
        id: 13,
        question: "Operation of valves and actuators not hindered by insulation",
        answer: ""
      },
      {
        id: 14,
        question: "Insulation joints sealed",
        answer: ""
      },
      {
        id: 15,
        question: "Cladding applied in high abuse/traffic areas",
        answer: ""
      },
      {
        id: 16,
        question: "Openings/Holes caused by testing closed/repaired",
        answer: ""
      },
      {
        id: 17,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Electric Domestic Water Heater Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Water heaters NOT started up dry",
        answer: ""
      },
      {
        id: 9,
        question: "TPR valve discharge directed to floor or other safe discharge location",
        answer: ""
      },
      {
        id: 10,
        question: "Surge tank provided for systems with back flow preventers on the supply line",
        answer: ""
      },
      {
        id: 11,
        question: "Water heater securely mounted and not supported by plumbing piping",
        answer: ""
      },
      {
        id: 12,
        question: "Tank grounded",
        answer: ""
      },
      {
        id: 13,
        question: "Drain valve accessible",
        answer: ""
      },
      {
        id: 14,
        question: "Shutoff / Bypass valves installed",
        answer: ""
      },
      {
        id: 15,
        question: "Service Manuals supplied to Owner",
        answer: ""
      },
      {
        id: 16,
        question: "Owner instructed in use and maintenance of Water Heater",
        answer: ""
      },
      {
        id: 17,
        question: "Operational set points noted in Operation and Maintenance Manuals",
        answer: ""
      },
      {
        id: 18,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Potable Water Storage Tanks Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Tanks are UL or NSF certified",
        answer: ""
      },
      {
        id: 9,
        question: "Tanks are securely mounted to structural supports",
        answer: ""
      },
      {
        id: 10,
        question: "Level / Pressure indicator visible",
        answer: ""
      },
      {
        id: 11,
        question: "Tank protected from traffic damage",
        answer: ""
      },
      {
        id: 12,
        question: "Tank secured from floatation",
        answer: ""
      },
      {
        id: 13,
        question: "Corrosion protection coating intact",
        answer: ""
      },
      {
        id: 14,
        question: "Tank is level and plumb",
        answer: ""
      },
      {
        id: 15,
        question: "Adequate pre-charge on pressure tanks",
        answer: ""
      },
      {
        id: 16,
        question: "Venting / Pressure relief is provided",
        answer: ""
      },
      {
        id: 17,
        question: "Vent openings are screened against insect intrusion",
        answer: ""
      },
      {
        id: 18,
        question: "Tank connections tight and leak proof",
        answer: ""
      },
      {
        id: 19,
        question: "Access ways secure against unauthorized entry",
        answer: ""
      },
      {
        id: 20,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Sanitary Drainage Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "No dips in gravity lines",
        answer: ""
      },
      {
        id: 9,
        question: "Adequate clean-outs provided in straight runs and at all bends",
        answer: ""
      },
      {
        id: 10,
        question: "Vents discharge outside of buildings and away from air intakes",
        answer: ""
      },
      {
        id: 11,
        question: "Grease trap located near source of grease",
        answer: ""
      },
      {
        id: 12,
        question: "Baffling provided on inlets and outlets of septic tanks",
        answer: ""
      },
      {
        id: 13,
        question: "Grinder pumps located on tank bottom",
        answer: ""
      },
      {
        id: 14,
        question: "Effluent pumps located off of tank bottom",
        answer: ""
      },
      {
        id: 15,
        question: "Lifting cables / chains / devices securely connected to pumps",
        answer: ""
      },
      {
        id: 16,
        question: "Below ground tanks secured against floatationd",
        answer: ""
      },
      {
        id: 17,
        question: "Alarms are visible and audible",
        answer: ""
      },
      {
        id: 18,
        question: "Pump tanks adequately vented",
        answer: ""
      },
      {
        id: 19,
        question: "Insect screens installed on all tank vents",
        answer: ""
      },
      {
        id: 20,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Storm Drainage Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Drain inlet tops located below adjacent grades",
        answer: ""
      },
      {
        id: 9,
        question: "Drain inlets and pipes free of debris// sediment// and foreign material",
        answer: ""
      },
      {
        id: 10,
        question: "Pipes / Culverts discharge to stabilized areas",
        answer: ""
      },
      {
        id: 11,
        question: "Sump pumps NOT connected to sanitary sewer system",
        answer: ""
      },
      {
        id: 12,
        question: "Roof drains NOT connected to sanitary sewer system",
        answer: ""
      },
      {
        id: 13,
        question: "Downspouts discharge away from building foundation",
        answer: ""
      },
      {
        id: 14,
        question: "Drainage ways free of debris// sediment and foreign material",
        answer: ""
      },
      {
        id: 15,
        question: "Tanks are securely mounted to structural supports",
        answer: ""
      },
      {
        id: 16,
        question: "Tank protected from traffic damage",
        answer: ""
      },
      {
        id: 17,
        question: "Tank secured from floatation",
        answer: ""
      },
      {
        id: 18,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Domestic Portable Water Distribution Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Piping and appurtenances are UL or NSF certified",
        answer: ""
      },
      {
        id: 9,
        question: "Fittings / Connections tight and leak free",
        answer: ""
      },
      {
        id: 10,
        question: "Piping not placed above electrical panels or switchgear",
        answer: ""
      },
      {
        id: 11,
        question: "Firestops installed at penetrations through fire partitions// fire walls// smoke partitions// or floors",
        answer: ""
      },
      {
        id: 12,
        question: "Piping secured to prevent movement and chafe",
        answer: ""
      },
      {
        id: 13,
        question: "Pumps securely mounted and properly aligned with intake and discharge piping",
        answer: ""
      },
      {
        id: 14,
        question: "Flow/Pressure sensor cabling led separately or shielded from power cabling",
        answer: ""
      },
      {
        id: 15,
        question: "Service Manuals supplied to Owner",
        answer: ""
      },
      {
        id: 16,
        question: "Owner instructed in use and maintenance of Water Distribution system",
        answer: ""
      },
      {
        id: 17,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },
    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Fuel Fired Domestic Water Heaters Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Water heaters NOT started up dry",
        answer: ""
      },
      {
        id: 9,
        question: "TPR valve discharge directed to floor or other safe discharge location",
        answer: ""
      },
      {
        id: 10,
        question: "Surge tank provided for systems with back flow preventers on the supply line",
        answer: ""
      },
      {
        id: 11,
        question: "Water heater securely mounted and not supported by plumbing piping",
        answer: ""
      },
      {
        id: 12,
        question: "Discharge flues have a positive slope from Heater to final discharge point",
        answer: ""
      },
      {
        id: 13,
        question: "Air intake located separate from flue discharge",
        answer: ""
      },
      {
        id: 14,
        question: "Drain valve accessible",
        answer: ""
      },
      {
        id: 15,
        question: "Shutoff / Bypass valves installed",
        answer: ""
      },
      {
        id: 16,
        question: "Service Manuals supplied to Owner",
        answer: ""
      },
      {
        id: 17,
        question: "Owner instructed in use and maintenance of Water Heater",
        answer: ""
      },
      {
        id: 18,
        question: "Operational set points noted in Operation and Maintenance Manuals",
        answer: ""
      },
      {
        id: 19,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },

    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Plumbing Fixtures Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 2,
        question: "Compliance with material inspection and tests",
        answer: "",
      },
      {
        id: 3,
        question: "Compliance with work in process first article inspection requirements?",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with Task completion inspection requirements",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with inspection and test plan?",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with safety policies and procedures",
        answer: ""
      },
      {
        id: 8,
        question: "Fixtures securely mounted to structural members",
        answer: ""
      },
      {
        id: 9,
        question: "Fixtures level and plumb",
        answer: ""
      },
      {
        id: 10,
        question: "Water supply and drain lines securely connected and free of leaks",
        answer: ""
      },
      {
        id: 11,
        question: "All traps have water in them to prevent intrusion of sewer gasses",
        answer: ""
      },
      {
        id: 12,
        question: "All fixtures quickly drain when operated",
        answer: ""
      },
      {
        id: 13,
        question: "Vents and overflow openings functional",
        answer: ""
      },
      {
        id: 14,
        question: "Valves fully close and are free of leaks",
        answer: ""
      },
      {
        id: 15,
        question: "Faucet aerators / screens free of grit and other debris",
        answer: ""
      },
      {
        id: 16,
        question: "Supply pressure adequate for proper functioning of fixture",
        answer: ""
      },
      {
        id: 17,
        question: "Automatic operation sensors functional",
        answer: ""
      },
      {
        id: 18,
        question: "Operational set points noted in Operation and Maintenance Manuals",
        answer: ""
      },
      {
        id: 19,
        question: "Service Manuals supplied to Owner",
        answer: ""
      },
      {
        id: 20,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },

    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  },
  {
    type: 'Fire Stopping Checklist',
    fieldTasks: [
      {
        id: 1,
        question: "Approved firestop for this project was used",
        answer: ""
      },
      {
        id: 2,
        question: "Approved firestop installer present at the time of installation",
        answer: "",
      },
      {
        id: 3,
        question: "Deliver materials undamaged in manufacturer's clearly labeled",
        answer: ""
      },
      {
        id: 4,
        question: "Compliance with initial job-ready requirements",
        answer: ""
      },
      {
        id: 5,
        question: "Compliance with material inspection and tests",
        answer: ""
      },
      {
        id: 6,
        question: "Compliance with work in process first article inspection requirements",
        answer: ""
      },
      {
        id: 7,
        question: "Compliance with work in process inspection requirements",
        answer: ""
      },
      {
        id: 8,
        question: "Compliance with work task completion inspection requirements",
        answer: ""
      },
      {
        id: 9,
        question: "Compliance with inspection and test plan",
        answer: ""
      },
      {
        id: 10,
        question: "Compliance with inspection and test plan",
        answer: ""
      },
      {
        id: 11,
        question: "Work task verified complete to specifications",
        answer: ""
      },
      {
        id: 12,
        question: "Insert items identified at project startup and preparatory meetings",
        answer: ""
      },
      {
        id: 13,
        question: "The opening to be sealed was clean",
        answer: ""
      },
      {
        id: 14,
        question: "Mineral wool pack in opening (if applicable for this application)",
        answer: ""
      },
      {
        id: 15,
        question: "Firestop was applied to the required depth in order to obtain the desirer fire rating",
        answer: ""
      },
      {
        id: 16,
        question: "Reported Nonconformances and incomplete items:",
        answer: ""
      },

    ],
    contractNo: "",
    description: "",
    contractor: "",
    sub_contractor: "",
    locationOfWork: "",
    date: "",
    preparedBy: "",
    datePrepared: "",
    inspectorName: "",
    surveillanceReport: "",
    drawing: "",
    remarks: "",
    nonconformanceRemarks: "",

    additionalRemarks: {
      text: "",
      name1: "",
      signature1: "",
      title1: "",
      date1: "",
      name2: "",
      signature2: "",
      title2: "",
      date2: "",
      name3: "",
      signature3: "",
      title3: "",
      date3: "",
    },
  }
]

export const generalChecklist = () => ({
  type: 'General Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Have shop drawings been approved",
      answer: ""
    },
    {
      id: 2,
      question: "Has plumber obtained all necessary permits",
      answer: ""
    },
    {
      id: 3,
      question: "Are plumbing materials in proper sizes, as per contract documents",
      answer: ""
    },
    {
      id: 4,
      question: "Verify the proper use of plumbing materials, as per contract",
      answer: ""
    },
    {
      id: 5,
      question: "Is contractor protecting openings of installed pipe at end of shift",
      answer: ""
    },
    {
      id: 6,
      question: "Check for clearance for future maintenance",
      answer: ""
    },
    {
      id: 7,
      question: "Are clean outs installed and accessible",
      answer: ""
    },
    {
      id: 8,
      question: "Is proper slope being maintained where required",
      answer: ""
    },
    {
      id: 9,
      question: "Are pipes threaded properly",
      answer: ""
    },
    {
      id: 10,
      question: "Are personnel utilizing proper techniques",
      answer: ""
    },
    {
      id: 11,
      question: "Do supply pipes to fixtures have shut off valves at each inlet",
      answer: ""
    },
    {
      id: 12,
      question: "Are all traps set at proper level and vented properly to avoid siphoning",
      answer: ""
    },
    {
      id: 13,
      question: "Do all pipes bring installed above frost line have proper insulation and/or heat tracing",
      answer: ""
    },
    {
      id: 14,
      question: "Is testing of pipes, etc., being performed according to specifications ",
      answer: ""
    },
    {
      id: 15,
      question: "Are all pipes properly supported",
      answer: ""
    },
    {
      id: 16,
      question: "Are hanger components approved",
      answer: ""
    },
    {
      id: 16,
      question: "Are hanger components approved",
      answer: ""
    },
    {
      id: 17,
      question: "Have valves and pipes been approved",
      answer: ""
    },
    {
      id: 18,
      question: "Has installed pipe been visually inspected prior to concrete placement",
      answer: ""
    },
    {
      id: 19,
      question: "Has system been vented at high sports to remove air prior to hydro",
      answer: ""
    },
    {
      id: 20,
      question: "Supply testing - has pipe been hydrostatically tested at 125 PSI or 125% of water utility pressure",
      answer: ""
    },
    {
      id: 21,
      question: "Are testing pressure gauges calibrated",
      answer: ""
    },
    {
      id: 22,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
});

export const insulationChecklist = () => ({
  type: 'Insulation Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Plumbing and equipment tested and operational before applying Insulation",
      answer: ""
    },
    {
      id: 9,
      question: "Area to be insulated is free of rust// scale// dirt// and moisture",
      answer: ""
    },
    {
      id: 10,
      question: "Adhesive/Anchors/Staples/Wrapping utilized is compatible with Insulation type",
      answer: ""
    },
    {
      id: 11,
      question: "Insulation through penetrations maintains fire rating of structure",
      answer: ""
    },
    {
      id: 12,
      question: "Insulation protected from chafe at all supports and contact points",
      answer: ""
    },
    {
      id: 13,
      question: "Operation of valves and actuators not hindered by insulation",
      answer: ""
    },
    {
      id: 14,
      question: "Insulation joints sealed",
      answer: ""
    },
    {
      id: 15,
      question: "Cladding applied in high abuse/traffic areas",
      answer: ""
    },
    {
      id: 16,
      question: "Openings/Holes caused by testing closed/repaired",
      answer: ""
    },
    {
      id: 17,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const electricDomesticWaterHeatersChecklist = () => ({
  type: 'Electric Domestic Water Heater Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Water heaters NOT started up dry",
      answer: ""
    },
    {
      id: 9,
      question: "TPR valve discharge directed to floor or other safe discharge location",
      answer: ""
    },
    {
      id: 10,
      question: "Surge tank provided for systems with back flow preventers on the supply line",
      answer: ""
    },
    {
      id: 11,
      question: "Water heater securely mounted and not supported by plumbing piping",
      answer: ""
    },
    {
      id: 12,
      question: "Tank grounded",
      answer: ""
    },
    {
      id: 13,
      question: "Drain valve accessible",
      answer: ""
    },
    {
      id: 14,
      question: "Shutoff / Bypass valves installed",
      answer: ""
    },
    {
      id: 15,
      question: "Service Manuals supplied to Owner",
      answer: ""
    },
    {
      id: 16,
      question: "Owner instructed in use and maintenance of Water Heater",
      answer: ""
    },
    {
      id: 17,
      question: "Operational set points noted in Operation and Maintenance Manuals",
      answer: ""
    },
    {
      id: 18,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const potableWaterStorageTanksChecklist = () => ({
  type: 'Potable Water Storage Tanks Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Tanks are UL or NSF certified",
      answer: ""
    },
    {
      id: 9,
      question: "Tanks are securely mounted to structural supports",
      answer: ""
    },
    {
      id: 10,
      question: "Level / Pressure indicator visible",
      answer: ""
    },
    {
      id: 11,
      question: "Tank protected from traffic damage",
      answer: ""
    },
    {
      id: 12,
      question: "Tank secured from floatation",
      answer: ""
    },
    {
      id: 13,
      question: "Corrosion protection coating intact",
      answer: ""
    },
    {
      id: 14,
      question: "Tank is level and plumb",
      answer: ""
    },
    {
      id: 15,
      question: "Adequate pre-charge on pressure tanks",
      answer: ""
    },
    {
      id: 16,
      question: "Venting / Pressure relief is provided",
      answer: ""
    },
    {
      id: 17,
      question: "Vent openings are screened against insect intrusion",
      answer: ""
    },
    {
      id: 18,
      question: "Tank connections tight and leak proof",
      answer: ""
    },
    {
      id: 19,
      question: "Access ways secure against unauthorized entry",
      answer: ""
    },
    {
      id: 20,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const sanitaryDrainageChecklist = () => ({
  type: 'Sanitary Drainage Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "No dips in gravity lines",
      answer: ""
    },
    {
      id: 9,
      question: "Adequate clean-outs provided in straight runs and at all bends",
      answer: ""
    },
    {
      id: 10,
      question: "Vents discharge outside of buildings and away from air intakes",
      answer: ""
    },
    {
      id: 11,
      question: "Grease trap located near source of grease",
      answer: ""
    },
    {
      id: 12,
      question: "Baffling provided on inlets and outlets of septic tanks",
      answer: ""
    },
    {
      id: 13,
      question: "Grinder pumps located on tank bottom",
      answer: ""
    },
    {
      id: 14,
      question: "Effluent pumps located off of tank bottom",
      answer: ""
    },
    {
      id: 15,
      question: "Lifting cables / chains / devices securely connected to pumps",
      answer: ""
    },
    {
      id: 16,
      question: "Below ground tanks secured against floatationd",
      answer: ""
    },
    {
      id: 17,
      question: "Alarms are visible and audible",
      answer: ""
    },
    {
      id: 18,
      question: "Pump tanks adequately vented",
      answer: ""
    },
    {
      id: 19,
      question: "Insect screens installed on all tank vents",
      answer: ""
    },
    {
      id: 20,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const stormDrainageChecklist = () => ({
  type: 'Storm Drainage Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Drain inlet tops located below adjacent grades",
      answer: ""
    },
    {
      id: 9,
      question: "Drain inlets and pipes free of debris// sediment// and foreign material",
      answer: ""
    },
    {
      id: 10,
      question: "Pipes / Culverts discharge to stabilized areas",
      answer: ""
    },
    {
      id: 11,
      question: "Sump pumps NOT connected to sanitary sewer system",
      answer: ""
    },
    {
      id: 12,
      question: "Roof drains NOT connected to sanitary sewer system",
      answer: ""
    },
    {
      id: 13,
      question: "Downspouts discharge away from building foundation",
      answer: ""
    },
    {
      id: 14,
      question: "Drainage ways free of debris// sediment and foreign material",
      answer: ""
    },
    {
      id: 15,
      question: "Tanks are securely mounted to structural supports",
      answer: ""
    },
    {
      id: 16,
      question: "Tank protected from traffic damage",
      answer: ""
    },
    {
      id: 17,
      question: "Tank secured from floatation",
      answer: ""
    },
    {
      id: 18,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const domesticPortableWaterDistributionChecklist = () => ({
  type: 'Domestic Portable Water Distribution Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Piping and appurtenances are UL or NSF certified",
      answer: ""
    },
    {
      id: 9,
      question: "Fittings / Connections tight and leak free",
      answer: ""
    },
    {
      id: 10,
      question: "Piping not placed above electrical panels or switchgear",
      answer: ""
    },
    {
      id: 11,
      question: "Firestops installed at penetrations through fire partitions// fire walls// smoke partitions// or floors",
      answer: ""
    },
    {
      id: 12,
      question: "Piping secured to prevent movement and chafe",
      answer: ""
    },
    {
      id: 13,
      question: "Pumps securely mounted and properly aligned with intake and discharge piping",
      answer: ""
    },
    {
      id: 14,
      question: "Flow/Pressure sensor cabling led separately or shielded from power cabling",
      answer: ""
    },
    {
      id: 15,
      question: "Service Manuals supplied to Owner",
      answer: ""
    },
    {
      id: 16,
      question: "Owner instructed in use and maintenance of Water Distribution system",
      answer: ""
    },
    {
      id: 17,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },
  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const fuelFiredDomesticWaterHeatersChecklist = () => ({
  type: 'Fuel Fired Domestic Water Heaters Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Water heaters NOT started up dry",
      answer: ""
    },
    {
      id: 9,
      question: "TPR valve discharge directed to floor or other safe discharge location",
      answer: ""
    },
    {
      id: 10,
      question: "Surge tank provided for systems with back flow preventers on the supply line",
      answer: ""
    },
    {
      id: 11,
      question: "Water heater securely mounted and not supported by plumbing piping",
      answer: ""
    },
    {
      id: 12,
      question: "Discharge flues have a positive slope from Heater to final discharge point",
      answer: ""
    },
    {
      id: 13,
      question: "Air intake located separate from flue discharge",
      answer: ""
    },
    {
      id: 14,
      question: "Drain valve accessible",
      answer: ""
    },
    {
      id: 15,
      question: "Shutoff / Bypass valves installed",
      answer: ""
    },
    {
      id: 16,
      question: "Service Manuals supplied to Owner",
      answer: ""
    },
    {
      id: 17,
      question: "Owner instructed in use and maintenance of Water Heater",
      answer: ""
    },
    {
      id: 18,
      question: "Operational set points noted in Operation and Maintenance Manuals",
      answer: ""
    },
    {
      id: 19,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },

  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const plumbingFixturesChecklist = () => ({
  type: 'Plumbing Fixtures Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 2,
      question: "Compliance with material inspection and tests",
      answer: "",
    },
    {
      id: 3,
      question: "Compliance with work in process first article inspection requirements?",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with Task completion inspection requirements",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with inspection and test plan?",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with safety policies and procedures",
      answer: ""
    },
    {
      id: 8,
      question: "Fixtures securely mounted to structural members",
      answer: ""
    },
    {
      id: 9,
      question: "Fixtures level and plumb",
      answer: ""
    },
    {
      id: 10,
      question: "Water supply and drain lines securely connected and free of leaks",
      answer: ""
    },
    {
      id: 11,
      question: "All traps have water in them to prevent intrusion of sewer gasses",
      answer: ""
    },
    {
      id: 12,
      question: "All fixtures quickly drain when operated",
      answer: ""
    },
    {
      id: 13,
      question: "Vents and overflow openings functional",
      answer: ""
    },
    {
      id: 14,
      question: "Valves fully close and are free of leaks",
      answer: ""
    },
    {
      id: 15,
      question: "Faucet aerators / screens free of grit and other debris",
      answer: ""
    },
    {
      id: 16,
      question: "Supply pressure adequate for proper functioning of fixture",
      answer: ""
    },
    {
      id: 17,
      question: "Automatic operation sensors functional",
      answer: ""
    },
    {
      id: 18,
      question: "Operational set points noted in Operation and Maintenance Manuals",
      answer: ""
    },
    {
      id: 19,
      question: "Service Manuals supplied to Owner",
      answer: ""
    },
    {
      id: 20,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },

  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})

export const fireStoppingChecklist = () => ({
  type: 'Fire Stopping Checklist',
  fieldTasks: [
    {
      id: 1,
      question: "Approved firestop for this project was used",
      answer: ""
    },
    {
      id: 2,
      question: "Approved firestop installer present at the time of installation",
      answer: "",
    },
    {
      id: 3,
      question: "Deliver materials undamaged in manufacturer's clearly labeled",
      answer: ""
    },
    {
      id: 4,
      question: "Compliance with initial job-ready requirements",
      answer: ""
    },
    {
      id: 5,
      question: "Compliance with material inspection and tests",
      answer: ""
    },
    {
      id: 6,
      question: "Compliance with work in process first article inspection requirements",
      answer: ""
    },
    {
      id: 7,
      question: "Compliance with work in process inspection requirements",
      answer: ""
    },
    {
      id: 8,
      question: "Compliance with work task completion inspection requirements",
      answer: ""
    },
    {
      id: 9,
      question: "Compliance with inspection and test plan",
      answer: ""
    },
    {
      id: 10,
      question: "Compliance with inspection and test plan",
      answer: ""
    },
    {
      id: 11,
      question: "Work task verified complete to specifications",
      answer: ""
    },
    {
      id: 12,
      question: "Insert items identified at project startup and preparatory meetings",
      answer: ""
    },
    {
      id: 13,
      question: "The opening to be sealed was clean",
      answer: ""
    },
    {
      id: 14,
      question: "Mineral wool pack in opening (if applicable for this application)",
      answer: ""
    },
    {
      id: 15,
      question: "Firestop was applied to the required depth in order to obtain the desirer fire rating",
      answer: ""
    },
    {
      id: 16,
      question: "Reported Nonconformances and incomplete items:",
      answer: ""
    },

  ],
  contractNo: "",
  description: "",
  contractor: "",
  sub_contractor: "",
  locationOfWork: "",
  date: "",
  preparedBy: "",
  datePrepared: "",
  inspectorName: "",
  surveillanceReport: "",
  drawing: "",
  remarks: "",
  nonconformanceRemarks: "",

  additionalRemarks: {
    text: "",
    name1: "",
    signature1: "",
    title1: "",
    date1: "",
    name2: "",
    signature2: "",
    title2: "",
    date2: "",
    name3: "",
    signature3: "",
    title3: "",
    date3: "",
  },
})