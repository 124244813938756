import axios from "axios";

const devUrl = "http://localhost:9000/email-service";
const prodUrl = "https://server.workforce-daily-report.com/email-service";

const URL = process.env.NODE_ENV === "development" ? devUrl : prodUrl;
const emailSource = "no-reply@workforce-daily-report.com";
const instance = axios.create({
  baseURL: URL,
});

export const saveChecklistPdf = async () => {
  try {
    let pdf = await instance.get(`/pdf/checklist-pdf`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const emailReport = async (recipient: string, emailBody: string, attachment?: any, link?: string) => {
  const formData = new FormData();
  formData.append("attachment", attachment);
  formData.append("emailBody", emailBody);
  formData.append("messageRecipient", recipient);

  const sendEmailRes = await instance.post("/attachment-email", formData, { headers: { "Content-Type": "multipart/form-data", Accept: "application/json" } });
};
