import React, { useEffect, useState, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getIntCode, confirmUser, setUserActive } from "../../../api/auth";

import "./ConfirmUser.scss";

type TAuthCodes = number[];

const ConfirmUser = () => {
  const [codes, setCodes] = useState<TAuthCodes>([]);
  const [intCode, setIntCode] = useState<TAuthCodes>([]);
  const [codesCompare, setCodesCompare] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [shouldResentCode, setShouldResendCode] = useState<boolean>(false);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const fetchIntCode = async () => {
    const codes = await getIntCode();
    const temp = codes.data;
    const tempArr: number[] = [];
    for (let i = 0; i < temp.length; i++) {
      if (!isNaN(temp[i])) {
        tempArr.push(parseInt(temp[i]));
      }
    }
    setIntCode(tempArr);
  };

  useEffect(() => {
    fetchIntCode();
  }, [setIntCode]);

  const BreakException = {};

  const activateUser = async () => {
    await setUserActive(query.get("useremail")!);
  };

  const compareCodes = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (codes.length !== intCode.length) {
      alert("Codes do not match.");
      throw BreakException;
    }
    codes.sort();
    intCode?.sort();
    for (let i = 0; i < codes.length; i++) {
      if (codes[i] !== intCode[i]) {
        alert("Codes do not match..");
        setCodesCompare(false);
        setShouldResendCode(true);
        setCodes([]);
        throw BreakException;
      }
    }
    alert("Authorization Code Successful");
    setCodesCompare(true);
    activateUser();
    return;
  };

  const resendCodes = async (e: React.FormEvent<HTMLFormElement>) => {
    const res = confirmUser(email);
    alert("Email successfully sent.  Please check our junk mail as well.");
    setEmail("");
  };

  const renderAuthForm = () => {
    return (
      <form onSubmit={e => compareCodes(e)} className="confirmuser-authform">
        <label className="confirmuser-authform-codelabel" htmlFor="code-input">
          Enter 6 Digit Code Sent By Email
        </label>
        <div className="confirmuser-authform-codeinputs">
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[0]) ? codes[0] : ""}
            name="codeinput1"
            ref={inputRef1}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[0] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
            onKeyUp={e => {
              if (codes[0] && !isNaN(codes[0])) {
                inputRef2.current?.focus();
              }
            }}
          />
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[1]) ? codes[1] : ""}
            name="codeinput2"
            ref={inputRef2}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[1] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
            onKeyUp={e => {
              if (codes[1] && !isNaN(codes[1])) {
                inputRef3.current?.focus();
              }
            }}
          />
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[2]) ? codes[2] : ""}
            name="codeinput3"
            ref={inputRef3}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[2] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
            onKeyUp={e => {
              if (codes[2] && !isNaN(codes[2])) {
                inputRef4.current?.focus();
              }
            }}
          />
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[3]) ? codes[3] : ""}
            name="codeinput4"
            ref={inputRef4}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[3] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
            onKeyUp={e => {
              if (codes[3] && !isNaN(codes[3])) {
                inputRef5.current?.focus();
              }
            }}
          />
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[4]) ? codes[4] : ""}
            name="codeinput5"
            ref={inputRef5}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[4] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
            onKeyUp={e => {
              if (codes[4] && !isNaN(codes[4])) {
                inputRef6.current?.focus();
              }
            }}
          />
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-input"
            type="text"
            pattern="\d*"
            maxLength={1}
            value={!isNaN(codes[5]) ? codes[5] : ""}
            name="codeinput1"
            ref={inputRef6}
            onChange={e => {
              const codesArr = [...codes];
              codesArr[5] = parseInt(e.target.value);
              setCodes(codesArr);
            }}
          />
        </div>
        <button className="confirmuser-authform-submitbtn" type="submit">
          Submit
        </button>
      </form>
    );
  };

  const renderResendForm = () => {
    return (
      <form onSubmit={e => resendCodes(e)} className="confirmuser-authform">
        <label className="confirmuser-authform-codelabel" htmlFor="code-input">
          Enter Email
        </label>
        <div className="confirmuser-authform-codeinputs">
          <input
            id="code-input"
            className="confirmuser-authform-codeinputs-emailinput"
            type="email"
            value={email}
            name="email"
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <button className="confirmuser-authform-submitbtn" type="submit">
          Resend Codes
        </button>
      </form>
    );
  };

  if (codesCompare) {
    return <Navigate to="/user-login" />;
  }

  return <div className="confirmuser">{!shouldResentCode ? renderAuthForm() : renderResendForm()}</div>;
};

export default ConfirmUser;
