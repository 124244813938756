import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";

import { getUser } from "../../api/auth";
import { getChecklistData, getInitChecklistData, updateChecklistData, updateChecklistDate, updateChecklistDatePrepared } from "../../api/checklists";
import { getShiftData, updateStartDate, updateTaskCompletion, updateWorkType } from "../../api/si";
import { getForemanData } from "../../graphql/queries";

import { ForemanData, Job, WorksiteEmployees } from "../../types";
import "./Header.scss";

interface HeaderProps {
  headerType?: string;
  checklistHeader?: string;
  site: {
    contractNo: string;
    agency: {
      agencyName: string;
    };
    location: string;
    jobName: string;
    startDate: string;
    taskCompletionDate: string;
    shift: string;
  };
  workShift: (s: string) => void;
}

enum Shifts {
  SHIFT1 = 1,
  SHIFT2,
  SHIFT3,
}

enum TypeOfWork {
  contract = "CONTRACT",
  changeOrder = "CHANGE ORDER",
  tAndM = "T & M",
  extraWork = "EXTRA WORK",
}

const EditReportHeader: React.FC<HeaderProps> = ({ site, workShift, headerType, checklistHeader }) => {
  const [showLowerHeader, setShowLowerHeader] = useState(true);
  const [shift, setShift] = useState<string>("");
  const [shiftStartDate, setShiftStartDate] = useState<Date | null>(null);
  const [taskCompletion, setTaskCompletion] = useState<Date | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [foremanData, setForemanData] = useState<ForemanData | null>(null);
  const [checklistHeaderData, setChecklistHeaderData] = useState<Job | null | undefined>(null);

  const [checklistDate, setChecklistDate] = useState<Date | null>(null);
  const [checklistDatePrepared, setChecklistDatePrepared] = useState<Date | null>(null);

  const handleShiftChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const shiftVal = e.target.value;
    setShift(shiftVal);
    workShift(shiftVal);
    const updatedShiftData = await getShiftData(jobFocus);
    setShiftData(updatedShiftData);
  };
  const fetchInitChecklistData = async () => {
    try {
      const _checklistData = await getChecklistData();
      if (_checklistData.error) {
        console.log(error);
      } else {
        setChecklistHeaderData(_checklistData);
      }
      const initForemanChecklistData = await getInitChecklistData();
      setForemanData(initForemanChecklistData);
    } catch (error) {
      console.log(error);
    }
  };

  const jobFocus = {
    jobName: site?.jobName,
    shift: site?.shift,
  };

  useEffect(() => {
    setShiftData(site);
    if (site?.shift) {
      setShift(site?.shift);
    }
    if (site?.startDate) {
      setShiftStartDate(new Date(site?.startDate));
    }
    if (site?.taskCompletionDate) {
      setTaskCompletion(new Date(site?.taskCompletionDate));
    }
  }, [site]);

  // useEffect(() => {
  //   getForemanData(getUser().userId)
  //     .then(data => {
  //       setForemanData({ ...data });
  //     })
  //     .catch(error => {
  //       console.log({ ERROR: error.message });
  //     });
  //   if (headerType === "checklist") {
  //     fetchInitChecklistData();
  //   }
  // }, []);

  const saveChecklistDatePrepared = async (val: Date) => {
    const updateChecklistData = await updateChecklistDatePrepared(val);
    setChecklistHeaderData(updateChecklistData);
    setChecklistDatePrepared(val);
  };

  const saveChecklistDate = async (val: Date) => {
    const updateChecklistData = await updateChecklistDate(val);
    setChecklistHeaderData(updateChecklistData);
    setChecklistDate(val);
  };

  return (
    <div className="header">
      <div className="header-1">
        {headerType !== "checklist" && (
          <>
            <div className="header-1-col1">
              <div>{shiftData?.contractNo}</div>
              <div>{shiftData?.agency?.agencyName}</div>
            </div>
            <div className="header-1-col2">
              <label htmlFor="shift">Shift</label>
              <select name="shift" id="shift" onChange={handleShiftChange} value={shift}>
                <option value="">Select a Shift</option>
                <option value={Shifts.SHIFT1}>{Shifts.SHIFT1}</option>
                <option value={Shifts.SHIFT2}>{Shifts.SHIFT2}</option>
                <option value={Shifts.SHIFT3}>{Shifts.SHIFT3}</option>
              </select>
            </div>
            <div className="header-1-col3">
              <div className="header-1-col3-date">
                <label>CONTRACT START DATE:</label>
                <p>{new Date(shiftData?.contractStartDate).toDateString()}</p>
              </div>
            </div>
          </>
        )}
      </div>
      {headerType !== "checklist" && (
        <div className="header-divider">
          <FontAwesomeIcon className="header-divider-icon" icon={showLowerHeader ? faChevronUp : faChevronDown} onClick={() => setShowLowerHeader(!showLowerHeader)} />
        </div>
      )}
      {showLowerHeader && (
        <>
          <div className="header-2">
            {headerType !== "checklist" ? (
              <>
                <div className="header-2-col1">
                  <label className="header-2-col1-label">LOCATION:</label>
                  <div className="header-2-col1-location">{shiftData?.location}</div>
                </div>
                <div className="header-2-col2">
                  <label className="header-2-col2-label">JOB NAME:</label>
                  <div className="header-2-col2-jobname">{shiftData?.jobName}</div>
                </div>
              </>
            ) : (
              <>
                <div className="header-2-col2checklist-wrapper">
                  <div className="header-2-col2checklist">
                    <select
                      name="agency"
                      id="agency"
                      // value={checklistHeaderData ? checklistHeaderData?.agency?.agencyName : `${"Select an agency"}`}
                      onChange={async e => {
                        // setChecklistHeaderData(foremanData?.jobs[Number(e.target.value)].job)
                        try {
                          const updatedChecklistData = await updateChecklistData({
                            agency: foremanData?.jobs[Number(e.target.value)].job.agency,
                            location: foremanData?.jobs[Number(e.target.value)].job.location,
                          });
                          if (updatedChecklistData!) {
                            setChecklistHeaderData(updatedChecklistData);
                          }
                        } catch (error: any) {
                          setError(error?.message);
                        }
                      }}
                    >
                      {!checklistHeaderData?.agency?.agencyName ? <option>Select Agency</option> : <option>{checklistHeaderData?.agency?.agencyName}</option>}
                      {foremanData?.jobs.map((data: WorksiteEmployees, idx) => {
                        return (
                          <option key={idx} value={idx}>
                            {data.job.agency.agencyName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="header-2-col2checklist">
                    <label className="header-2-col2checklist-label">LOCATION:</label>
                    <div className="header-2-col2checklist-location">{checklistHeaderData?.location}</div>
                  </div>
                  {/* <div className="header-2-col2checklist">
                        <label className="header-2-col2checklist-label">JOB NAME:</label>
                        <div className="header-2-col2checklist-jobname">{shiftData?.jobName}</div>
                      </div> */}
                </div>
              </>
            )}
          </div>
          <div className="header-3">
            <div className="header-3-col1">
              {headerType !== "checklist" && (
                <>
                  <label htmlFor="typeOfWork">WORK TYPE</label>
                  <select
                    className="header-3-col1-worktype"
                    name="typeOfWork"
                    id="typeOfWork"
                    onChange={async e => {
                      try {
                        await updateWorkType(e.target.value, jobFocus);
                        const job = await getShiftData(jobFocus);
                        setShiftData(job);
                      } catch (error) {
                        alert("Please Enter Job Shift");
                      }
                    }}
                    value={shiftData?.workType}
                  >
                    <option value="">Select Work Type</option>
                    <option value={TypeOfWork.contract}>{TypeOfWork.contract}</option>
                    <option value={TypeOfWork.changeOrder}>{TypeOfWork.changeOrder}</option>
                    <option value={TypeOfWork.tAndM}>{TypeOfWork.tAndM}</option>
                    <option value={TypeOfWork.extraWork}>{TypeOfWork.extraWork}</option>
                  </select>
                </>
              )}
            </div>
            <div className="header-3-col2">
              {headerType !== "checklist" ? (
                <>
                  <div className="header-3-col2-date">
                    <label>START DATE:</label>
                    <DateTimePicker className="header-3-col2-date-picker" value={shiftStartDate!} />
                  </div>
                  <div className="header-3-col2-date">
                    <label>TASK COMPLETION:</label>
                    <DateTimePicker className="header-3-col2-date-picker" value={taskCompletion!} />
                  </div>
                </>
              ) : (
                <>
                  <div className="header-3-col2-date-checklist">
                    <label>DATE:</label>
                    <DateTimePicker
                      className="header-3-col2-date-picker-checklist"
                      onChange={(val: any) => {
                        saveChecklistDate(val);
                      }}
                      value={checklistDate!}
                    />
                  </div>
                  <div className="header-3-col2-date-checklist">
                    <label>DATE PREPARED:</label>
                    <DateTimePicker
                      className="header-3-col2-date-picker-checklist"
                      onChange={(val: any) => {
                        saveChecklistDatePrepared(val);
                      }}
                      value={checklistDatePrepared!}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditReportHeader;
