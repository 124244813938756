import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Document, Page } from "react-pdf";
import { getJobImages } from "../../../api/images";
import { getFocus, getMaterialDescRowImgIds, updateMaterialDescRowImages } from "../../../api/si";
import { getJobs } from "../../../graphql/queries";
import day from "dayjs";
import AdminMenu from "../AdminMenu";
import { getUser } from "../../../api/auth";
import { getAllPdfs, removePdfs } from "../../../api/pdfAttachments";

import "./PdfAttachments.scss";
import { Navigate, useNavigate } from "react-router-dom";
import { ICurrentUser, IPdfs } from "../../../types";
import Button1 from "../../../common/button1/Button1";

const PdfAttachments: React.FC = () => {
  const [attachments, setAttachments] = useState<IPdfs[] | null>(null);
  const [updatedAttachments, setUpdatedAttachments] = useState<IPdfs[] | null>(null);
  const [selectedPdf, setSelectedPdf] = useState<IPdfs | null>(null);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [file, setFile] = useState<Buffer | null>(null);
  const [user, setUser] = useState<ICurrentUser | null>(null);
  const [selectedPdfs, setSelectedPdfs] = useState<IPdfs[]>([]);
  const [shouldSelectMultiple, setShouldSelectMultiple] = useState<boolean>(false);

  const fetchAttachments = async () => {
    const a = await getAllPdfs();
    if (a) {
      setAttachments(a.data);
    }
  };

  useEffect(() => {
    setUser(getUser());
    fetchAttachments();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const handleDeletePdfs = () => {
    if (selectedPdfs.length > 0) {
      selectedPdfs.forEach(async (p: IPdfs) => {
        const resData = await removePdfs(p._id!);
        // console.log("DELETE RES: ", resData);
      });
    }
  };

  const renderPdfList = () => {
    return (
      <div className="pdfattachments-listcontainer">
        <ul className="pdfattachments-listcontainer-list">
          {attachments?.map((a: IPdfs, idx: number) => {
            return (
              <li
                key={idx}
                className={`pdfattachments-listcontainer-list-item ${selectedPdfs?.find((p: IPdfs) => p._id === a._id) && "selectedpdf"}`}
                onClick={() => {
                  if (!shouldSelectMultiple) {
                    setSelectedPdf(a);
                    setShowSelected(true);
                  } else if (shouldSelectMultiple) {
                    const foundData = selectedPdfs?.find((p: IPdfs) => p._id === a._id);
                    if (!foundData) {
                      setSelectedPdfs([...selectedPdfs!, a]);
                      const data = [...updatedAttachments!];
                      const idx = data.findIndex((b: IPdfs) => b._id === a._id);
                      data.splice(idx, 1);
                      setUpdatedAttachments(data);
                    }
                  }
                }}
              >
                <div className="pdfattachments-listcontainer-list-item-doc">
                  <div>
                    <h4>{a.name}</h4>
                    <h4>
                      <span>Job: </span>
                      {a.jobName}
                    </h4>
                  </div>
                  <Document className="pdfattachments-listcontainer-list-item-doc-document" file={a.file} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(1))
                      .map((x: any, i: number) => i + 1)
                      .map((page: any, idx: number) => (
                        <Page className="pdfattachments-listcontainer-list-item-doc-document-page" key={idx} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} width={150} />
                      ))}
                  </Document>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderSelectePdf = () => {
    return (
      <div className="pdfattachments-detailscontainer">
        <div className="pdfattachments-detailscontainer-controls">
          <Button1
            btnClassName="pdfattachments-detailscontainer-controls-backbtn"
            btnName="backBtn"
            btnType="button"
            btnValue="Go Back"
            onclick={() => {
              setShowSelected(false);
              setSelectedPdf(null);
            }}
          />
        </div>

        <div className="pdfattachments-detailscontainer-header">
          <div className="pdfattachments-detailscontainer-header-name">
            <span className="pdfattachments-detailscontainer-header-name-label">Name: </span>
            {selectedPdf?.name}
          </div>
          <div className="pdfattachments-detailscontainer-header-jobname">
            <span className="pdfattachments-detailscontainer-header-jobname-label">Job Name: </span>
            {selectedPdf?.jobName || "N/A"}
          </div>
          <div className="pdfattachments-detailscontainer-header-notes">
            <span className="pdfattachments-detailscontainer-header-notes-label">Notes: </span>
            {selectedPdf?.txt}
          </div>
        </div>
        <div className="pdfattachments-detailscontainer-document">
          <Document className="pdfattachments-detailscontainer-document-doc" file={selectedPdf?.file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.apply(null, Array(numPages))
              .map((x: any, i: number) => i + 1)
              .map((page: any, idx: number) => (
                <Page className="pdfattachments-detailscontainer-document-doc-page" key={idx} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
              ))}
          </Document>
        </div>
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  return (
    <div className="pdfattachments">
      {user?.role === "ADMIN" && <AdminMenu />}
      <div className="pdfattachments-header">
        <h1 className="pdfattachments-header-title">PDF Attachments</h1>
      </div>
      <div className="pdfattachments-controls">
        {!showSelected && (
          <Button1
            btnClassName="pdfattachments-controls-selectbtn"
            btnName="selectMultiple"
            btnType="button"
            btnValue={shouldSelectMultiple ? " Cancel" : "Select Multiple"}
            onclick={() => {
              setShouldSelectMultiple(!shouldSelectMultiple);
              setSelectedPdfs([]);
              setUpdatedAttachments([...attachments!]);
            }}
          />
        )}

        {shouldSelectMultiple && (
          <Button1
            btnClassName="pdfattachments-controls-deletebtn"
            btnName="deleteBtn"
            btnType="button"
            btnValue="Remove Selected"
            onclick={() => {
              setAttachments(updatedAttachments);
              setShouldSelectMultiple(false);
              handleDeletePdfs();
            }}
          />
        )}
      </div>
      {showSelected ? renderSelectePdf() : renderPdfList()}
    </div>
  );
};

export default PdfAttachments;
