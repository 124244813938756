import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";

const PdfGenerator: React.FC = () => {
  const [clReport, setClReport] = useState<any | null>(null);

  const getCLReport = async () => {
    if (localStorage.getItem("CLReport")) {
      const clr = await JSON.parse(localStorage.getItem("CLReport")!);
      setClReport(clr);
      if (clr) {
        // printPDF();
      }
    }
  }

  useEffect(() => {
    getCLReport()
  }, [])



  console.log(clReport);
  // jspdf GENERATOR FUNCTION
  const jsPdfGenerator = async () => {
    // New doc in jspdf
    let doc = new jsPDF('p', 'pt');

    // Add some text...
    // doc.setLineWidth(1);
    // doc.rect(10, 30, 250, 75);
    // doc.rect(260, 30, 250, 75);
    // doc.text(`${clReport?.checklistType}`, 20, 40);
    // doc.text('BISSETTA & LIST, INC.', 20, 42);
    // doc.text('BISSETTA & LIST, INC.', 20, 52);
    // doc.text('BISSETTA & LIST, INC.', 20, 62);

    doc.html(await document.getElementById("pdf-content")!, {
      callback: async (doc) => {
        doc.save('TESTpdF')
      }
    })

    // doc.save("Generated")
  }

  return (
    <div>
      <button onClick={jsPdfGenerator}>Generate PDF</button>
      <div id="pdf-content">Hello PDF...</div>
    </div>
  )
}

export default PdfGenerator;