import React, { useState } from 'react';

import './EmailChecklist.scss';

interface EmailChecklistProps {
  sendEmail: () => void;
  handleRecipientInput: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  emailRecipient: string;
  emailBody: string
}

const EmailChecklist: React.FC<EmailChecklistProps> = ({ sendEmail, handleRecipientInput, emailRecipient, emailBody }) => {
  const [emailData, setEmailData] = useState<{ recipient: string, body: string, attachment?: string }>({
    recipient: "",
    body: "",
  })


  return (
    <div className='emailchecklist'>
      <form className='emailchecklist-form'>
        <div className='emailchecklist-form-grp'>
          <label className='emailchecklist-form-grp-label' htmlFor="email-recipient">Email Recipients</label>
          <input
            className='emailchecklist-form-grp-input'
            placeholder='jane.doe@email.com, jon.doe@email.com'
            type="email"
            name="recipient"
            value={emailRecipient}
            onChange={e => {
              e.preventDefault();
              setEmailData({
                ...emailData,
                recipient: e.target.value
              })
              handleRecipientInput(e);
            }}
          />
        </div>
        <div className='emailchecklist-form-grp emailbody'>
          <label className='emailchecklist-form-grp-label' htmlFor="email-body">Message (Optional)</label>
          <textarea
            className='emailchecklist-form-grp-textarea'
            placeholder='Add message body here...'
            name='body'
            rows={30}
            value={emailBody}
            onChange={e => {
              e.preventDefault();
              setEmailData({
                ...emailData,
                body: e.target.value
              })
              handleRecipientInput(e);
            }}
          />
        </div>
        <button
          className='emailchecklist-form-sendbtn'
          type='submit'
          onClick={e => {
            e.preventDefault();
            sendEmail();
          }}
        >
          Send
        </button>
      </form>
    </div>
  )
}

export default EmailChecklist;