import React, { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button1 from "../button1/Button1";

import "./Modal.scss";

interface ModalProps {
  modalType: string;
  notesModalData?: {
    id: string;
    firstName: string;
    lastName: string;
    notes: string;
  };
  timeModalData?: {
    id: string;
    firstName: string;
    lastName: string;
    notes: string;
    type: string;
  };
  signaturesData?: {
    id: string;
    projectManager: string;
    contractorRep: string;
    agencyRep: string;
    type: string;
  };
  dtModalData?: {
    firstName: string;
    lastName: string;
    id: string;
    type: string;
  };
  notesValue?: string | number | undefined;

  handleNotesChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSaveNotes?: () => JSX.Element | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | void;
  onCancelNotes?: () => void;
  onSaveTime?: (data?: string) => void | undefined;
  onSaveDT?: (data?: string) => void | undefined;
  onCancelDT?: () => void;
  onCancelTime?: () => void;
  onSaveSignatures?: () => void;
  onCancelSignatures?: () => void;
  onSavePMSignature?: (data: string) => void;
  onSaveContractorRepSig?: (data: string) => void;
  onSaveAgencyRepSig?: (data: string) => void;
  handlePMTextChange?: () => void;
  handleCRTextChange?: () => void;
  handleARTextChange?: () => void;
  getSigData?: (data?: string) => void;
}

enum ModalTypes {
  NOTES = "notes",
  TIME = "time",
  SIGNATURES = "signatures",
  DOUBLE_TIME = "doubleTime",
}

const Modal: React.FC<ModalProps> = ({ modalType, notesModalData, timeModalData, signaturesData, dtModalData, onSaveNotes, onCancelNotes, handleNotesChange, onSaveTime, onCancelTime, onSaveSignatures, onCancelSignatures, onSavePMSignature, onSaveAgencyRepSig, onSaveContractorRepSig, handlePMTextChange, handleCRTextChange, notesValue, handleARTextChange, getSigData, onSaveDT, onCancelDT }) => {
  const [notesValCache, setNotesValCache] = useState<string | number | undefined>("");
  const [doubletime, setDoubletime] = useState<string>("");

  useEffect(() => {
    if (notesValue) {
      setNotesValCache(notesValue);
    }
  }, []);
  let sigPad: SignatureCanvas | null;

  const clearSignature = () => {
    sigPad?.clear();
  };

  const trimSignature = (type?: string) => {
    if (getSigData) {
      const signatureData = sigPad?.getTrimmedCanvas().toDataURL(`${timeModalData?.firstName}_${timeModalData?.lastName}_${new Date().toLocaleString()}/png`);
      getSigData(signatureData);
    }
  };

  const _trimSignature = (type?: string) => {
    const signatureData = sigPad?.getTrimmedCanvas().toDataURL(`${new Date().toLocaleString()}/png`);
    return signatureData;
  };

  const notesModal = () => (
    <div className="notesmodal">
      <p>
        {notesModalData?.firstName} {notesModalData?.lastName}
      </p>
      <p>Notes</p>
      <p>{new Date().toLocaleString()}</p>
      <textarea
        onChange={e => {
          handleNotesChange && handleNotesChange(e);
        }}
        name="notes-text"
        id=""
        cols={30}
        rows={10}
        // placeholder={notesValue ? `${notesValue}` : ""}
        value={notesValue}
      ></textarea>
      <div className="notesmodal-buttons">
        <Button1 btnClassName="notesmodal-buttons-save" btnType="button" onclick={onSaveNotes!} btnName="saveModalNotes" btnValue="Save" />

        <Button1 btnClassName="notesmodal-buttons-cancel" btnType="button" onclick={onCancelNotes!} btnName="cancelModalNotes" btnValue="Cancel" />
      </div>
    </div>
  );

  const timeModal = () => (
    <div className="timemodal">
      <p>
        {timeModalData?.firstName} {timeModalData?.lastName}
      </p>
      <p>{timeModalData?.type === "timeIn" ? "IN" : "OUT"}</p>
      <p>{new Date().toLocaleString()}</p>
      <SignatureCanvas
        penColor="black"
        // velocityFilterWeight={2.5}
        canvasProps={{
          width: 500,
          height: 200,
          className: "timemodal-sigcanvas",
        }}
        ref={ref => (sigPad = ref)}
      />
      <div className="timemodal-buttons">
        <Button1
          btnClassName="timemodal-buttons-confirm"
          btnType="button"
          onclick={() => {
            if (onSaveTime) {
              trimSignature();
              onSaveTime();
            }
          }}
          btnName="saveTimeModal"
          btnValue="Confirm"
        />

        <Button1 btnClassName="timemodal-buttons-cancel" btnType="button" onclick={onCancelTime!} btnName="cancelTimeModal" btnValue="Cancel" />
      </div>
    </div>
  );

  const signaturesModal = () => (
    <div className="signaturesModal">
      <div className="signaturesModal-sigwrapper">
        <div className="signaturesModal-sigwrapper-item">
          <label htmlFor="pm-sig">Signature Project Manager/Foreman</label>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "signaturesModal-sigcanvas",
              id: "pm-sig",
            }}
            ref={ref => (sigPad = ref)}
          />
        </div>
        <Button1
          btnClassName="signaturesModal-sigwrapper-savebtn"
          btnType="button"
          onclick={() => {
            if (onSavePMSignature) {
              onSavePMSignature(_trimSignature()!);
            }
          }}
          btnName="saveSignaturesModal"
          btnValue="Save"
        />
      </div>
      <div className="signaturesModal-sigwrapper">
        <div className="signaturesModal-sigwrapper-item">
          <label htmlFor="pm-name">Name/Title: Project Manager/Foreman</label>
          <p className="signaturesModal-sigwrapper-PM">{signaturesData?.projectManager}</p>
        </div>
      </div>
      <div className="signaturesModal-sigwrapper">
        <div className="signaturesModal-sigwrapper-item">
          <label htmlFor="pm">Signature (Contractor Representative)</label>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "signaturesModal-sigcanvas",
            }}
            ref={ref => (sigPad = ref)}
          />
        </div>
        <Button1
          btnClassName="signaturesModal-sigwrapper-savebtn"
          btnType="button"
          onclick={() => {
            if (onSaveContractorRepSig) {
              onSaveContractorRepSig(_trimSignature()!);
            }
          }}
          btnName="saveSignaturesModal"
          btnValue="Save"
        />
      </div>
      <div className="signaturesModal-sigwrapper">
        <div className="signaturesModal-sigwrapper-item">
          <label htmlFor="pm">Signature (Agency Representative)</label>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "signaturesModal-sigcanvas",
            }}
            ref={ref => (sigPad = ref)}
          />
        </div>
        <Button1
          btnClassName="signaturesModal-sigwrapper-savebtn"
          btnType="button"
          onclick={() => {
            if (onSaveAgencyRepSig) {
              onSaveAgencyRepSig(_trimSignature()!);
            }
          }}
          btnName="saveSignaturesModal"
          btnValue="Save"
        />
      </div>
      <p className="signaturesModal-date">{new Date().toLocaleString()}</p>
      <div className="signaturesModal-buttons">
        <Button1 btnClassName="signaturesModal-buttons-confirm" btnType="button" onclick={onSaveSignatures!} btnName="saveSignaturesModal" btnValue="Done" />

        <Button1 btnClassName="signaturesModal-buttons-cancel" btnType="button" onclick={onCancelSignatures!} btnName="cancelSignaturesModal" btnValue="Cancel" />
      </div>
    </div>
  );

  const doubleTimeModal = () => (
    <div className="doubletimeModal">
      <p>
        {dtModalData?.firstName} {dtModalData?.lastName}
      </p>
      <p>DOUBLE TIME</p>

      <input
        className="doubletimeModal-input"
        type="number"
        step="any"
        value={doubletime! || ""}
        onChange={e => {
          console.log("DOUBLETIME FLOAT: ", e.target.value);
          setDoubletime(e.target.value);
        }}
      />
      <div className="timemodal-buttons">
        <Button1
          btnClassName="timemodal-buttons-confirm"
          btnType="button"
          onclick={() => {
            if (onSaveDT) {
              trimSignature();
              onSaveDT(doubletime);
            }
          }}
          btnName="saveTimeModal"
          btnValue="Confirm"
        />

        <Button1
          btnClassName="timemodal-buttons-cancel"
          btnType="button"
          onclick={() => {
            if (onCancelDT) {
              onCancelDT();
            }
          }}
          btnName="cancelTimeModal"
          btnValue="Cancel"
        />
      </div>
    </div>
  );

  return <>{modalType === ModalTypes.NOTES ? notesModal() : modalType === ModalTypes.TIME ? timeModal() : modalType === ModalTypes.SIGNATURES ? signaturesModal() : modalType === ModalTypes.DOUBLE_TIME && doubleTimeModal()}</>;
};

export default Modal;
