import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button1 from "../../../common/button1/Button1";

import { getUser } from "../../../api/auth";
import { createInitChecklistData, getChecklistData, updateChecklistData } from "../../../api/checklists";
import { getFocus, getShiftData } from "../../../api/si";
import { getForemanData, createChecklistCreator } from "../../../graphql/queries";

import { checklistType, domesticPortableWaterDistributionChecklist, electricDomesticWaterHeatersChecklist, fireStoppingChecklist, fuelFiredDomesticWaterHeatersChecklist, generalChecklist, insulationChecklist, plumbingFixturesChecklist, potableWaterStorageTanksChecklist, sanitaryDrainageChecklist, stormDrainageChecklist } from "../../checklist/Checklists";

import { ForemanData } from "../../../types";

import "./ChecklistCreator.scss";

const ChecklistCreator: React.FC = () => {
  const [checklist, setChecklist] = useState<checklistType | null | undefined>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [hasSaved, setHasSaved] = useState<boolean>(false);
  const [checklistFieldValues, setChecklistFieldValues] = useState<any | null>(null);
  const [blankChecklistFieldValues, setBlankChecklistFieldValues] = useState<{ id: number; question: string; answer: string }[]>([
    {
      id: 0,
      question: "",
      answer: "",
    },
  ]);

  const [fieldTasks, setFieldTasks] = useState<any[]>([]);

  const [addChecklistCreator, { data, loading, error }] = useMutation(createChecklistCreator);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const val = e.target.value;
    setChecklistFieldValues({ ...checklistFieldValues, [`${name}`]: val });
  };

  console.log("CHECKLIST FIELD VALUES: ", checklistFieldValues);
  const renderTopForm = () => (
    <div className="checklist-topform">
      <div className="checklist-topform-inputgroup">
        <label className="checklist-topform-inputgroup-label" htmlFor="">
          Checklist Type:
        </label>
        <input className="checklist-topform-inputgroup-input" name="type" value={checklistFieldValues?.type} type="text" onChange={handleInputChange} placeholder="Add Checklist Type Here..." />
      </div>
    </div>
  );

  const handleBlankFieldTasksChange = (idx: number, e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target!;
    const blankFieldTaskRows = [...blankChecklistFieldValues];
    blankFieldTaskRows[idx] = {
      id: idx + 1,
      question: name === "question" ? value : blankChecklistFieldValues[idx].question,
      answer: "",
    };
    setBlankChecklistFieldValues(blankFieldTaskRows);
  };

  const addBlankFieldTaskrow = () => {
    const item: { id: number; question: string; answer: string } = {
      id: 0,
      question: "",
      answer: "",
    };
    setBlankChecklistFieldValues([...blankChecklistFieldValues!, item]);
  };

  const removeBlankFieldTaskrow = (idx: number) => {
    const updatedBlankFieldTasks = blankChecklistFieldValues;
    updatedBlankFieldTasks.splice(idx, 1);
    setBlankChecklistFieldValues(prevState => updatedBlankFieldTasks);

    const updatedTasks = fieldTasks;
    updatedTasks.splice(idx, 1);
    setFieldTasks(prevState => updatedTasks);
    console.log("NEW FIELD TASKS: ", blankChecklistFieldValues);
    addBlankFieldTaskrow();
  };

  // useEffect(() => {
  //   blankChecklistFieldValues;
  // }, [blankChecklistFieldValues]);

  const renderBlankFieldTasks = () => (
    <>
      <div className="checklist-blankfields-add">
        <button
          className="checklist-blankfields-add-btn"
          onClick={async () => {
            setFieldTasks(blankChecklistFieldValues);
            setHasSaved(true);
          }}
        >
          Save
        </button>
        <FontAwesomeIcon
          className="checklist-blankfields-add-icon"
          icon={faPlus}
          onClick={async () => {
            addBlankFieldTaskrow();
            setFieldTasks(blankChecklistFieldValues);
          }}
        />
      </div>
      <ul className="checklist-fieldtasks">
        {blankChecklistFieldValues?.map((task: { question: string; answer: string; id: number }, idx) => {
          return (
            <li key={idx} className="checklist-fieldtasks-items">
              <span className="checklist-fieldtasks-items-number">{idx + 1}.</span>
              <input className="checklist-fieldtasks-items-question" type="text" name="question" onChange={e => handleBlankFieldTasksChange(idx, e)} value={blankChecklistFieldValues[idx]?.question} />
              <div className="checklist-fieldtasks-items-question-removewrapper">
                <FontAwesomeIcon
                  className="checklist-fieldtasks-items-question-removewrapper-removeicon"
                  icon={faMinus}
                  onClick={() => {
                    removeBlankFieldTaskrow(idx);
                  }}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );

  const handleSubmitChecklist = async () => {
    checklistFieldValues.fieldTasks = fieldTasks;
    console.log("SUBMITTING CHECKLIST FIELD VALUES: ", checklistFieldValues);
    await addChecklistCreator({ variables: { input: checklistFieldValues } });
    alert("Checklist Successfully Submitted");
    setChecklistFieldValues(null);
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  // if (getUser() && getUser().role === "ADMIN") {
  //   return <Navigate to="/jobs" replace={true} />
  // }

  return (
    <div className="checklist">
      {renderTopForm()}
      {renderBlankFieldTasks()}
      <Button1
        btnClassName="checklist-submitbtn"
        btnType="button"
        btnName="submit-button"
        btnValue="Submit New Checklist"
        onclick={() => {
          if (blankChecklistFieldValues.length < 1 && blankChecklistFieldValues[0].question !== "") {
            alert("There are no Checklist Questions to submit.");
          }
          if (!hasSaved) {
            alert("Save Checklist Questions first - Press the SAVE button");
          } else if (checklistFieldValues === null) {
            alert("Enter a Checklist Type before submitting");
          } else {
            handleSubmitChecklist();
          }
        }}
      />
    </div>
  );
};

export default ChecklistCreator;
